import React, { useState, useEffect } from 'react';
import { Fragment } from 'react';
import { Cookies } from 'react-cookie';
import { Button, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import { levelDecode } from '../components/common';
import Breadcrumb from '../layout/breadcrumb';
import {
  merchantId_api,
  putSetting,
  getMerchantInfo,
  putPinUpdate,
  userPassReset,
} from '../lib/api';
import { ToggleBtn } from './seatList';
import PageContainer from '../components/common/PageContainer';

const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  > .input-group {
    width: 20rem;
  }
  @media screen and (max-width: 360px) {
    > .input-group {
      width: 100%;
    }
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
`;

const WarningP = styled.p`
  :first-child {
    top: 64%;
  }
  left: 3%;
  color: red;
  font-weight: 800;
`;
export const Title = styled.h4`
  @media screen and (max-width: 480px) {
    text-align: center;
    margin-bottom: 0;
    font-size: 20px;
  }
`;
export const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  > h5 {
    margin-top: 7px;
    margin-right: 20px;
    font-size: 14px;
    strong {
      color: red;
    }
  }
`;

export const Label2 = styled.label`
  font-weight: 500;
  display: flex;
  flex-direction: column;
  h5 {
    font-size: 14px;
    strong {
      color: ${({ theme }) => theme.main[600]};
    }
  }
  p {
    margin-bottom: 0;
    font-size: 12px;
    color: #555;
  }
  :not(:last-child) {
    margin-bottom: 15px;
  }
  input,
  select {
    margin-top: 5px;
    background: #f8fcff;
    border: 1px solid #e9e9e9;
    border-radius: 4px;
    padding: 6px 13px;
  }

  @media screen and (max-width: 480px) {
    h5 {
      font-size: 13px;
    }
    input,
    select {
      padding: 9px 12px;
      font-size: 13px;
    }
  }
`;

let merchantId;
const Setting = ({ setIsLoading }) => {
  useEffect(() => {
    MerchantId();
  }, []);

  const [info, setInfo] = useState({
    store_name: null,
    contact: null,
    store_address: null,
    kakao_plus_id: null,
    br_number: null,
    mail_order_sales_number: null,
    user_name: null,
  });

  const [info1, setInfo1] = useState({
    merchant_code: null,
    card_code: null,
    card_code_inner: null,
    card_port: null,
    cash_port: null,
    card_type: 'daouVP',
    receipt_port: null,
    cash_check: null,
    finger_print_num: null,
    finger_print_num_inner: null,
    relay_port1: 'QAAMZ',
    relay_port2: '01',
    relay_port1_inner: 'QAAMZ',
    relay_port2_inner: '01',
    finger_reset_check: null,
    aratechMAC: '',
  });

  const [pw, setPw] = useState({ password1: '', password2: '' });

  const [pinNum, setPinNum] = useState({ pinNum1: '', pinNum2: '' });

  const [isPinOk, setIsPinOk] = useState(false);

  const level = localStorage.getItem('level');

  const MerchantId = async () => {
    setIsLoading(true);
    const { data } = await merchantId_api();
    if (data) {
      merchantId = data.id;
      const res = await getMerchantInfo(data.id);
      if (res.status === 200) {
        const info = res.data[0];
        setInfo({
          store_name: info.store_name ? info.store_name : null,
          contact: info.contact ? info.contact : null,
          store_address: info.store_address ? info.store_address : null,
          kakao_plus_id: info.kakao_plus_id ? info.kakao_plus_id : null,
          br_number: info.br_number ? info.br_number : null,
          mail_order_sales_number: info.mail_order_sales_number
            ? info.mail_order_sales_number
            : null,
          mid: info.id,
          toss_secret_key: info.toss_secret_key,
          toss_client_key: info.toss_client_key,
          user_name: info.user_name || null,
        });
        setInfo1({
          merchant_code: info.merchant_code ? info.merchant_code : null,
          card_code: info.card_code ? info.card_code : null,
          card_code_inner: info.card_code_inner ? info.card_code_inner : null,
          card_port: info.card_port ? info.card_port : null,
          card_type: info.card_type ? info.card_type : 'daouVP',
          cash_port: info.cash_port ? info.cash_port : null,
          receipt_port: info.receipt_port ? info.receipt_port : null,
          cash_check: info.cash_check ? info.cash_check : null,
          relay_port1: info.relay_port1 ? info.relay_port1 : 'QAAMZ',
          relay_port2: info.relay_port2 ? info.relay_port2 : '01',
          relay_port1_inner: info.relay_port1_inner ? info.relay_port1_inner : 'QAAMZ',
          relay_port2_inner: info.relay_port2_inner ? info.relay_port2_inner : '01',
          finger_print_num: info.finger_print_num ? info.finger_print_num : null,
          finger_print_num_inner: info.finger_print_num_inner ? info.finger_print_num_inner : null,
          finger_reset_check: info.finger_reset_check ? info.finger_reset_check : null,
          pay_tid: info.pay_tid,
          local_tid: info.local_tid,
          aratechMAC: info.aratechMAC,
        });
      }
    }
    setIsLoading(false);
  };

  const handleOnChange = (e) => {
    setInfo({ ...info, [e.target.name]: e.target.value });
    if (
      e.target.name === 'br_number' ||
      e.target.name === 'contact' ||
      e.target.name === 'br_number'
    ) {
      var regex = /[^0-9]/g;
      setInfo({ ...info, [e.target.name]: e.target.value.replace(regex, '') });
    }
  };

  const handleOnChange1 = (e) => {
    if (e.target.name === 'aratechMAC') {
      if (e.target.value.length < 13)
        setInfo1({
          ...info1,
          [e.target.name]: e.target.value.replace(/[^a-z|A-Z|0-9]/g, ''),
        });
    } else setInfo1({ ...info1, [e.target.name]: e.target.value });
  };

  const handleSingleCheck = (checked, target) => {
    setInfo1({ ...info1, [target.name]: checked ? 1 : 0 });
  };

  const handlePinNumber = ({ target }) => {
    setPinNum({
      ...pinNum,
      [target.name]: target.value.substr(0, 6),
    });
  };
  const handlePassword = ({ target }) => {
    setPw({ ...pw, [target.name]: target.value });
  };

  function clean(obj) {
    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined) {
        delete obj[propName];
      }
    }
    return obj;
  }

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    if (aratechMAC && aratechMAC.replace(' ', '').length !== 12) {
      return Swal.fire({
        icon: 'error',
        title: '아라테크 제어기 맥번호는 12자리로 입력하셔야합니다.',
        timer: 2000,
      });
    }
    let copy = { ...info };
    let filterNull = clean(copy);

    const res = await putSetting({ ...filterNull, merchantId });
    if (res.status === 200) {
      Swal.fire({
        icon: 'success',
        title: '기본설정 수정이 완료됐습니다.',
        timer: 2000,
      }).then(() => {
        window.location.reload();
      });
    } else {
      Swal.fire({
        icon: 'error',
        title: '수정에 실패했습니다. 다시 시도해주세요.',
        timer: 2000,
      });
    }
  };

  const handleOnSubmit1 = async (e) => {
    e.preventDefault();
    let copy = { ...info1 };
    let filterNull = clean(copy);

    const res = await putSetting({ ...filterNull, merchantId });
    if (res.status === 200) {
      Swal.fire({
        icon: 'success',
        title: '고급설정 수정이 완료됐습니다.',
        timer: 2000,
      }).then(() => {
        window.location.reload();
      });
    } else {
      Swal.fire({
        icon: 'error',
        title: '수정에 실패했습니다. 다시 시도해주세요.',
        timer: 2000,
      });
    }
  };

  const passwordOnSubmit = async (e) => {
    e.preventDefault();
    if (
      pinNum.pinNum1.length === 6 &&
      pinNum.pinNum2.length === 6 &&
      pinNum.pinNum1 === pinNum.pinNum2
    ) {
      const res = await putPinUpdate({
        merchant_uuid: info1.merchant_code,
        pin_number: pinNum.pinNum1,
      });

      if (res.status === 200) {
        Swal.fire({
          icon: 'success',
          title: '키오스크 PIN번호가 변경됐습니다.',
          timer: 2000,
        }).then(() => {
          window.location.reload();
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: '수정에 실패했습니다. 다시 시도해주세요.',
          timer: 2000,
        });
      }
    } else {
      Swal.fire({
        icon: 'error',
        title: 'pin번호의 조건에 맞게 입력해주세요',
        timer: 2000,
      });
    }
  };

  const checkPwFunc = (e) => {
    let pwCheck = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{6,}$/;
    let booleanCheck = pwCheck.test(e);

    if (!booleanCheck) {
      Swal.fire({
        icon: 'error',
        title: '비밀번호 구성이 올바르지 않습니다.',
        text: '비밀번호는 영문,숫자,특수문자(!@$%^&* 만 허용)를\n조합하여 6자 이상으로 구성하세요',
      });
    } else {
      return booleanCheck;
    }
  };

  const passwordOnSubmit1 = async (e) => {
    e.preventDefault();
    if (level === 'admin') {
      Swal.fire({
        icon: 'error',
        title: '전체지점관리자는 지점리스트에서 수정해주세요',
        timer: 2000,
      });
    } else {
      if (pw.password1 === pw.password2) {
        if (checkPwFunc(pw.password1)) {
          const res = await userPassReset({
            password: pw.password1,
            phoneNum: info1.merchant_code,
            merchantId: merchantId,
          });

          if (res.status === 200) {
            Swal.fire({
              icon: 'success',
              title: '패스워드가 변경됐습니다.',
              timer: 2000,
            }).then(() => {
              window.location.reload();
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: '수정에 실패했습니다. 다시 시도해주세요.',
              timer: 2000,
            });
          }
        }
      } else {
        Swal.fire({
          icon: 'error',
          title: '비밀번호가 일치하지 않습니다. 똑같이 입력해주세요',
          timer: 2000,
        });
      }
    }
  };

  const {
    store_name,
    contact,
    store_address,
    kakao_plus_id,
    br_number,
    mail_order_sales_number,
    user_name,
  } = info;

  const {
    receipt_port,
    cash_check,
    finger_print_num,
    finger_print_num_inner,
    relay_port1,
    relay_port2,
    relay_port1_inner,
    relay_port2_inner,
    finger_reset_check,
    aratechMAC,
  } = info1;

  return (
    <Fragment>
      <Breadcrumb parent="하드웨어 및 설정" title="기본설정" />
      <PageContainer title="기본 설정">
        <FormContent>
          <Label2>
            <h5>지점명</h5>
            <Input
              className="form-control"
              type="text"
              name="store_name"
              placeholder="지점명"
              value={store_name || ''}
              onChange={handleOnChange}
            />
          </Label2>
          <Label2>
            <h5>지점 전화번호</h5>
            <Input
              className="form-control digits"
              type="text"
              name="contact"
              placeholder="지점 전화번호"
              value={contact || ''}
              onChange={handleOnChange}
            />
          </Label2>

          <Label2>
            <h5>지점 주소</h5>
            <Input
              className="form-control m-input digits"
              type="text"
              name="store_address"
              placeholder="지점 주소"
              value={store_address || ''}
              onChange={handleOnChange}
            />
          </Label2>
          <Label2>
            <h5>카카오플러스 아이디</h5>
            <Input
              className="form-control"
              type="text"
              name="kakao_plus_id"
              placeholder="카카오플러스 아이디"
              value={kakao_plus_id || ''}
              onChange={handleOnChange}
            />
          </Label2>
          <Label2>
            <h5>사업자 등록 번호</h5>
            <Input
              className="form-control"
              type="number"
              name="br_number"
              placeholder="사업자 등록 번호"
              value={br_number || ''}
              onChange={handleOnChange}
            />
          </Label2>
          <Label2>
            <h5>통신판매번호</h5>
            <Input
              className="form-control"
              type="text"
              name="mail_order_sales_number"
              placeholder="통신판매번호"
              value={mail_order_sales_number || ''}
              onChange={handleOnChange}
            />
          </Label2>
          <Label2>
            <h5>지점 관리자 이름</h5>
            <Input
              className="form-control"
              type="text"
              name="user_name"
              placeholder="이름"
              value={user_name || ''}
              onChange={handleOnChange}
            />
          </Label2>
          {/* <Label2>
                    <h5>토스 클라이언트키</h5>
                    <Input
                      className="form-control"
                      type="text"
                      name="toss_client_key"
                      placeholder="토스 클라이언트키"
                      value={toss_client_key || ""}
                      onChange={handleOnChange}
                    />
                  </Label2>
                  <Label2>
                    <h5>토스 시크릿키</h5>
                    <Input
                      className="form-control"
                      type="text"
                      name="toss_secret_key"
                      placeholder="토스 시크릿키"
                      value={toss_secret_key || ""}
                      onChange={handleOnChange}
                    />
                  </Label2> */}
        </FormContent>
        <ButtonWrapper>
          <Button color="primary" className="mr-1" onClick={handleOnSubmit}>
            수정하기
          </Button>
          <Button color="light" type="reset">
            취소하기
          </Button>
        </ButtonWrapper>
      </PageContainer>
      <PageContainer title="고급 설정">
        <Form className="form theme-form" onSubmit={handleOnSubmit1}>
          <FormContent>
            {/* <Label2>
                    <h5>카드 코드번호</h5>
                    <Input
                      className="form-control"
                      type="text"
                      name="card_code"
                      placeholder="카드 코드번호"
                      value={card_code || ""}
                      onChange={handleOnChange1}
                    />
                  </Label2> */}
            {/* <Label2>
                    <h5>내부 키오스크 카드 코드번호</h5>
                    <Input
                      className="form-control"
                      type="text"
                      name="card_code_inner"
                      placeholder="내부 키오스크 카드 코드번호"
                      value={card_code_inner || ""}
                      onChange={handleOnChange1}
                    />
                  </Label2> */}
            {/* <Label2>
                    <h5>off PG 코드번호 (지역화폐)</h5>
                    <Input
                      className="form-control"
                      type="text"
                      name="local_tid"
                      placeholder="off PG 코드번호"
                      value={local_tid || ""}
                      onChange={handleOnChange1}
                    />
                  </Label2> */}
            {/* <Label2>
                    <h5>KIS 결제 단말기 번호</h5>
                    <Input
                      className="form-control"
                      type="text"
                      name="pay_tid"
                      placeholder="KIS 결제 단말기 번호"
                      value={pay_tid || ""}
                      onChange={handleOnChange1}
                    />
                  </Label2> */}
            {/* <Label2>
                    <h5>카드 포트번호</h5>
                    <Input
                      className="form-control digits"
                      type="text"
                      name="card_port"
                      placeholder="카드 포트번호"
                      value={card_port || ""}
                      onChange={handleOnChange1}
                    />
                  </Label2> */}
            {/* <Label2>
                    <h5>현금 포트번호</h5>
                    <Input
                      className="form-control digits"
                      type="text"
                      name="cash_port"
                      placeholder="현금 포트번호"
                      value={cash_port || ""}
                      onChange={handleOnChange1}
                    />
                  </Label2> */}
            <Label2>
              <h5>영수증 포트번호</h5>
              <Input
                className="form-control m-input digits"
                type="text"
                name="receipt_port"
                placeholder="영수증 포트번호"
                value={receipt_port || ''}
                onChange={handleOnChange1}
              />
            </Label2>

            <Label2>
              <h5>지문인식기 포트번호</h5>
              <Input
                className="form-control"
                type="text"
                name="finger_print_num"
                placeholder="지문인식기 포트번호"
                value={finger_print_num || ''}
                onChange={handleOnChange1}
              />
            </Label2>
            <Label2>
              <h5>내부키오스크 지문인식기 포트번호</h5>
              <Input
                className="form-control"
                type="text"
                name="finger_print_num_inner"
                placeholder="내부키오스크 지문인식기 포트번호"
                value={finger_print_num_inner || ''}
                onChange={handleOnChange1}
              />
            </Label2>
            <Label2>
              <h5>외부 릴레이 포트번호1</h5>
              <Input
                className="form-control"
                type="text"
                name="relay_port1"
                placeholder="외부 릴레이 포트번호1"
                value={relay_port1 || ''}
                onChange={handleOnChange1}
              />
            </Label2>
            <Label2>
              <h5>외부 릴레이 포트번호2</h5>
              <Input
                className="form-control"
                type="text"
                name="relay_port2"
                placeholder="외부 릴레이 포트번호2"
                value={relay_port2 || ''}
                onChange={handleOnChange1}
              />
            </Label2>
            <Label2>
              <h5>내부 릴레이 포트번호1</h5>
              <Input
                className="form-control"
                type="text"
                name="relay_port1_inner"
                placeholder="내부 릴레이 포트번호1"
                value={relay_port1_inner || ''}
                onChange={handleOnChange1}
              />
            </Label2>
            <Label2>
              <h5>내부 릴레이 포트번호2</h5>
              <Input
                className="form-control"
                type="text"
                name="relay_port2_inner"
                placeholder="내부 릴레이 포트번호2"
                value={relay_port2_inner || ''}
                onChange={handleOnChange1}
              />
            </Label2>
            {/* <Label2>
                    <h5>VAN사 설정</h5>
                    <Input
                      className="form-control"
                      type="select"
                      placeholder="card"
                      name="card_type"
                      value={card_type || ""}
                      onChange={handleOnChange1}
                    >
                      <option value="daouVP">daouVP</option>
                      <option value="KIS">KIS</option>
                    </Input>
                  </Label2> */}
            <Label2>
              <h5>아라테크 제어기 맥번호(영어,숫자)</h5>
              <Input
                className="form-control"
                type="text"
                placeholder="12자리(영어, 숫자)"
                name="aratechMAC"
                value={aratechMAC || ''}
                onChange={handleOnChange1}></Input>
            </Label2>
            <ToggleContainer>
              <h5>현금 사용</h5>
              <ToggleBtn>
                <Input
                  id="cash1"
                  name="cash_check"
                  type="checkbox"
                  checked={cash_check}
                  onChange={({ target }) => handleSingleCheck(target.checked, target)}
                />
                <span className="onoff-switch"></span>
              </ToggleBtn>
            </ToggleContainer>
            <ToggleContainer>
              <h5>지문재등록 차단</h5>
              <ToggleBtn>
                <Input
                  id="finger_reset_check"
                  name="finger_reset_check"
                  type="checkbox"
                  checked={finger_reset_check === 1}
                  onChange={({ target }) => handleSingleCheck(target.checked, target)}
                />
                <span className="onoff-switch"></span>
              </ToggleBtn>
            </ToggleContainer>
          </FormContent>

          <ButtonWrapper>
            <Button color="primary" className="mr-1">
              수정하기
            </Button>
            <Button color="light" type="reset">
              취소하기
            </Button>
          </ButtonWrapper>
        </Form>
      </PageContainer>
      <PageContainer title="관리자 비밀번호 설정">
        <FormContent>
          <p>※ 영문,숫자,특수문자(!@$%^&* 만 허용)를 조합하여 6자 이상으로 입력해주세요.</p>
          {/* {isPasswordOk === "false" && (
                    <WarningP>패스워드가 일치하지 않습니다.</WarningP>
                  )} */}
          <Label2>
            <h5>
              비밀번호 <strong>*</strong>
            </h5>
            <Input
              className="form-control"
              type="text"
              aria-describedby="inputGroupPrepend2"
              name="password1"
              placeholder="비밀번호를 입력해주세요"
              onChange={handlePassword}
              value={pw.password1}
            />
          </Label2>
          <Label2>
            <h5>
              비밀번호 확인 <strong>*</strong>
            </h5>
            <Input
              className="form-control"
              type="text"
              aria-describedby="inputGroupPrepend2"
              name="password2"
              placeholder="비밀번호를 입력해주세요"
              onChange={handlePassword}
              value={pw.password2}
            />
          </Label2>
          {/* <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>* 비밀번호</InputGroupText>
            </InputGroupAddon>
            <Input
              className="form-control"
              type="text"
              aria-describedby="inputGroupPrepend2"
              name="password1"
              placeholder="비밀번호를 입력해주세요"
              onChange={handlePassword}
              value={pw.password1}
            />
          </InputGroup>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>* 비밀번호 확인</InputGroupText>
            </InputGroupAddon>
            <Input
              className="form-control"
              type="text"
              aria-describedby="inputGroupPrepend2"
              name="password2"
              placeholder="비밀번호를 입력해주세요"
              onChange={handlePassword}
              value={pw.password2}
            />
          </InputGroup> */}
        </FormContent>
        <ButtonWrapper>
          <Button color="primary" className="mr-1" onClick={passwordOnSubmit1}>
            수정하기
          </Button>
          <Button color="light" type="reset">
            취소하기
          </Button>
        </ButtonWrapper>
      </PageContainer>
      <PageContainer title="키오스크 PIN 번호 설정">
        <FormContent>
          <p>
            ※ 접속 PIN번호 수정 시, 반드시 내/외부 키오스크를 수동으로 종료하신 후, 새로운 PIN번호
            입력하여 다시 구동해 주셔야 합니다.
          </p>
          <Label2>
            <h5>
              PIN 번호 <strong>*</strong>
            </h5>
            <Input
              className="form-control"
              type="password"
              name="pinNum1"
              placeholder="6자리 숫자만 입력해주세요"
              aria-describedby="inputGroupPrepend2"
              onChange={handlePinNumber}
              value={pinNum.pinNum1}
            />
          </Label2>
          <Label2>
            <h5>
              PIN 번호 확인 <strong>*</strong>
            </h5>
            <Input
              className="form-control"
              type="password"
              name="pinNum2"
              placeholder="6자리 숫자만 입력해주세요"
              aria-describedby="inputGroupPrepend2"
              onChange={handlePinNumber}
              value={pinNum.pinNum2}
            />
          </Label2>
          {/* <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>* PIN번호</InputGroupText>
            </InputGroupAddon>
            <Input
              className="form-control"
              type="password"
              name="pinNum1"
              placeholder="6자리 숫자만 입력해주세요"
              aria-describedby="inputGroupPrepend2"
              onChange={handlePinNumber}
              value={pinNum.pinNum1}
            />
          </InputGroup>
          {pinNum.pinNum1 !== pinNum.pinNum2 && <WarningP>PIN번호가 일치하지 않습니다.</WarningP>}
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>* PIN번호 확인</InputGroupText>
            </InputGroupAddon>
            <Input
              className="form-control"
              type="password"
              name="pinNum2"
              placeholder="6자리 숫자만 입력해주세요"
              aria-describedby="inputGroupPrepend2"
              onChange={handlePinNumber}
              value={pinNum.pinNum2}
            />
          </InputGroup> */}
        </FormContent>

        <ButtonWrapper>
          <Button color="primary" className="mr-1" onClick={passwordOnSubmit}>
            수정하기
          </Button>
          <Button color="light" type="reset">
            취소하기
          </Button>
        </ButtonWrapper>
      </PageContainer>
    </Fragment>
  );
};
export default Setting;
