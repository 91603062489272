import React from 'react';
import {
  differentialOfDate,
  diffTime1,
  diffTimeToRest,
  secondsToDayHourFormat,
  secondsToHHmmss,
} from './commonFunctions';

const ticketTypeString = (ticketType: 1 | 2 | 3 | 4 | 5) => {
  try {
    if (ticketType === 1) return '일회권';
    if (ticketType === 2) return '기간권';
    if (ticketType === 3) return '시간정액권';
    if (ticketType === 4) return '세미나실 이용권';
  } catch (error) {
    return '';
  }
};

export const getSeatTypeString = (seat: EntireSeat) => {
  return seat.type === 'seminar' ? '세미나실' : seat.seatType === 0 ? '자유석' : '고정석';
};

export const getSeatTicketTypeString = (seat: EntireSeat) => {
  if (!seat.ticketType) return '';
  return (
    <>
      <br />
      {ticketTypeString(seat.ticketType)}
    </>
  );
};

export const getSeatRemainUsingTime = (seat: EntireSeat) => {
  if (seat.status !== 'using') return '';
  return (
    <>
      <br />
      {/* 일회권 */}
      {seat.ticketType === 1 && (
        <div>
          {secondsToHHmmss(diffTime1(seat.lastIn, seat.remainTime))}
          <br />
          남음
        </div>
      )}
      {/* 시간정액권 */}
      {seat.ticketType === 3 && (
        <div>
          {secondsToHHmmss(diffTime1(seat.lastIn, seat.remainTime))} <br />
          남음
        </div>
      )}
      {/* 기간권 / 고정석 */}
      {seat.ticketType === 2 && seat.seatType === 1 && (
        <>
          <div>
            {`${differentialOfDate(new Date(), seat.endDate)}일`}
            <br />
            남음
          </div>
        </>
      )}
      {/* 기간권 / 자유석 */}
      {seat.ticketType === 2 && seat.seatType === 0 && (
        <>
          <div>
            {`${differentialOfDate(new Date(), seat.endDate)}일`}
            <br />
            {secondsToHHmmss(diffTime1(seat.lastIn, seat.remainTime), true)} 남음
          </div>
        </>
      )}
      {/* 세미나실 */}
      {seat.ticketType === 4 && (
        <>
          <div>
            {secondsToDayHourFormat(diffTime1(seat.startDate, seat.remainTime))}
            <br />
            남음
          </div>
        </>
      )}
    </>
  );
};

export const getSeatRemainRestTime = (seat: EntireSeat) => {
  if (seat.status !== 'rest') return '';

  return (
    <>
      <br />
      {/* 기간권 / 고정석 */}
      {seat.ticketType === 2 && seat.seatType === 1 && (
        <>
          <div>
            {`${differentialOfDate(new Date(), seat.endDate)}일`}
            <br />
            남음
          </div>
        </>
      )}

      {/* 일회권 */}
      {seat.seatType !== 1 && (
        <div>
          {secondsToDayHourFormat(seat.restTime)}
          지남
        </div>
      )}

      {/* 시간정액권 */}
      {/* {seat.ticketType === 3 && (
        <div>
          {secondsToHHmmss(diffTime1(seat.lastIn, seat.remainTime))} <br />
          남음
        </div>
      )} */}

      {/* 기간권 / 자유석 */}
      {/* {seat.ticketType === 2 && seat.seatType === 0 && (
        <>
          <div>
            {`${differentialOfDate(new Date(), seat.endDate)}일`}
            <br />
            {secondsToHHmmss(diffTime1(seat.lastIn, seat.remainTime), true)} 남음
          </div>
        </>
      )} */}
    </>
  );
};
