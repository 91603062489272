import React, { forwardRef, useEffect, useState } from 'react';
import { Input, Modal, ModalBody, ModalHeader } from 'reactstrap';
import styled from 'styled-components';
import { ko } from 'date-fns/locale';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { getLockerList, getSeatList, userList, userTicketEnroll } from '../lib/api';
import Swal from 'sweetalert2';
import { Button, Label1 } from './lockerChange';
import configDB from '../data/customizer/config';
import { ToggleBtn } from './seatList';
import { Calendar } from 'react-feather';
import { ModalBackground } from '../components/modal/choiceModal';
import { Label2 } from './coupon/coupon';

const primary = localStorage.getItem('default_color') || configDB.data.color.primary_color;

const Inputs = styled.div`
  display: flex;
  align-items: center;
  > input {
    width: 40%;
    margin-right: 0.2vw;
    :not(:first-child) {
      margin-left: 0.8vw;
    }
  }
`;

export const LabelRow = styled(Label1)`
  flex-direction: row;
  align-items: center;
  h5 {
    margin-right: 1vw;
    margin-bottom: 0;
  }
  position: relative;
`;
const DatePickerS = styled.button`
  background: #f8fcff;
  border: 1px solid #e9e9e9;
  border-radius: 4px;
  padding: 6px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  > svg {
    /* margin-left: 2vw; */
    width: 17px;
    color: #ccc;
  }
  :active,
  :focus {
    border: 1px solid #05c8da;
  }
`;

export const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
  <DatePickerS onClick={onClick} ref={ref}>
    {value}
    <Calendar />
  </DatePickerS>
));

const TicketCustom = ({ mid, close, reloading }) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(moment(new Date()).add(1, 'y')._d);
  const [startDateplus1, setStartDateplus1] = useState(new Date());
  const [ticket, setTicket] = useState({
    type: 1,
    phoneNum: '010',
    paymentType: 'cash',
    amount: 1,
    day: 28,
    usedTime: 0,
    remainTime: 3600,
    seatType: 0,
    level: 1,
    seatId: 0,
    seatNum: 0,
    remainHour: 1,
    remainMin: 0,
    usedHour: 0,
    usedMin: 0,
  });
  const [idleSeatData, setIdleSeatData] = useState([]);
  const [idleLockerData, setIdleLockerData] = useState([]);
  var regex = /[^0-9]/g;

  var submitFlag = false;

  const submitCheck = () => {
    if (submitFlag) {
      return submitFlag;
    } else {
      submitFlag = true;
      return false;
    }
  };

  const seatList = async () => {
    if (mid) {
      let res = await getSeatList(mid);
      if (res.status === 200 && res.data) {
        if (+ticket.seatType === 0) {
          setIdleSeatData(
            res.data.filter(
              (item) =>
                item.status === 'idle' &&
                !item.isUsing &&
                item.level <= +ticket.level &&
                item.seatType === 0,
            ),
          );
        } else {
          setIdleSeatData(
            res.data.filter(
              (item) =>
                item.status === 'idle' &&
                !item.isUsing &&
                item.level <= +ticket.level &&
                item.seatType === 1,
            ),
          );
        }
      } else {
        Swal.fire('좌석 데이터를 불러올 수가 없습니다.');
      }
    }
  };

  const lockerList = async () => {
    if (mid) {
      let res = await getLockerList(mid);

      if (res.status === 200 && res.data) {
        setIdleLockerData(res.data.filter((item) => item.status === 'idle'));
      } else {
        Swal.fire('좌석 데이터를 불러올 수가 없습니다.');
      }
    }
  };

  const handleChange = (e) => {
    if (e.target.name === 'seatType') {
      if (+e.target.value === 1) setTicket({ ...ticket, [e.target.name]: 0 });
      else {
        setTicket({ ...ticket, [e.target.name]: 1 });
      }
    } else if (e.target.name === 'paymentType') {
      setTicket({
        ...ticket,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === 'seatId') {
      setTicket({
        ...ticket,
        [e.target.name]: e.target.value,
        seatNum: e.target.options[e.target.selectedIndex].text.split('.')[0],
      });
    } else {
      setTicket({
        ...ticket,
        [e.target.name]: e.target.value.replace(regex, ''),
      });
    }
  };

  const handleSubmit = async () => {
    try {
      let remainTime = +ticket.remainHour * 3600 + +ticket.remainMin * 60;
      let usedTime = +ticket.usedHour * 3600 + +ticket.usedMin * 60;
      const t11 = moment(startDate).format('YYYY-MM-DD');
      const t22 = moment(new Date()).format('YYYY-MM-DD');
      let diffDate = moment(t11).isSame(t22);

      if (!ticket.phoneNum) {
        Swal.fire('휴대폰 번호를 입력해주세요.');
      } else if ((+ticket.type === 1 || +ticket.type === 3) && +remainTime < 600) {
        Swal.fire('이용권의 최소시간은 10분입니다.');
      } else if ((+ticket.type === 2 || +ticket.type === 5) && +ticket.day === 0) {
        Swal.fire('이용권의 최소 일 수는 1일입니다.');
      } else if (+ticket.amount === 0) {
        Swal.fire('이용권의 최소 금액은 1원입니다.');
      } else if (+ticket.level === 0) {
        Swal.fire('이용권의 등급을 제대로 설정해주세요');
      } else if (+ticket.seatId === 0 && +ticket.type === 1) {
        Swal.fire('좌석을 선택해주세요');
      } else if (+ticket.seatId === 0 && +ticket.type === 5) {
        Swal.fire('사물함을 선택해주세요');
      } else if ((+ticket.type === 1 || +ticket.type === 3) && ticket.day > 730) {
        Swal.fire('이용권의 최대 이용시간은 2년(730일)입니다.');
      } else if ((+ticket.type === 2 || +ticket.type === 5) && +ticket.day > 17520) {
        Swal.fire('이용권의 최대 이용시간은 2년(17520시간)입니다.');
      } else {
        if (submitCheck()) {
          return;
        }
        if (ticket.phoneNum) {
          const userData = await userList(mid, ticket.phoneNum);

          if (userData.status === 200 && userData.data.length !== 0) {
            let data;
            if (+ticket.type === 2 && !diffDate) {
              data = await userTicketEnroll({
                merchantId: mid,
                phoneNum: ticket.phoneNum,
                startDate: moment(startDate).format('YYYY-MM-DD 00:00:00'),
                day: +ticket.day,
                seatType: ticket.seatType,
                type: +ticket.type,
                paymentType: ticket.paymentType,
                amount: +ticket.amount,
                level: +ticket.level,
                meta8: `백오피스-입장권 발행(기간권)`,
                meta13: `사용자 : ${ticket.phoneNum}`,
              });
            } else if (+ticket.type === 2 && diffDate) {
              data = await userTicketEnroll({
                merchantId: mid,
                phoneNum: ticket.phoneNum,
                startDate: startDate,
                endDate: null,
                day: +ticket.day,
                seatType: ticket.seatType,
                type: +ticket.type,
                paymentType: ticket.paymentType,
                amount: +ticket.amount,
                level: +ticket.level,
                meta8: `백오피스-입장권 발행(기간권)`,
                meta13: `사용자 : ${ticket.phoneNum}`,
              });
            } else if (+ticket.type === 5) {
              data = await userTicketEnroll({
                merchantId: mid,
                phoneNum: ticket.phoneNum,
                startDate: startDate,
                endDate: null,
                day: +ticket.day,
                seatType: ticket.seatType,
                seatId: +ticket.seatId,
                seatNumber: +ticket.seatNum,
                type: +ticket.type,
                paymentType: ticket.paymentType,
                amount: +ticket.amount,
                level: 1,
                meta8: `백오피스-입장권 발행(사물함)`,
                meta13: `사용자 : ${ticket.phoneNum}`,
              });
            } else if (+ticket.type === 1) {
              data = await userTicketEnroll({
                merchantId: mid,
                phoneNum: ticket.phoneNum,
                startDate: startDate,
                endDate: null,
                remainTime: +remainTime,
                usedTime: +usedTime,
                seatType: 0,
                seatId: +ticket.seatId,
                seatNumber: +ticket.seatNum,
                type: +ticket.type,
                paymentType: ticket.paymentType,
                amount: +ticket.amount,
                level: +ticket.level,
                meta8: `백오피스-입장권 발행(일회권)`,
                meta13: `사용자 : ${ticket.phoneNum}`,
              });
            } else {
              data = await userTicketEnroll({
                merchantId: mid,
                phoneNum: ticket.phoneNum,
                startDate: startDate,
                endDate: endDate,
                remainTime: +remainTime,
                usedTime: +usedTime,
                seatType: 0,
                type: +ticket.type,
                paymentType: ticket.paymentType,
                amount: +ticket.amount,
                level: +ticket.level,
                meta8: `백오피스-입장권 발행(시간정액권)`,
                meta13: `사용자 : ${ticket.phoneNum}`,
              });
            }
            if (data.status === 200) {
              reloading(mid);
              setTicket({
                type: 1,
                phoneNum: '010',
                paymentType: 'cash',
                amount: 1,
                day: 1,
                usedTime: 0,
                remainTime: 1,
                seatType: 0,
                seatNum: 0,
                seatId: 0,
              });
              submitFlag = false;
              setStartDate(new Date());
              setEndDate(moment(new Date()).add(1, 'y')._d);
              Swal.fire({
                icon: 'success',
                title: '이용권 등록이 완료되었습니다.',
                timer: 2000,
              }).then(close());
            } else if (data.status === 202) {
              Swal.fire({
                icon: 'error',
                title: '현재시간이 정확하지 않습니다. \n 관리자에게 문의해주세요.',
                timer: 3000,
              });
            } else {
              Swal.fire({
                icon: 'error',
                title: '이용권 등록에 실패하였습니다.',
                timer: 2000,
              });
              submitFlag = false;
            }
          } else if (userData.status === 200 && userData.data.length === 0) {
            Swal.fire({
              icon: 'error',
              title: '유효하지 않은 휴대폰 번호입니다.',
              timer: 3000,
            });
            submitFlag = false;
          } else {
            Swal.fire({
              icon: 'error',
              title: '등록되지 않은 회원입니다.',
              timer: 3000,
            });
            submitFlag = false;
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    seatList();
  }, [ticket?.level, ticket?.seatType]);

  useEffect(() => {
    seatList();
    lockerList();
  }, [ticket?.type]);

  return (
    <>
      <Modal centered isOpen={close} toggle={close}>
        <ModalHeader className="modal-title" toggle={close}>
          입장권 발행
        </ModalHeader>
        <ModalBody>
          <Label1>
            <h5>
              휴대폰 번호 ('-'를 제외하고 입력해주세요) <strong>*</strong>
            </h5>
            <Input
              type="text"
              name="phoneNum"
              value={ticket.phoneNum || ''}
              onChange={(e) => handleChange(e)}
            />
          </Label1>
          <Label1>
            <h5>
              이용권 타입 <strong>*</strong>
            </h5>
            <select name="type" value={ticket.type} onChange={(e) => handleChange(e)}>
              입장권 종류
              <option value={1}>일회권</option>
              <option value={2}>기간권</option>
              <option value={3}>시간정액권</option>
              <option value={5}>사물함</option>
            </select>
          </Label1>
          {+ticket.type === 1 && (
            <Label1>
              <h5>
                좌석선택 <strong>*</strong>
              </h5>
              <select name="seatId" value={ticket.seatId} onChange={(e) => handleChange(e)}>
                <option value={0} disabled>
                  좌석을 선택해주세요
                </option>
                {idleSeatData.map((item, idx) => (
                  <option value={item.id} id={item.number} key={'se' + idx}>
                    {item.number}.{item.name}
                  </option>
                ))}
              </select>
            </Label1>
          )}
          {+ticket.type === 5 && (
            <Label1>
              <h5>
                사물함선택 <strong>*</strong>
              </h5>
              <select name="seatId" value={ticket.seatId} onChange={(e) => handleChange(e)}>
                <option value={0} disabled>
                  사물함을 선택해주세요
                </option>
                {idleLockerData.map((item, idx) => (
                  <option value={item.id} key={'lo' + idx}>
                    {item.number}.{item.name}
                  </option>
                ))}
              </select>
            </Label1>
          )}
          {+ticket.type !== 5 && (
            <Label1>
              <h5>
                이용권 등급 <strong>*</strong>
              </h5>
              <Input
                type="text"
                name="level"
                value={ticket.level}
                onChange={(e) => handleChange(e)}
              />
            </Label1>
          )}
          <Label2>
            <h5>
              시작날짜 <strong>*</strong>
            </h5>
          </Label2>
          <Label1>
            <DatePicker
              selected={startDate}
              onChange={(date) => {
                if (ticket.type === 1) {
                  Swal.fire({
                    icon: 'error',
                    title: '일회권은 당일 발급만 가능합니다.',
                    timer: 3000,
                  });
                  return;
                }
                setStartDate(date);
              }}
              selectsStart
              locale={ko}
              startDate={startDate}
              maxDate={+ticket.type === 3 ? endDate : false}
              endDate={+ticket.type === 3 ? endDate : false}
              customInput={<ExampleCustomInput />}
            />
          </Label1>
          {+ticket.type === 3 && (
            <Label1>
              <h5>
                마지막날짜 <strong>*</strong>
              </h5>
              <div className="date-pickers">
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  selectsEnd
                  locale={ko}
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDateplus1}
                  customInput={<ExampleCustomInput />}
                />
              </div>
            </Label1>
          )}
          {+ticket.type !== 1 && +ticket.type !== 3 && +ticket.type !== 4 && (
            <Label1>
              <h5>
                사용일수(일) <strong>*</strong>
              </h5>
              <Input
                type="text"
                name="day"
                value={ticket.day || ''}
                onChange={(e) => handleChange(e)}
              />
            </Label1>
          )}
          {+ticket.type !== 2 && +ticket.type !== 5 && (
            <>
              <Label1>
                <h5>
                  발급 시간<strong>*</strong>
                </h5>
                <Inputs>
                  <Input
                    type="text"
                    name="remainHour"
                    value={ticket.remainHour || ''}
                    onChange={(e) => handleChange(e)}
                  />
                  시간
                  <Input
                    type="text"
                    name="remainMin"
                    value={ticket.remainMin || ''}
                    onChange={(e) => handleChange(e)}
                  />
                  분
                </Inputs>
              </Label1>
              <Label1>
                <h5>
                  사용 시간<strong>*</strong>
                </h5>
                <Inputs>
                  <Input
                    type="text"
                    name="usedHour"
                    value={ticket.usedHour || ''}
                    onChange={(e) => handleChange(e)}
                  />
                  시간
                  <Input
                    type="text"
                    name="usedMin"
                    value={ticket.usedMin || ''}
                    onChange={(e) => handleChange(e)}
                  />
                  분
                </Inputs>
              </Label1>
            </>
          )}

          <Label1>
            <h5>
              금액(원) <strong>*</strong>
            </h5>
            <Input
              type="text"
              name="amount"
              value={ticket.amount || ''}
              onChange={(e) => handleChange(e)}
            />
          </Label1>
          <Label1>
            <h5>
              매출유형 <strong>*</strong>
            </h5>
            <select
              name="paymentType"
              value={ticket.paymentType || ''}
              onChange={(e) => handleChange(e)}>
              매출종류
              <option value="cash">현금</option>
              {/* <option value={3}>간편결제</option> */}
              <option value="virtual">가매입/가매출</option>
            </select>
          </Label1>
          {+ticket.type === 2 && (
            <LabelRow>
              <h5>
                고정석 설정 <strong>*</strong>
              </h5>
              <ToggleBtn>
                <Input
                  type="checkbox"
                  name="seatType"
                  value={ticket.seatType || ''}
                  onChange={(e) => handleChange(e)}
                  checked={+ticket.seatType === 1}
                />
                <span className="onoff-switch"></span>
              </ToggleBtn>
            </LabelRow>
          )}
          <div
            style={{
              display: 'flex',
              width: '100%',
              gap: '10px',
            }}>
            <Button
              color="#fff"
              onClick={() => {
                close();
                setTicket({
                  type: 1,
                  phoneNum: '010',
                  paymentType: 'cash',
                  amount: 1,
                  day: 1,
                  usedTime: 1,
                  remainTime: 1,
                  seatType: 0,
                });
                setStartDate(new Date());
                setEndDate(moment(new Date()).add(7, 'day')._d);
              }}>
              취소
            </Button>
            <Button onClick={() => handleSubmit()} color={primary}>
              확인
            </Button>
          </div>
        </ModalBody>
      </Modal>
      <ModalBackground />
    </>
  );
};

export default TicketCustom;
