import React, { forwardRef, Fragment, useEffect, useState } from 'react';
import moment from 'moment';
import styled from 'styled-components';

import { numberWithCommas } from '../../common';

import { ticketSales, ticketSalesAmount } from '../../../lib/api';
import Arrow from '../../../assets/images/dashboard/arrow.svg';
import { DashboardCard } from '../../commonStyle';
import theme from '../../../theme';

const StyleComponent = styled(DashboardCard)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  .topContainer {
    width: 100%;
    > div > p {
      &:first-child {
        font-weight: 700;
        line-height: 1.5;
        font-size: 1.25rem;
      }
    }
    > p {
      font-size: 1rem;
      color: var(--dashboard-default);
      font-weight: 700;
    }
  }

  .flex {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    gap: 1rem;
  }

  .flex-col {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: calc(100% - 15vw);
  }
`;

const SaleData = styled.div`
  display: flex;
  font-size: 1rem;
  background: ${(props) => `${props.color}10`};
  border-radius: 100px;

  /* height: 2.13vw; */
  .title {
    width: 8rem;
    display: flex;
    align-items: center;
    background: ${(props) => props.color};
    justify-content: center;
    border-radius: 100px;

    > p {
      color: #ffffff;
      font-size: 0.8rem;
      font-weight: 600;
      margin-bottom: 0;
    }
  }
  .data {
    padding: 0.5rem;
    display: grid;
    width: 100%;
    align-items: center;
    p {
      text-align: center;
      margin-bottom: 0;
      font-size: 1rem;
      font-weight: 600;
      color: #686c71;
      &:first-child,
      &:nth-child(2) {
        border-right: 1px solid #dde4eb;
      }
    }
    &.ticketData {
      grid-template-columns: 1fr 2fr;
      p {
        &:nth-child(2) {
          border-right: none;
        }
      }
    }
  }
`;

const TicketSale = ({ paddingStyle, setIsLoading, mid, startDate, endDate }) => {
  const defaultData = [
    {
      type: 1,
      ticketName: '일회권',
      color: theme.normalSeatWithDisposableTicket,
      total_amount: 0,
      count: 0,
    },
    {
      type: 2,
      ticketName: '기간권',
      color: theme.normalSeatWithPeriodicTicket,
      total_amount: 0,
      count: 0,
    },
    {
      type: 3,
      ticketName: '시간정액권',
      color: theme.normalSeatWithTimeBasedTicket,
      total_amount: 0,
      count: 0,
    },
    {
      type: 5,
      ticketName: '사물함',
      color: theme.normalSeatWithLockerTicket,
      total_amount: 0,
      count: 0,
    },
  ];
  const [ticketSaleData, setTicketSaleData] = useState([...defaultData]);
  const getTicketSaleData = async () => {
    //ticket count
    const ticketData = await ticketSales(
      mid,
      moment(startDate).format('YYYY-MM-DD 00:00:00'),
      moment(endDate).format('YYYY-MM-DD 23:59:59'),
    );

    //ticket totalAmount
    const ticketAmountData = await ticketSalesAmount(
      mid,
      moment(startDate).format('YYYY-MM-DD 00:00:00'),
      moment(endDate).format('YYYY-MM-DD 23:59:59'),
    );

    if (ticketData.status === 200 || ticketAmountData.status === 200) {
      if (ticketData.data.length !== 0) {
        let copyData = [...defaultData];
        for (let i = 0; ticketData.data.length > i; i++) {
          if (ticketData.data[i].type === 1) {
            copyData[0].count = ticketData.data[i].count;
            copyData[0].total_amount = ticketAmountData.data[i].total_amount;
          } else if (ticketData.data[i].type === 2) {
            copyData[1].count = ticketData.data[i].count;
            copyData[1].total_amount = ticketAmountData.data[i].total_amount;
          } else if (ticketData.data[i].type === 3) {
            copyData[2].count = ticketData.data[i].count;
            copyData[2].total_amount = ticketAmountData.data[i].total_amount;
          } else if (ticketData.data[i].type === 5) {
            copyData[3].count = ticketData.data[i].count;
            copyData[3].total_amount = ticketAmountData.data[i].total_amount;
          }
        }

        setTicketSaleData(copyData);
      } else {
        setTicketSaleData(defaultData);
      }
    }
  };
  useEffect(() => {
    if (mid) getTicketSaleData();
  }, [mid, startDate, endDate]);

  return (
    <Fragment>
      <StyleComponent>
        <div className="topContainer">
          <div className="flex">
            <p>이용권별 판매 현황 및 금액</p>
          </div>
        </div>
        <div className="flex margin-top">
          {ticketSaleData &&
            ticketSaleData.map((item) => (
              <SaleData color={item.color}>
                <div className="title ticketTitle">
                  <p>{item.ticketName}</p>
                </div>
                <div className="ticketData data">
                  <p>{item?.count || 0}개</p>
                  <p>{numberWithCommas(item?.total_amount) || 0}원</p>
                </div>
              </SaleData>
            ))}
        </div>
      </StyleComponent>
    </Fragment>
  );
};
export default TicketSale;
