import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { Dropdown, Input, Spinner } from 'reactstrap';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import ConfigDB from '../../data/customizer/config';
import { franchiseCalculateFunc, franchiseCalculateMailSend } from '../../lib/api';
import { Button } from '../../pages/lockerChange';
import { Checkbox } from '../../pages/messageBulk';
import { exportExcel, numberWithCommas } from '../common';
import Loading from '../Loading';
import FranchiseList from './franchiseTable';

const primary = localStorage.getItem('default_color') || ConfigDB.data.color.primary_color;

const FranchiseContainer = styled.div`
  width: 100%;
  .flex {
    width: 50%;
    display: flex;
    align-items: center;
    select {
      background: #f8fcff;
      border: 1px solid #e9e9e9;
      border-radius: 4px;
      padding: 9px 12px;
      font-size: 14px;
      margin-left: 10px;
    }
    .btns {
      width: 50%;
      display: flex;
      margin-left: 2vw;
      button {
        margin-left: 10px;
      }
    }
  }
  table {
    width: 100%;
    margin-top: 2vw;
    tr {
      border-bottom: 1px solid #2222;
    }
    thead {
      border-bottom: 2px solid gray;
      th {
        padding: 15px 5px;
      }
    }
    td {
      padding: 15px 5px;
    }
  }

  .loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const Franchise = () => {
  const fullMonth = [...Array(12)].map((v, i) => i + 1);
  const nowMonth = moment().add(-1, 'month').format('MM');
  const nowYear = moment().add(-1, 'month').format('YYYY');
  let length = +nowYear - 2019;
  const fullYear = [...Array(length + 1)].map((v, i) => 2019 + i);
  //조회될 데이터 (시작일, 말일)

  const [selectMonth, setSelectMonth] = useState(nowMonth);
  const [franchiseData, setFranchiseData] = useState([]);
  //연도 검색
  const [searchYear, setSearchYear] = useState(+nowYear);
  //기타 값
  const [isLoading, setIsLoading] = useState(true);
  //checkbox 선택한 데이터
  const [choiceData, setChoiceData] = useState([]);

  //checkbox Event
  const checkBoxEvent = (item, index) => {
    let checkChoiceData = choiceData.some((choice) => choice.id === index);
    if (!checkChoiceData) {
      setChoiceData([...choiceData, item]);
    } else {
      const filterData = choiceData.filter((choice) => choice.id !== index);
      setChoiceData(filterData);
    }
  };

  // const checkBoxEvent = (item) => {
  //   let checkChoiceData = choiceData.some((choice) => choice === item.id);
  //   if (!checkChoiceData) {
  //     setChoiceData([...choiceData, item.id]);
  //   } else {
  //     const filterData = choiceData.filter((choice) => choice !== item.id);
  //     setChoiceData(filterData);
  //   }
  // };

  const choiceAllEvent = (item) => {
    //현재 기준 선택한 데이터의 갯수가 전체 항목의 갯수와 다를 경우(전체선택)
    if (choiceData.length !== franchiseData.length) {
      setChoiceData(franchiseData);
    } else {
      //(전체선택해제)
      setChoiceData([]);
    }
  };

  //버튼 클릭 이벤트
  const clickBtn = async () => {
    try {
      setIsLoading(true);
      // 버튼을 클릭시 start, end가 각각 해당월 초~월 말로 가게 만든다.
      let startData = `${searchYear}-${
        selectMonth < 10 && selectMonth.length !== 2 ? '0' + selectMonth : selectMonth
      }-01 00:00:00`;
      let endChangeData = moment(startData).endOf('month').format('DD');
      let endData = `${searchYear}-${
        selectMonth < 10 && selectMonth.length !== 2 ? '0' + selectMonth : selectMonth
      }-${endChangeData} 23:59:59`;

      //api 실행
      const { data } = await franchiseCalculateFunc(startData, endData);

      setFranchiseData(
        data,
        data.map((item, idx) => {
          item.id = idx;
          item.allComma = item.all !== 0 ? numberWithCommas(item.all) : 0;
          item.creditComma = item.credit !== 0 ? numberWithCommas(item.credit) : 0;
          item.cashComma = item.cash !== 0 ? numberWithCommas(item.cash) : 0;
          item.all1Comma = item.all1 !== 0 ? numberWithCommas(item.all1) : 0;
          item.credit1Comma = item.credit1 !== 0 ? numberWithCommas(item.credit1) : 0;
          item.royaltyComma = item.royalty !== 0 ? numberWithCommas(item.royalty) : 0;
          item.kioskComma = item.kiosk !== 0 ? numberWithCommas(item.kiosk) : 0;
          item.amount1Comma = item.amount1 !== 0 ? numberWithCommas(item.amount1) : 0;
          item.amount2Comma = item.amount2 !== 0 ? numberWithCommas(item.amount2) : 0;
          item.amount3Comma = item.amount3 !== 0 ? numberWithCommas(item.amount3) : 0;
        }),
      );
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  const changeData = (e, idx) => {
    if (e.target.name.includes('kiosk'))
      setFranchiseData(
        franchiseData.map((item, index) =>
          index === idx
            ? {
                ...item,
                [e.target.name]: +e.target.value.replace(/[^0-9.]/g, ''),
              }
            : item,
        ),
      );
    else
      setFranchiseData(
        franchiseData.map((item, index) =>
          index === idx ? { ...item, [e.target.name]: e.target.value } : item,
        ),
      );
  };

  const sendData = async () => {
    if (choiceData.length === 0) {
      return Swal.fire({
        icon: 'error',
        text: '선택한 데이터가 없습니다.',
        timer: 3500,
      });
    } else {
      const startDateTime = `${searchYear}-${selectMonth.toString().padStart(2, '0')}-01 00:00:00`;
      const end = moment(startDateTime).endOf('month').format('DD');
      const endDateTime = `${searchYear}-${selectMonth
        .toString()
        .padStart(2, '0')}-${end} 23:59:59`;

      Swal.fire({
        icon: 'question',
        text: `총 ${choiceData.length}개의 지점이 맞습니까?`,
        showCancelButton: true,
        confirmButtonColor: '#04c9db',
        cancelButtonColor: '#F53C14',
        confirmButtonText: '예',
        cancelButtonText: '취소',
      }).then(async (result) => {
        if (result.isConfirmed) {
          let send = choiceData.map((item) => ({
            name: item.name,
            credit: item.credit,
            cash: item.cash,
            email: item.email,
            royalty: item.royalty,
            kiosk: item.kiosk,
            text: item.text ? item.text : '',
            other: item.kiosk1 && item.kiosk2 ? -item.kiosk2 + item.kiosk1 : 0,
            merchantId: item.merchantId,
            start: startDateTime,
            end: endDateTime,
          }));
          // console.log(send);

          let { status, data } = await franchiseCalculateMailSend(send);
          console.log(status, data);
        }
      });
    }
  };

  useEffect(() => {
    clickBtn();
  }, []);

  return (
    <FranchiseContainer>
      <div className="flex">
        <select value={searchYear} onChange={(e) => setSearchYear(e.target.value)}>
          {fullYear.map((item) => (
            <option key={item + 'btn'} value={item}>
              {item}
            </option>
          ))}
        </select>
        년
        <select value={selectMonth} onChange={(e) => setSelectMonth(e.target.value)}>
          {fullMonth.map((item) => (
            <option key={item + 'btn'} value={item}>
              {item}
            </option>
          ))}
        </select>
        월
        <div className="btns">
          <Button color={primary} onClick={() => clickBtn()}>
            조회
          </Button>
          <Button color={primary} onClick={() => exportExcel(franchiseData, '', '', '프랜차이즈')}>
            엑셀출력하기
          </Button>
          <Button color={primary} onClick={() => sendData()}>
            메일전송
          </Button>
        </div>
      </div>
      <table>
        <thead>
          <tr>
            <th>
              <Checkbox>
                <Input
                  id="isAd"
                  type="checkbox"
                  checked={choiceData.length === franchiseData.length}
                  onChange={() => choiceAllEvent()}
                />
                <span className="checkbox_icon" />
              </Checkbox>
            </th>
            <th>지점명</th>
            <th>총 결제금액</th>
            <th>카드결제금액</th>
            <th>현금결제금액</th>
            <th>월매출</th>
            <th>카드매출</th>
            <th>로얄티</th>
            <th>키오스크 유지보수비</th>
            <th>청구액</th>
            <th>부가세</th>
            <th>공급가액</th>
            <th>기타+</th>
            <th>기타-</th>
            <th>비고</th>
            <th>이메일</th>
          </tr>
        </thead>

        <tbody>
          {!isLoading &&
            franchiseData.map((item, idx) => (
              <tr>
                <td>
                  <Checkbox>
                    <Input
                      id="index"
                      type="checkbox"
                      onChange={() => checkBoxEvent(item, idx)}
                      checked={choiceData.some((choice) => choice.id === idx)}
                    />
                    <span className="checkbox_icon" />
                  </Checkbox>
                </td>
                <td>{item.name}</td>
                <td>{item.allComma}원</td>
                <td>{item.creditComma}원</td>
                <td>{item.cashComma}원</td>
                <td>{item.all1Comma}원</td>
                <td>{item.credit1Comma}원</td>
                <td>{item.royaltyComma}원</td>
                <td>{item.kioskComma}원</td>
                <td>{item.amount1Comma}원</td>
                <td>{item.amount2Comma}원</td>
                <td>{item.amount3Comma}원</td>
                <td>
                  <Input
                    style={{ width: '100px', margin: '0 auto' }}
                    name="kiosk1"
                    value={item.kiosk1 || 0}
                    onChange={(e) => changeData(e, idx)}
                  />
                </td>
                <td>
                  <Input
                    style={{ width: '100px', margin: '0 auto' }}
                    name="kiosk2"
                    value={item.kiosk2 || 0}
                    onChange={(e) => changeData(e, idx)}
                  />
                </td>
                <td>
                  <Input
                    style={{ width: '100px', margin: '0 auto' }}
                    name="text"
                    value={item.text}
                    onChange={(e) => changeData(e, idx)}
                  />
                </td>
                <td>
                  <Input
                    style={{ width: '200px', margin: '0 auto' }}
                    value={item.email}
                    name="email"
                    onChange={(e) => changeData(e, idx)}
                  />
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      {isLoading && <Loading />}
    </FranchiseContainer>
  );
};

export default Franchise;
