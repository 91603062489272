import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import { Form, Input, Row } from 'reactstrap';
import Swal from 'sweetalert2';
import Breadcrumbs from '../layout/breadcrumb';
import { EntryExitLog, getUserData, merchantId_api, userList } from '../lib/api';
import DetailsModal from '../components/modal/detailsModal';
import { ExampleCustomInput, LabelRow } from './ticketCustom';
import DataTable from 'react-data-table-component';
import { ko } from 'date-fns/locale';
import DatePicker from 'react-datepicker';
import { DatePickerStyle } from './seatInfo';
import SelectDate, { SelectDateV2 } from '../components/selectDate';
import { userExitColumns } from '../data/ticketList';
import PaginationComponent from '../components/pagination';
import styled from 'styled-components';
import { exportExcel, swalMessage } from '../components/common';
import { TabButton, TabList } from './seatList';
import PerPageSelect from '../components/table/PerPageSelect';
import Table, {
  TableHeaderArea,
  TableHeaderContainer,
  TableHeaderContainerV2,
} from '../components/common/Table';
import PageContainer from '../components/common/PageContainer';
import { SearchButton } from '../components/input';
import { Button, ExcelButton } from '../components/common/Button';

import IconExcel from '../assets/images/button/icon_button_excel.svg';
import DatePickerComponent from '../components/filter/DatePicker';
import Search from '../components/common/Search';

const CardContainer = styled.div`
  .date-pickers {
    display: flex;
    h5 {
      margin: 0 6px;
    }
  }
  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1vw;
  }
  .topbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1vw;

    .m-0 {
      display: flex;
      align-items: center;
      position: relative;
      width: 100%;

      input {
        width: 100%;
      }
      .fa-search {
        position: absolute;
        right: 12px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  /* .searchContainer {
    display: flex;
    input {
      margin-top: 0;
      width: 100%;
    }
    form {
      width: 100% !important;
    }
  } */
  @media screen and (max-width: 768px) {
    .topbar {
      flex-direction: column;
    }
    /* .searchContainer {
      width: 100%;
      margin-top: 1.5vw;
      input {
        width: 100%;
        height: 38px;
      }
      div {
        margin: 0;
        width: fit-content;
      }
      select {
        margin-left: 0;
        margin-right: 10px;
      }
    } */
  }
  @media screen and (max-width: 480px) {
    .topbar {
      align-items: center;

      .m-0 {
        margin: 3vw 0 !important;
      }
    }
  }
  .phoneNum-click {
    color: #04c9db;
    border-bottom: 1px solid #fff;
    &:hover {
      border-bottom: 1px solid #04c9db;
      cursor: pointer;
    }
  }
`;

let merchantId;

const ExitLog = ({ setIsLoading }) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [exitLog, setExitLog] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState(null);
  const [defaultList, setDefaultList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);
  const [tabData, setTabData] = useState('All');

  const [detailUserModal, setDetailUserModal] = useState(false);
  const [userData, setUserData] = useState({});

  const indexOfLast = currentPage * postsPerPage;
  const indexOfFirst = indexOfLast - postsPerPage;

  const [searchOption, setSearchOption] = useState('좌석번호');

  let searchKeywordData = ['좌석번호', '입장권', '내역', '전화번호', '권번'];

  const getData = async (startDate, endDate) => {
    let { data } = await merchantId_api();
    try {
      merchantId = data.id;
      getEntryExitLog(data.id, startDate, endDate);
    } catch (error) {
      console.log(error);
    }
  };

  // 디테일 데이터 모달로 보내기
  const sendDetailData = (phoneNum) => {
    getUserList(phoneNum);
  };
  // 휴대혼 번호 가져오기

  const getUserList = async (phoneNum) => {
    const result = await userList(merchantId, phoneNum);
    if (result && result.status === 200) {
      setUserData(result.data[0]);
      setDetailUserModal(!detailUserModal);
    }
  };

  const showDetailModal = () => {
    setDetailUserModal(!detailUserModal);
  };

  const getEntryExitLog = async (id, startDate, endDate) => {
    setIsLoading(true);
    const term = moment(endDate).diff(moment(startDate), 'days');
    if (term > 31) return;
    let ExitLogData = await EntryExitLog(
      id,
      moment(startDate).format('YYYY-MM-DD 00:00:00'),
      moment(endDate).format('YYYY-MM-DD 23:59:59'),
    );

    let processData = [
      ExitLogData.data,
      ExitLogData.data.map((item) => {
        item.idx = item.id;
        item.message1 =
          item.message === '휴식종료(입장)' ? (
            <p style={{ color: '#3661E6' }}>외출종료(입장)</p>
          ) : item.message === '휴식' ? (
            <p style={{ color: '#E66036' }}>외출</p>
          ) : item.message.includes('입장') ? (
            <p style={{ color: '#3661E6' }}>{item.message}</p>
          ) : item.message.includes('퇴장') ? (
            <p style={{ color: '#E66036' }}>{item.message}</p>
          ) : item.message.includes('차감') ? (
            <p style={{ color: '#E66036' }}>{item.message}</p>
          ) : item.message.includes('병합') ? (
            <p style={{ color: '#3661E6' }}>{item.message}</p>
          ) : item.message.includes('자리이동') ? (
            <p style={{ color: '#1b431d' }}>{item.message.split('번')[0]}번에서 자리이동</p>
          ) : item.message.includes('사물함 만료') ? (
            <p style={{ color: '#3661E6' }}>{item.message}</p>
          ) : item.message.includes('사물함 사용') ? (
            <p style={{ color: '#E66036' }}>{item.message}</p>
          ) : (
            <p style={{ color: 'red' }}>비상출입</p>
          );

        item.message2 =
          item.message === '휴식종료(입장)'
            ? '외출종료(입장)'
            : item.message === '휴식'
            ? '외출'
            : item.message.includes('입장')
            ? item.message
            : item.message.includes('퇴장')
            ? item.message
            : item.message.includes('차감')
            ? item.message
            : item.message.includes('병합')
            ? item.message
            : item.message.includes('자리이동')
            ? '자리이동'
            : item.message.includes('사물함 만료')
            ? '사물함 만료'
            : item.message.includes('사물함 사용')
            ? '사물함 사용'
            : '비상출입';
        item.phoneNumTag =
          item.phoneNum == 0 ? null : !item.phoneNum && item.message.includes('비상출입') ? (
            `${item.message.split('-')[0]}`
          ) : (
            <div
              className="phoneNum-click"
              onClick={(e) => {
                sendDetailData(e.currentTarget.textContent);
              }}>
              {item.phoneNum}
            </div>
          );

        item.seatNumber1 = (item.seatNumber ? item.seatNumber : '-') + '번';
        item.create1 = moment(item.createdAt).format('YYYY-MM-DD HH:mm:ss');
        item.typeData =
          item.type === 1
            ? '일회권'
            : item.type === 2
            ? '기간권'
            : item.type === 3
            ? '시간정액권'
            : item.type === 4
            ? '세미나실'
            : item.type === 5
            ? '사물함'
            : '';

        item.typeTag =
          item.type === 1 ? (
            <p style={{ color: '#EBAF22' }}>일회권</p>
          ) : item.type === 2 ? (
            <p style={{ color: '#E03D64' }}>기간권</p>
          ) : item.type === 3 ? (
            <p style={{ color: '#33946B' }}>시간정액권</p>
          ) : item.type === 4 ? (
            <p style={{ color: '#3E67B6' }}>세미나실</p>
          ) : item.type === 5 ? (
            <p style={{ color: '#AB84FF' }}>사물함</p>
          ) : (
            ''
          );
      }),
    ];
    if (tabData === 'All') {
      setTotalCount(ExitLogData.data.length);
      setExitLog(processData[0]);
      setDefaultList(processData[0]);
      setCurrentPage(1);
    } else if (tabData === 'seat') {
      const ticketData = processData[0].filter((item) => item.type === 1);
      setTotalCount(ticketData.length);
      setExitLog(ticketData);
      setDefaultList(processData[0]);
      setCurrentPage(1);
    } else if (tabData === 'locker') {
      const lockerData = processData[0].filter(
        (item) => item.type === 5 || item.subtype.includes('locker'),
      );
      setTotalCount(lockerData.length);
      setExitLog(lockerData);
      setDefaultList(processData[0]);
      setCurrentPage(1);
    }
    setIsLoading(false);
  };

  const handleSearchKeyword = (keyword) => {
    if (keyword === '') {
      setSearchKeyword(null);
    } else {
      setSearchKeyword(keyword);
    }
  };

  const searchList = (listData) => {
    let searchData;
    if (searchOption === '전화번호') {
      searchData = listData.filter((item) => item.phoneNum?.includes(searchKeyword));
    } else if (searchOption === '입장권') {
      searchData = listData.filter((item) => item.typeData?.toString().includes(searchKeyword));
    } else if (searchOption === '내역') {
      searchData = listData.filter((item) => item.message2?.toString().includes(searchKeyword));
    } else if (searchOption === '좌석번호') {
      if (searchKeyword.includes('번')) {
        searchData = listData.filter((item) => item.seatNumber1?.toString() === searchKeyword);
      } else {
        searchData = listData.filter(
          (item) => item.seatNumber1?.toString() === searchKeyword + '번',
        );
      }
    } else if (searchOption === '권번') {
      searchData = listData.filter((item) => item.ticketId?.toString().includes(searchKeyword));
    }

    return searchData;
  };

  const searchData = () => {
    if (!searchKeyword) {
      Swal.fire({ icon: 'error', title: '검색어를 입력해주세요', timer: 2000 });
    } else {
      let copyData;

      if (tabData === 'All') {
        setExitLog(defaultList);
        copyData = [...defaultList];
      } else if (tabData === 'seat') {
        const ticketData = defaultList.filter((item) => item.type === 1);
        copyData = [...ticketData];
      } else if (tabData === 'locker') {
        const lockerData = defaultList.filter(
          (item) => item.type === 5 || item.subtype.includes('locker'),
        );
        copyData = [...lockerData];
        setExitLog(lockerData);
        setTotalCount(lockerData.length);
      }

      let searchDatas = searchList(copyData);
      setExitLog(searchDatas);
      setTotalCount(searchDatas.length);
      setCurrentPage(1);
    }
  };

  const currentPosts = (tmp) => {
    let currentPosts = 0;
    currentPosts = tmp.slice(indexOfFirst, indexOfLast);
    return currentPosts;
  };

  const changeShowData = (tabName) => {
    setIsLoading(true);
    setTabData(tabName);
    setCurrentPage(1);
    setSearchKeyword(null);
    if (tabName === 'All') {
      setExitLog(defaultList);
      setTotalCount(defaultList.length);
    } else if (tabName === 'seat') {
      const ticketData = defaultList.filter((item) => item.type === 1);
      setExitLog(ticketData);
      setTotalCount(ticketData.length);
    } else if (tabName === 'locker') {
      const lockerData = defaultList.filter(
        (item) => item.type === 5 || item.subtype.includes('locker'),
      );
      setExitLog(lockerData);
      setTotalCount(lockerData.length);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const term = moment(endDate).diff(moment(startDate), 'days');
    if (term <= 31) return getData(startDate, endDate);
    swalMessage('warning', '최대 1개월 범위(31일)까지 조회 가능합니다.', '확인', false);
    const changedEndDate = moment(startDate).add(31, 'days').toDate();
    getData(startDate, changedEndDate);
    setEndDate(changedEndDate);
  }, [startDate]);

  useEffect(() => {
    const term = moment(endDate).diff(moment(startDate), 'days');
    if (term <= 31) return getData(startDate, endDate);
    swalMessage('warning', '최대 1개월 범위(31일)까지 조회 가능합니다.', '확인', false);
    const changedStartDate = moment(endDate).add(-31, 'days').toDate();
    getData(changedStartDate, endDate);
    setStartDate(changedStartDate);
  }, [endDate]);

  return (
    <Fragment>
      {detailUserModal && (
        <DetailsModal
          isOpen={detailUserModal}
          close={showDetailModal}
          seat={userData}
          back={true}
          // setData={getUserTicketListApi}
        />
      )}
      <Breadcrumbs parent="정보조회" title="지점 출입 정보 조회" />
      <PageContainer
        title="지점 출입 정보 조회"
        itemsRightSideTitle={[
          <ExcelButton
            onClick={() => exportExcel(exitLog, startDate, endDate, '지점 출입 정보 조회')}
          />,
        ]}>
        <TabList>
          <TabButton selected={tabData === 'All'} onClick={() => changeShowData('All')}>
            전체
          </TabButton>
          <TabButton selected={tabData === 'locker'} onClick={() => changeShowData('locker')}>
            사물함
          </TabButton>
          <TabButton selected={tabData === 'seat'} onClick={() => changeShowData('seat')}>
            일회권
          </TabButton>
        </TabList>
        <TableHeaderContainerV2>
          <DatePickerComponent
            withCalendar
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            startMaxDate={endDate}
            endMinDate={startDate}
          />
          {/* <DatePickerStyle>
            <DatePicker
              selected={startDate}
              onChange={(date) => {
                setStartDate(date);
              }}
              selectsStart
              locale={ko}
              startDate={startDate}
              // minDate={moment(endDate).subtract(1, 'M').toDate()}
              // maxDate={endDate}
              endDate={endDate}
              customInput={<ExampleCustomInput />}
            />
            <h5> - </h5>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              selectsEnd
              locale={ko}
              startDate={startDate}
              endDate={endDate}
              // minDate={startDate}
              // maxDate={moment(startDate).subtract(-1, 'M').toDate()}
              customInput={<ExampleCustomInput />}
            />
            <SelectDateV2
              setStartDate={(val) => setStartDate(val)}
              setEndDate={(val) => setEndDate(val)}
              maximumRange="최근 1개월"
            />
          </DatePickerStyle> */}
          <PerPageSelect onChange={setPostsPerPage} />
          <div className="inner-grid-2">
            <DatePickerStyle>
              <select onChange={(e) => setSearchOption(e.target.value)} value={searchOption}>
                {searchKeywordData.map((item) => (
                  <option>{item}</option>
                ))}
              </select>
            </DatePickerStyle>

            <Search
              onSubmit={searchData}
              searchKeyword={searchKeyword}
              setSearchKeyword={handleSearchKeyword}
            />
            {/* <Form
              // style={{
              //   width: window.innerWidth > 480 ? '30%' : '100%',
              // }}
              onSubmit={(e) => {
                e.preventDefault();
                searchData();
              }}>
              <LabelRow className="m-0">
                <Input
                  // className="form-control"
                  type="text"
                  placeholder="검색어를 입력해주세요"
                  value={searchKeyword || ''}
                  onChange={(e) => handleSearchKeyword(e.target.value)}
                />

                <SearchButton onClick={searchData} />
              </LabelRow>
            </Form> */}
          </div>
        </TableHeaderContainerV2>

        <Table
          data={currentPosts(exitLog)}
          columns={userExitColumns}
          striped={true}
          center={true}
        />

        {totalCount > 0 && (
          <PaginationComponent
            setCurrentPage={setCurrentPage}
            totalLength={totalCount}
            postsPerPage={postsPerPage}
            currentPage={currentPage}
          />
        )}
      </PageContainer>
    </Fragment>
  );
};

export default ExitLog;
