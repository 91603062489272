import moment from 'moment';
import styled from 'styled-components';
import { getMerchantInfo, postPayLog } from '../lib/api';
import configDB from '../data/customizer/config';
import crypto from 'crypto-js';
import { loadTossPayments } from '@tosspayments/payment-sdk';
import Swal from 'sweetalert2';
import { Cookies } from 'react-cookie';
import * as xlsx from 'xlsx';
import { SETTLE_PG } from './webpay/hecto';

const cookies = new Cookies();

const jwt = require('jsonwebtoken');

const primary = localStorage.getItem('default_color') || configDB.data.color.primary_color;

export const payLog = async (data) => {
  let payData = {
    paymentType: data.payType,
    amount: data.amount,
    userId: data.userId,
    cashRecepitType: null,
    couponId: null,
    spread: null,
    addTime: data.addTime,
    addDay: data.addDay,
    meta1: '',
    meta2: '',
    meta3: '',
    meta4: '',
    meta5: '',
    meta6: '',
    meta7: '',
    meta8: `백오피스-${data.meta8}`,
    meta9: '',
    meta10: '',
    meta11: '',
    meta12: '',
    meta13: '',
    merchantId: data.merchantId,
    ticketId: data.ticketId,
  };

  const putPayLog = await postPayLog(payData);
  if (putPayLog.status === 200) {
    return putPayLog.status;
  }
};

export const TimeTermBtn = styled.button`
  width: 150px;
  background-color: #fff;
  border: 1px solid ${primary};
  padding: 7px 12px;
  margin-left: 5px;
  border-radius: 4px;
  color: ${primary};
  :active,
  :hover {
    background-color: ${primary};
    color: #fff;
  }
  transition: all 0.2s ease-in-out;
`;

export function numberWithCommas(x) {
  if (!x) {
    return '-';
  } else {
    if (typeof x === 'string') {
      return x.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
}

const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;

export const decryptFunc = (encryptData) => {
  let originalData = crypto.AES.decrypt(encryptData, SECRET_KEY);

  let originalText = originalData.toString(crypto.enc.Utf8);
  return originalText;
};

const CODE_KEY = process.env.REACT_APP_BARCODE_SECRET_KEY;

export const encryptFunc = (levelData) => {
  let ldata = crypto.AES.encrypt(levelData.toString(), CODE_KEY).toString();
  return ldata;
};

export const decryptFuncCode = (encryptData) => {
  let originalData = crypto.AES.decrypt(encryptData, CODE_KEY);

  let originalText = originalData.toString(crypto.enc.Utf8);
  return originalText;
};

export const discountRate = (price, percent) => {
  const priceData = price - (percent / 100) * price - ((price - (percent / 100) * price) % 10);
  return priceData;
};

//uuid제작
export const getUUID = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

// 결제 함수

export const tossPay = async (
  clientKey,
  orderId,
  amount,
  orderName,
  itemId,
  phoneNum,
  seminarId,
  seminarCount,
  seatNum,
  startDate,
  setStartDate,
  seatId,
) => {
  const tossPayments = await loadTossPayments(clientKey);
  // 추후 가격이 맞는지 인증 필요
  if (tossPayments) {
    tossPayments
      .requestPayment('카드', {
        amount,
        orderId,
        orderName,
        customerName: phoneNum,
        successUrl: `${window.location.origin}/success/?itemId=${itemId}&phoneNum=${phoneNum}&seminarId=${seminarId}&seminarCount=${seminarCount}&seatNumber=${seatNum}&startDate=${startDate}&seatId=${seatId}`,
        failUrl: window.location.href,
      })
      .then(async (res) => {})
      .catch((error) => {
        if (error.code === 'USER_CANCEL') {
          // 취소 이벤트 처리

          Swal.fire({
            icon: 'error',
            title: '결제를 취소했습니다.',
            timer: 3000,
          }).then(() => {
            setStartDate(null);
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: '결제에 실패했습니다.',
            timer: 3000,
          }).then(() => {
            setStartDate(null);
          });
        }
      });
  }
};

export const tossPayMessage = async (
  clientKey,
  orderId,
  amount,
  itemId,
  orderName,
  customerName,
) => {
  const tossPayments = await loadTossPayments(clientKey);
  // 추후 가격이 맞는지 인증 필요
  if (tossPayments) {
    tossPayments
      .requestPayment('카드', {
        amount,
        orderId,
        orderName,
        customerName,
        successUrl: `${window.location.origin}/success/?itemId=${itemId}&type=${'message'}`,
        failUrl: window.location.href,
      })
      .then(async (res) => {})
      .catch((error) => {
        if (error.code === 'USER_CANCEL') {
          // 취소 이벤트 처리

          Swal.fire({
            icon: 'error',
            title: '결제를 취소했습니다.',
            timer: 3000,
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: '결제에 실패했습니다.',
            timer: 3000,
          });
        }
      });
  }
};

export const levelDecode = (token) => {
  if (localStorage.getItem('number')) {
    localStorage.removeItem('merchantToken', {
      path: '/',
    });

    localStorage.removeItem('merchantRefreshToken', {
      path: '/',
    });
    localStorage.removeItem('adminToken', {
      path: '/',
    });

    localStorage.removeItem('adminRefreshToken', {
      path: '/',
    });
    localStorage.removeItem('number');
    window.location.replace(`${process.env.PUBLIC_URL}/auth/login`);
  } else {
    const decoded = jwt.decode(token);
    if (decoded) {
      let level = decryptFunc(decoded.level);
      return level;
    }
  }
};

export const idDecode = (token) => {
  const decoded = jwt.decode(token);
  return decoded.sub;
};

export const exportExcel = async (lists, startDate, endDate, dataName) => {
  try {
    if (lists.length === 0) {
      Swal.fire({
        text: '현재 엑셀로 출력 할 수 있는 데이터가 없습니다.',
        timer: 2000,
      });
    } else {
      let excelData;
      if (dataName === '입장권 정보 조회') {
        excelData = lists.map((item) => {
          let newopj = {};
          newopj['권번'] = item.id;
          newopj['유형'] = item.typeName;
          newopj['등급'] = item.level;
          newopj['전화번호'] = item.phoneNum;
          newopj['사용시작일'] = item.startDate;
          newopj['마지막 출입시간'] = item.lastIn;
          newopj['충전시간'] = item.action;
          newopj['유효기간'] = item.expireDate2;
          newopj['잔여시간'] = item.remainTime2;
          newopj['사용가능여부'] = item.blockKeyword;
          newopj['사용상태'] = item.usingTimeKeyword;
          return newopj;
        });
      } else if (dataName === '맵뷰어 상세 조회') {
        excelData = lists.map((item) => {
          let newopj = {};
          newopj['유형'] = item.typeData;
          newopj['좌석번호'] = item.numberName;
          newopj['전화번호'] = item.phoneNum;
          newopj['입장일시'] = item.lastIn;
          newopj['최종 입출입 시간'] = item.action;
          newopj['체류시간'] = item.expireDate;
          newopj['권번'] = item.id;
          newopj['퇴장여부'] = item.message;

          return newopj;
        });
      } else if (dataName === '지점 출입 정보 조회') {
        excelData = lists.map((item) => {
          let newopj = {};
          newopj['no.'] = item.idx;
          newopj['전화번호'] = item.phoneNum;
          newopj['내역'] = item.message2;
          newopj['좌석번호'] = item.seatNumber1;
          newopj['권번'] = item.ticketId;
          newopj['일시'] = item.create1;

          return newopj;
        });
      } else if (dataName === '회원 정보 조회') {
        excelData = lists.map((item) => {
          let newopj = {};
          newopj['no.'] = item.id;
          newopj['이름'] = item.name;
          newopj['휴대폰 번호'] = item.phoneNum;
          newopj['가입일자'] = item.create;
          newopj['지문등록'] = item.finger;
          newopj['키오스크'] = item.kiosk;
          newopj['백오피스'] = item.backoffice;
          newopj['QR/PIN 제한해제'] = item.access;
          return newopj;
        });
      } else if (dataName === '결제내역') {
        excelData = lists.map((item) => {
          let newopj = {};
          newopj['ID'] = item.id;
          newopj['입장권 유형'] = item.p_type;
          newopj['전화번호'] = item.phoneNum;
          newopj['티켓번호'] = item.ticketId;
          newopj['결제일자'] = item.createdAt;
          newopj['금액'] = item.price;
          newopj['적립 마일리지'] = item.addMileage;
          newopj['사용 마일리지'] = item.minusMileage;
          newopj['승인번호'] = item.meta2;
          newopj['상태'] = item.cancel;

          return newopj;
        });
      } else if (dataName === '메시지 전송 내역') {
        excelData = lists.map((item) => {
          let newopj = {};
          newopj['no.'] = item.id;
          newopj['본문'] = item.message;
          newopj['수신번호'] = item.phone;
          newopj['전송일자'] = item.createdAt;
          return newopj;
        });
      } else if (dataName === '마일리지') {
        excelData = lists.map((item) => {
          let newopj = {};
          newopj['no.'] = item.id;
          newopj['적립액'] = item.price;
          newopj['사용시간'] = item.usingTime;
          newopj['전화번호'] = item.phoneNum;
          return newopj;
        });
      } else if (dataName === '쿠폰 발급 내역') {
        excelData = lists.map((item) => {
          let newopj = {};
          newopj['no.'] = item.id;
          newopj['쿠폰번호'] = item.code;
          newopj['쿠폰타입'] = item.couponType;
          newopj['사용여부'] = item.used;
          newopj['발급시간'] = item.create;
          newopj['전화번호'] = item.phoneNum;
          newopj['유효기간'] = item.expdate;
          newopj['상품유효기간'] = item.expirationDate;
          return newopj;
        });
      } else if (dataName === '쿠폰 사용 내역') {
        excelData = lists.map((item) => {
          let newopj = {};
          newopj['no.'] = item.id;
          newopj['쿠폰번호'] = item.code;
          newopj['쿠폰타입'] = item.couponType;
          newopj['발급시간'] = item.create;
          newopj['전화번호'] = item.phoneNum;
          newopj['유효기간'] = item.expdate;
          return newopj;
        });
      } else if (dataName === '세미나실 예약 현황') {
        excelData = lists.map((item) => {
          let newopj = {};
          newopj['전화번호'] = item.phoneNum;
          newopj['시작시간'] = item.start;
          newopj['종료시간'] = item.end;
          return newopj;
        });
      } else if (dataName === '지점 쿠폰 발급') {
        excelData = lists.map((item, index) => {
          let newopj = {};
          newopj['No.'] = index + 1;
          newopj['지점명'] = item.merchantName;
          newopj['쿠폰 타입'] = item.typeName;
          if (+item.type === 1) {
            newopj['상품시간'] = item.term;
          } else if (+item.type === 2 || +item.type === 5) {
            newopj['상품기간'] = item.term;
          } else {
            newopj['상품시간'] = item.term;
            newopj['유효기간'] = item.day;
          }
          newopj['쿠폰번호'] = item.name;
          newopj['쿠폰 유효기간'] = item.expDate;
          return newopj;
        });
      } else if (dataName === '프랜차이즈') {
        excelData = lists.map((item, index) => {
          let newopj = {};
          newopj['no.'] = index + 1;
          newopj['지점명'] = item.name;
          newopj['총 결제금액'] = item.allComma;

          newopj['카드결제금액'] = item.creditComma;

          newopj['현금결제금액'] = item.cashComma;

          newopj['월매출'] = item.all1Comma;
          newopj['카드매출'] = item.credit1Comma;

          newopj['로얄티'] = item.royaltyComma;
          newopj['키오스크 유지보수비'] = item.kioskComma;
          newopj['청구액'] = item.amount1Comma;
          newopj['부가세'] = item.amount2Comma;
          newopj['공급가액'] = item.amount3Comma;
          newopj['기타'] =
            item.kiosk2 && item.kiosk1 ? numberWithCommas(-item.kiosk2 + item.kiosk1) : 0;
          newopj['비고'] = item.text;
          newopj['이메일'] = item.email;
          return newopj;
        });
      }

      const workbook = xlsx.utils.book_new();
      const data = xlsx.utils.json_to_sheet(excelData);
      xlsx.utils.book_append_sheet(workbook, data, 'sheet1');

      if (startDate === '' && endDate === '') {
        xlsx.writeFile(workbook, `${dataName}.xlsx`);
      } else {
        xlsx.writeFile(
          workbook,
          `${moment(startDate).format('YYYY.MM.DD')}-${moment(endDate).format(
            'YYYY.MM.DD',
          )} ${dataName}.xlsx`,
        );
      }
    }
  } catch (e) {
    console.log(e);
    Swal.fire({
      text: '엑셀출력중 에러가 생겼습니다.',
      timer: 2000,
    });
  }
};

// 웹결제 hecto
//세틀뱅크(핵토파이낸셜 결제)
export const HectoPay = (
  url_store_id,
  date,
  time,
  UUID,
  store_name,
  store_name_en,
  meta8,
  hash_amount,
  hashData,
  amount,
  itemId,
  env,
  type,
  merchantId,
  //credit,simple
  payType,
  //ticket, seminar, message
  buyType,
  //logData(메시지 이외의 type일때만 사용)
  logData,
) => {
  console.log(
    url_store_id,
    date,
    time,
    UUID,
    store_name,
    store_name_en,
    meta8,
    hash_amount,
    hashData,
    amount,
    itemId,
    env,
    type,
  );
  SETTLE_PG.pay(
    {
      env: env,
      //상점아이디(필수)
      mchtId: url_store_id,
      //결제수단(필수)
      method: type === 'card' ? type : 'corp',
      //요청일자(필수)
      trdDt: date,
      //요청시간(필수)
      trdTm: time,
      //상점 주문번호(필수)
      mchtTrdNo: UUID,
      //상점 한글명(필수)
      mchtName: store_name,
      //상점 영문명(필수)
      mchtEName: store_name_en,
      //상품명(필수)
      pmtPrdtNm: meta8,
      //거래금액(필수)_암호화
      trdAmt: hash_amount,
      //고객명(필수 x)
      // mchtCustNm: "홍길동",
      // custAcntSumry: "헥토파이낸셜",
      //결제 후 결과 전달되는 페이지(필수)
      notiUrl: `${
        window.location.origin.includes('dev') || window.location.origin.includes('localhost')
          ? 'https://dev-kiosk-api-server.letmeup.co.kr'
          : 'https://kiosk-api-server.letmeup.co.kr'
      }${buyType === 'message' ? '/webPayment/msgPointWebpay' : '/webPayment/webpayRequest'}`,

      // `${
      //   window.location.origin.includes("dev") ||
      //   window.location.origin.includes("localhost")
      //     ? "https://dev-kiosk-api-server.letmeup.co.kr"
      //     : "https://kiosk-api-server.letmeup.co.kr"
      // }

      //결제 후 결과 전달 및 이동페이지 URL
      nextUrl:
        buyType === 'message'
          ? `${window.location.origin}/admin/success`
          : `${window.location.origin}/success`,
      //고객 강제 종료 시 결과 전달 및 이동페이지(필수)
      cancUrl:
        buyType === 'message'
          ? `${window.location.origin}/admin/fail`
          : `${window.location.origin}/fail`,
      //기타 주문 정보를 입력하는 상점 예약 필드
      mchtParam:
        //메시지
        buyType === 'message'
          ? `merchantId=${merchantId}&msgItemId=${itemId}&payMethod=${payType}`
          : buyType === 'ticket' //이용권
          ? `ticketItemId=${logData.ticketItemId}&merchantId=${merchantId}&payMethod=${payType}&phoneNum=${logData.phoneNum}&startTime=${logData.startTime}&startDate=${logData.startDate}&day=${logData.day}&remainTime=${logData.remainTime}&seatId=${logData.seatId}&seatNumber=${logData.seatNumber}&expireDate=${logData.expireDate}`
          : //세미나
            `ticketItemId=${logData.ticketItemId}&merchantId=${merchantId}&payMethod=${payType}&phoneNum=${logData.phoneNum}&startDate=${logData.startDate}&startTime=${logData.startTime}&seatId=${logData.seatId}&seatNumber=${logData.seatNumber}&seminarName=${logData.seminarName}&seminarId=${logData.seminarId}&seminarCount=${logData.seminarCount}`,
      //고객 IP주소
      // custIp: "127.0.0.1",
      //SHA256 방식으로 생성한 해쉬(필수)
      pktHash: hashData,
      //간편결제 코드
      corpPayCode: type ? type : null,
      corpPayType: type === 'NVP' ? 'CARD' : null,
      cashRcptUIYn: type === 'NVP' ? 'N' : null,
      ui: {
        type: 'self',
        width: '430',
        height: '660',
      },
    },
    function (rsp) {
      console.log(rsp);
    },
  );
};

//트리거
export const trigger = (triggerData, setTriggerData) => {
  if (triggerData) {
    return trigger;
  } else {
    setTriggerData(true);
    return false;
  }
};

// 유저 결제정보 불러오기
export const getUserPayData = async (merchantId) => {
  try {
    const { status, data } = await getMerchantInfo(merchantId);
    if (status === 200) {
      //데이터가 아예 없을경우
      if (data[0].credit_url_store_id === null && data[0].simple_url_store_id === null) {
        return {
          credit_store_id: data[0].credit_url_store_id,
          simple_store_id: data[0].simple_url_store_id,
        };
      } else {
        return {
          credit_store_id: data[0].credit_url_store_id,
          simple_store_id: data[0].simple_url_store_id,
        };
      }
    }
  } catch (err) {
    Swal.fire({
      text: '결제 정보를 불러올 수 없습니다. \n 관리자에게 문의해주세요',
      timer: 3500,
    });
    return {
      credit_store_id: null,
      simple_store_id: null,
    };
  }
};

// hecto 응답 파라미터 받아오기
const getParameter = (param) => {
  let requestParam;
  //현재 주소를 decoding
  // eslint-disable-next-line no-restricted-globals
  const url = unescape(location.href);
  // eslint-disable-next-line no-restricted-globals
  console.log(url, location.href);
  //파라미터만 자르고, 다시 &그분자를 잘라서 배열에 넣는다.
  const paramArr = url.substring(url.indexOf('?') + 1, url.length).split('&');

  for (var i = 0; i < paramArr.length; i++) {
    var temp = paramArr[i].split('='); //파라미터 변수명을 담음

    if (temp[0].toUpperCase() == param.toUpperCase()) {
      // 변수명과 일치할 경우 데이터 삽입
      requestParam = paramArr[i].split('=')[1];
      break;
    }
  }
  return requestParam;
};

export const swalMessage = (
  type,
  text,
  confirmButtonText,
  showCancelButton,
  onClickConfirm,
  onClickReject,
  cancelButtonText,
) =>
  Swal.fire({
    icon: type,
    html: text ? text.replaceAll('\n', '<br />') : '',
    confirmButtonText: confirmButtonText || '확인',
    showCancelButton: showCancelButton,
    cancelButtonText: cancelButtonText ?? '취소',
    reverseButtons: true,
  }).then((result) => {
    if (result.isConfirmed) return onClickConfirm && onClickConfirm();
    return onClickReject && onClickReject();
  });

export const swalSuccess = (text) =>
  Swal.fire({
    icon: 'success',
    text: `성공적으로 ${text}되었습니다.`,
    confirmButtonText: '확인',
    timer: 3500,
  });
