import React, { Fragment, useEffect, useState } from 'react';
import Breadcrumbs from '../layout/breadcrumb';
import { Container, Row, Col, Card, CardBody, Input, CardFooter } from 'reactstrap';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import { merchantId_api, postSendEveryMessage } from '../lib/api';
import { Label1 } from './lockerChange';

const InputS = styled(Input).attrs({ required: true })``;

const TextareaStyle = styled.textarea`
  width: 100%;
  height: 20vh;
  margin: 10px 0;
  border: 1px solid #e6e4e9;
  border-radius: 0.25rem;
  resize: none;
`;

const FlexContainer = styled.div`
  display: flex;
  width: 43vw;
  justify-content: space-between;
  div {
    width: 45%;
    p {
      float: right;
    }
  }

  @media screen and (max-width: 768px) {
    width: 80vw;
  }

  @media screen and (max-width: 480px) {
    width: 100%;
    flex-direction: column;
    div {
      width: 100%;
    }
  }
`;

let merchantId = '';

const MessageSend = () => {
  const [phoneNum, setPhoneNum] = useState('010');
  const [msg, setMsg] = useState('');
  const [msgPreview, setMsgPreview] = useState();
  const [isShowPhoneNum, setIsShowPhoneNum] = useState(false);
  const [phoneNumList, setPhoneNumList] = useState([]);

  useEffect(() => {
    getMerchantId();
  }, []);

  const getMerchantId = async () => {
    const { data } = await merchantId_api();
    if (data) {
      merchantId = data.id;
    }
  };

  const handleOnchange = ({ target }) => {
    if (target.name === 'phoneNum') {
      setPhoneNum(target.value);
    } else {
      setMsg(target.value);
      setMsgPreview('[Web발신]\n' + target.value);
    }
  };

  //메시지 array변환
  const showPhoneNumList = () => {
    const OnlyNumber = /[^0-9.]/;
    if (phoneNum.length !== 11) {
      Swal.fire({
        icon: 'error',
        title: '전화번호는 11자리로 입력해주세요',
        timer: 2000,
      });
    } else if (OnlyNumber.test(phoneNum)) {
      Swal.fire({
        icon: 'error',
        title: '숫자만 입력해주세요',
        timer: 2000,
      });
    } else {
      if (phoneNum) {
        setIsShowPhoneNum(true);
        setPhoneNumList([...phoneNumList, phoneNum]);
        setPhoneNum('010');
      }
    }
  };

  //엔터키 이벤트
  const clickEnter = () => {
    if (window.event.keyCode === 13) {
      showPhoneNumList();
    }
  };

  const deletePhoneNum = (index) => {
    let array = [...phoneNumList];
    array.splice(index, 1);
    setPhoneNumList(array);
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();

    try {
      let objectPhone = phoneNumList.map((item) => ({ phoneNum: item }));
      if (phoneNumList.length > 0 && msg.length > 0) {
        Swal.fire({
          text: '발송중입니다.',
          showConfirmButton: false,
        });
        const res = await postSendEveryMessage({
          phoneNum: objectPhone,
          mid: merchantId,
          msg,
        });

        if (res.status === 200) {
          Swal.fire({
            icon: 'success',
            title: '메시지 전송이 완료됐습니다.',
            timer: 2000,
          }).then(() => {
            setPhoneNum('010');
            setMsg('');
            setMsgPreview();
            setPhoneNumList([]);
          });
        } else if (res.status === 202) {
          Swal.fire({
            icon: 'error',
            title: '메시지포인트가 부족합니다. 충전후 사용 부탁드립니다.',
            timer: 3000,
          });
        } else if (res.status === 203) {
          Swal.fire({
            icon: 'error',
            title: '메시지 수신을 차단한 회원이 있습니다',
            timer: 3000,
          });
        }
      } else {
        Swal.fire({
          icon: 'error',
          title: '전화번호와 메시지를 입력해주세요.',
          timer: 2000,
        });
      }
    } catch (err) {
      console.log(err);
      Swal.fire({
        icon: 'error',
        title: '전송에 실패했습니다.',
        timer: 2000,
      }).then(() => {
        setPhoneNum('010');
        setMsg('');
      });
    }
  };

  return (
    <Fragment>
      <Breadcrumbs parent="메시지 관리" title="메시지 전송" />
      <Container fluid={true}>
        <Row>
          <Col sm="12" style={{ paddingLeft: 5, paddingRight: 5 }}>
            <Card>
              <CardBody>
                <h5 style={{ marginBottom: '20px' }}>개별 메시지전송</h5>
                <Label1>
                  <h5>
                    전화번호 (숫자만 입력 후 엔터 또는 추가를 눌러주세요.) <strong>*</strong>
                  </h5>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <InputS
                      type="text"
                      placeholder="전화번호"
                      name="phoneNum"
                      onChange={handleOnchange}
                      value={phoneNum || '010'}
                      onKeyUp={clickEnter}
                    />
                    <button
                      className="btn-primary btn-sm"
                      style={{
                        width: '80px',
                        height: '30px',
                        marginTop: '5px',
                        marginLeft: '10px',
                      }}
                      onClick={showPhoneNumList}>
                      추가
                    </button>
                  </div>
                  {isShowPhoneNum && (
                    <div>
                      <p>메시지 전송 전화번호 리스트</p>
                      {phoneNumList.map((item, index) => (
                        <p key={'p' + index}>
                          {index + 1}. {item} <span onClick={() => deletePhoneNum(index)}>x</span>
                        </p>
                      ))}
                    </div>
                  )}
                </Label1>
                <Label1>
                  <FlexContainer>
                    <div>
                      <h5>전송메시지</h5>

                      <TextareaStyle name="msg" onChange={handleOnchange} value={msg || ''} />
                    </div>
                    <div>
                      <h5>미리보기</h5>
                      <TextareaStyle value={msgPreview || ''} disabled />
                      <p>{msg.length}/2,000</p>
                    </div>
                  </FlexContainer>
                </Label1>
                <CardFooter className="text-center">
                  <button onClick={handleOnSubmit} className="btn btn-primary">
                    전송하기
                  </button>
                </CardFooter>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default MessageSend;
