import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import IconMapviewer from '../../../assets/images/dashboard/mapviewer.svg';
import IconTicket from '../../../assets/images/dashboard/search_ticket.svg';
import IconInAndOut from '../../../assets/images/dashboard/search_inandout.svg';
import IconMember from '../../../assets/images/dashboard/search_member.svg';
import IconOpenDoor from '../../../assets/images/dashboard/remote_opendoor.svg';
import IconHelp from '../../../assets/images/dashboard/help.svg';
import { useHistory } from 'react-router-dom';
import { DashboardCard } from '../../commonStyle';
import { openDoor } from '../../../lib/socket';
import OpenDoorModal from '../../modal/openDoorModal';
import Swal from 'sweetalert2';

const StyleComponent = styled(DashboardCard)`
  height: fit-content;
  display: grid;
  grid-template-columns: ${({ columns }) => `repeat(${columns || 3}, 1fr)`};
  gap: 0.5rem;
  justify-items: center;
`;

const QuickBtn = styled.button`
  /* width: 4.16vw; */
  width: 100%;
  /* height: 4.16vw; */
  border-radius: 0.5rem;
  background-color: var(--dashboard-default);
  border: none;
  color: #ffffff;
  font-size: 1rem;
  font-weight: bold;
  gap: 0.5rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0.25rem;
  word-break: keep-all;
  img {
    width: 2rem;
  }
  &:hover {
    background-color: #2f6d84;
  }

  @media screen and (max-width: 805px) {
    /* width: 33.88vw; */
    /* height: 13.33vw; */
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
const QuickMenu = ({ isMobile, isMulti, mid }) => {
  const history = useHistory();
  const menuList = [
    { name: '맵 뷰어', img: IconMapviewer, path: '/seat/list' },
    { name: '입장권 조회', img: IconTicket, path: '/ticket/ticket-list' },
    { name: '지점 출입 조회', img: IconInAndOut, path: '/exit-data' },
    { name: '회원 정보 조회', img: IconMember, path: '/user/userlist' },
    { name: '원격 문열기', img: IconOpenDoor, path: 'door' },
    { name: '가이드', img: IconHelp, path: 'guide' },
  ];
  const [isDoorModal, setIsDoorModal] = useState(false);

  const goPage = (path) => {
    if (path === 'door') {
      if (isMulti) {
        console.log(isMulti);
        setIsDoorModal(true);
      } else {
        openDoor(mid);
        console.log(mid);
      }
    } else if (path === 'guide') {
      Swal.fire({
        text: '현재 준비중입니다.',
        timer: 5000,
      });
    } else {
      history.push(path);
    }
  };

  return (
    <Fragment>
      {isDoorModal && isMulti && (
        <OpenDoorModal show={isDoorModal} onClose={setIsDoorModal} data={isMulti} id={mid} />
      )}
      <StyleComponent>
        {menuList.map((item) =>
          isMobile && (item.path === '/user/userlist' || item.path === 'guide') ? (
            <></>
          ) : (
            <QuickBtn onClick={() => goPage(item.path)}>
              {!isMobile && <img src={item.img} alt="icon" />}
              {item.name}
            </QuickBtn>
          ),
        )}
      </StyleComponent>
    </Fragment>
  );
};

export const MobileQuickMenu = ({ isMulti, mid, itemRef }) => {
  const history = useHistory();
  const menuList = [
    { name: '입장권 조회', img: IconTicket, path: '/ticket/ticket-list' },
    { name: '지점 출입 조회', img: IconInAndOut, path: '/exit-data' },
    { name: '맵 뷰어', img: IconMapviewer, path: '/seat/list' },
    { name: '원격 문 열기', img: IconOpenDoor, path: 'door' },
  ];
  const [isDoorModal, setIsDoorModal] = useState(false);

  const goPage = (path) => {
    if (path === 'door') {
      if (isMulti) {
        setIsDoorModal(true);
      } else {
        openDoor(mid);
      }
    } else {
      history.push(path);
    }
  };

  return (
    <Fragment>
      {isDoorModal && isMulti && (
        <OpenDoorModal show={isDoorModal} onClose={setIsDoorModal} data={isMulti} id={mid} />
      )}
      <StyleComponent columns={2} ref={itemRef}>
        {menuList.map((item) => (
          <QuickBtn onClick={() => goPage(item.path)}>
            <img src={item.img} alt="icon" />
            {item.name}
          </QuickBtn>
        ))}
      </StyleComponent>
    </Fragment>
  );
};

export default QuickMenu;
