import React, { useEffect, useMemo, useState, ReactNode } from 'react';
import { ModalDispatchContext, ModalsStateContext } from '../store/modalContext';

const ModalsProvider: React.FC<ModalsProviderProps> = ({ children }) => {
  const [openedModals, setOpenedModals] = useState<Modal[]>([]);

  const open = (Component: () => JSX.Element, props: object) => {
    setOpenedModals((modals) => [...modals, { Component, props }]);
  };

  const close = (Component: () => JSX.Element) => {
    setOpenedModals((modals) => modals.filter((modal) => modal.Component !== Component));
  };

  const reset = () => {
    setOpenedModals([]);
  };

  const dispatch = useMemo(() => ({ open, close, reset }), []);

  return (
    <ModalsStateContext.Provider value={openedModals}>
      <ModalDispatchContext.Provider value={dispatch}>{children}</ModalDispatchContext.Provider>
    </ModalsStateContext.Provider>
  );
};

export default ModalsProvider;
