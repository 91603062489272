import React, { useState } from 'react';

import { Check, CheckCircle, PlusCircle, User, XCircle } from 'react-feather';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import { getMapViewList, postCreateSeat } from '../../lib/api';
import { Flex, FlexCenter, FlexCenterH, FlexCenterV } from '../../style/flexStyle';

import SelectSearchBar from '../atom/SelectSearchBar';
import { forceEnter, getUserList, userTicketList } from '../../lib/api/v2';
import { Text } from '../../style/textStyle';
import { Button } from '../../style/atomStyle';
import moment from 'moment';
import TableComponent from '../table/TableComponent';

const Btns = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-top: 10px;
`;
export const ButtonS = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 30%;
  padding: 8px 12px;
  border-radius: 4px;
  border: none;
  background-color: ${(props) => props.bg};
  color: ${(props) => (props.color ? props.color : '#fff')};
  font-weight: 600;
  font-size: 15px;
  > svg {
    height: 17px;
  }
  @media screen and (max-width: 480px) {
    padding: 6px 10px;
    font-size: 13px;
    > svg {
      height: 15px;
    }
  }
`;

export const ModalBackground = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  z-index: 101;
`;

const EmptyBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 1rem;
  border: 1px solid #ddd;
`;

const UserItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #eee;
  border-radius: 0.5rem;
  padding: 0.5rem;
  cursor: pointer;

  background: #fcfcfd;
  &:hover {
    background-color: #eee;
  }
`;
const SeatBlockModal = ({ isOpen, onClose, seatData, setLists, lists, floorNum, fetch }) => {
  const [isSelectMember, setIsSelectMember] = useState(false);

  const [userList, setUserList] = useState();
  const [tableData, setTableData] = useState();
  const [selectedUserId, setSelectedUserId] = useState();

  const changeBlock = async (blockType) => {
    Swal.fire({
      showCancelButton: true,
      confirmButtonColor: '#04c9db',
      cancelButtonColor: '#F53C14',
      confirmButtonText: '확인',
      cancelButtonText: '취소',
      reverseButtons: true,
      title: '좌석설정을 변경하시겠습니까?',
      html: `${blockType === 'unBlock' ? '좌석열기 실행' : '좌석막기 실행'}`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        let changeData;
        if (blockType === 'unBlock') {
          changeData = [{ ...seatData, status: 'idle' }];
        } else {
          changeData = [{ ...seatData, status: 1 }];
        }

        const res = await postCreateSeat({
          seat: changeData,
          merchantId: seatData.merchantId,
        });

        try {
          if (res.status === 200) {
            Swal.fire({
              icon: 'success',
              title: '변경이 완료됐습니다.',
              timer: 3000,
            }).then(() => getSeatListApi());
          } else if (res) {
            Swal.fire({
              icon: 'error',
              title: '변경에 실패했습니다. 다시 시도해주세요.',
              timer: 3000,
            });
          }
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: '변경에 실패했습니다. 다시 시도해주세요.',
            timer: 3000,
          });
        }
      }
    });
  };

  const getSeatListApi = async () => {
    const res = await getMapViewList(seatData.merchantId);
    if (res.status === 200) {
      if (floorNum) {
        setLists(
          res.data
            .filter((item) => item.type === 'seat' || item.type === 'seminar')
            .filter((list) => list.floor === +floorNum),
        );
      } else {
        setLists(res.data.filter((item) => item.type === 'seat' || item.type === 'seminar'));
      }

      onClose();
    }
  };

  const enterUser = async (userId, ticket) => {
    try {
      const apiData = {
        merchantId: seatData.merchantId,
        userId,
        ticketId: ticket.ticketId,
        seatId: seatData.id,
      };

      const res = await forceEnter(apiData);

      if (res.status === 200) {
        Swal.fire({
          icon: 'success',
          title: res.data,
          timer: 3000,
        }).then(() => {
          fetch();
        });
      }
    } catch (e) {
      Swal.fire({
        icon: 'error',
        title: e.data,
        timer: 5000,
      }).then(() => {
        onSearchUserTicketList(userId);
        // fetch();
      });
    }
  };

  const onSearchUserTicketList = async (userId) => {
    try {
      const res = await userTicketList(seatData.merchantId, userId, seatData.type);

      if (res.status === 200) {
        const mappedData = res.data.map((item) => {
          const endDate = moment(item.endDate, 'YYYY-MM-DD HH:mm:ss'); // 이용권 종료 날짜
          const nowDate = moment(new Date(), 'YYYY-MM-DD HH:mm:ss'); // 오늘 날짜

          const expireDays = Math.ceil(moment.duration(moment(endDate).diff(nowDate)).asDays());

          const remainTime = item.remainTime / 60;

          const ticketType =
            item.ticketType === 2
              ? '기간권'
              : item.ticketType === 3
              ? '시간정액권'
              : item.ticketType === 4
              ? '세미나실'
              : '-';

          return {
            ...item,
            type: `${ticketType} / ${item.level}등급`,
            select: (
              <FlexCenter>
                <Button bg={`var(--theme-default)`} onClick={() => enterUser(userId, item)}>
                  선택
                </Button>
              </FlexCenter>
            ),
            usagePeriod: `${moment(item.startDate).format('YYYY-MM-DD HH:mm')} ~ ${moment(
              item.endDate,
            ).format('YYYY-MM-DD HH:mm')}`,
            remainTime:
              item.ticketType === 3 || item.ticketType === 4
                ? item.remainTime < 0
                  ? '-'
                  : `${remainTime % 1 !== 0 ? remainTime.toFixed(1) : remainTime}분 남음`
                : expireDays > 0
                ? `${expireDays}일`
                : '-',
          };
        });
        setTableData(mappedData);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const onSearchUser = async (value, option) => {
    try {
      const key = option === '이름' ? 'name' : 'phoneNum';
      const res = await getUserList(seatData.merchantId, { [key]: value });

      if (res.status === 200) {
        if (res.data && res.data.length) {
          setUserList(res.data);
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <Modal
        centered
        isOpen={isOpen}
        toggle={onClose}
        style={{
          minWidth: window.innerWidth < 480 ? '' : window.innerWidth < 768 ? '80vw' : '',
        }}>
        <ModalHeader className="modal-title" toggle={onClose}>
          {seatData.number}번{' '}
        </ModalHeader>
        <ModalBody style={isSelectMember ? { paddingBottom: '2rem' } : {}}>
          <h5>{seatData.phoneNum}</h5>
          {isSelectMember && <Text color="#555">좌석 등급 : {seatData.level}</Text>}
          <Btns>
            {seatData.status === 'block' && (
              <ButtonS bg="#21D7D9" onClick={() => changeBlock('unBlock')}>
                좌석열기 <PlusCircle />
              </ButtonS>
            )}
            {seatData.status === 'idle' &&
              (!isSelectMember ? (
                <FlexCenterV gap={1} full>
                  <ButtonS bg="#28a745" onClick={() => setIsSelectMember(true)}>
                    회원 지정 <CheckCircle />
                  </ButtonS>
                  <ButtonS bg="#F53C14" onClick={() => changeBlock('block')}>
                    좌석막기 <XCircle />
                  </ButtonS>
                </FlexCenterV>
              ) : (
                <FlexCenterH column gap={1.5} full>
                  <FlexCenterH column gap={1}>
                    <Text>지정할 회원을 검색해주세요.</Text>
                    <SelectSearchBar
                      selectList={[
                        { title: '이름', placeholder: '이름을 검색하세요.' },
                        { title: '전화번호', placeholder: '전화번호를 검색하세요.' },
                      ]}
                      onSearch={onSearchUser}
                    />
                  </FlexCenterH>

                  {userList && userList.length && (
                    <Flex
                      column
                      gap={0.5}
                      style={{
                        border: '1px solid #eee',
                        borderRadius: '0.5rem',
                        padding: '0.5rem',
                        maxHeight: '11.5rem',
                        overflowY: 'auto',
                      }}>
                      {userList.map((user) => (
                        <UserItem
                          style={{ background: selectedUserId === user.id ? '#eee' : '#fcfcfd' }}
                          onClick={() => {
                            setSelectedUserId(user.id);
                            onSearchUserTicketList(user.id);
                          }}>
                          <FlexCenterV gap={0.5}>
                            <User />
                            <Text>{`${user.name}(${user.phoneNum})`}</Text>
                          </FlexCenterV>
                          {selectedUserId === user.id ? <Check /> : <Button>입장권 조회</Button>}
                        </UserItem>
                      ))}
                    </Flex>
                  )}

                  {tableData &&
                    (tableData.length ? (
                      <Flex full style={{ height: '30rem', overflow: 'auto' }}>
                        <TableComponent
                          data={tableData}
                          columns={[
                            { accessor: 'type', Header: '유형', width: '7rem' },
                            { accessor: 'usagePeriod', Header: '이용기간', width: '18rem' },
                            { accessor: 'remainTime', Header: '잔여시간', width: '7rem' },
                            { accessor: 'select', Header: '선택', width: '5rem' },
                          ]}
                        />
                      </Flex>
                    ) : (
                      <EmptyBox>조회된 데이터가 없습니다.</EmptyBox>
                    ))}
                </FlexCenterH>
              ))}
          </Btns>
        </ModalBody>
      </Modal>
      <ModalBackground />
    </>
  );
};

export default SeatBlockModal;

// <DataTable data={tableData} columns={mapViewUserListColumn} striped={true} center={true} />
