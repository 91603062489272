import { createGlobalStyle } from 'styled-components';

/**
 * CSS Normalization
 */
const GlobalStyle = createGlobalStyle`

button {
    background: none;
    border:none;
}
`;

export default GlobalStyle;
