import React, { useState, useEffect } from 'react';

const KSPayWebForm = ({ payData }: { payData: any }) => {
  const API_URL = process.env.REACT_APP_API_URL_V2;

  const [form, setForm] = useState({
    sndPaymethod: '1000000000', // Default: 신용카드
    sndStoreid: payData.url_store_id,
    sndOrdernumber: payData.merchant_uid,
    sndGoodname: payData.name,
    sndAmount: payData.amount,
    sndOrdername: payData.userName,
    sndShowcard: 'C',
    sndCurrencytype: 'WON',
    sndInstallmenttype: 'ALL(0:2:3:4:5:6:7:8:9:10:11:12)',
    sndInteresttype: 'NONE',
    sndEmail: '',
    sndMobile: '',
    sndReply: `${API_URL}/payment/ticket/kspay/log?payData=${payData.payData}`,
    sndGoodType: '1',
    sndCharSet: 'utf-8',
    sndStoreCeoName: '',
    sndStorePhoneNo: '',
    sndStoreAddress: '',
    sndEscrow: '0',
    sndCashReceipt: '0',
    isReady: false,
  });

  useEffect(() => {
    if (payData) {
      setForm({
        ...form,
        sndStoreid: payData.url_store_id,
        sndOrdernumber: payData.merchant_uid,
        sndGoodname: payData.name,
        sndAmount: payData.amount,
        sndOrdername: payData.userName,
        sndReply: `${API_URL}/payment/ticket/kspay/log?payData=${payData.payData}`,
        isReady: payData.isReady,
      });
    }
  }, [payData]);

  useEffect(() => {
    if (!form.isReady) return;
    requestKSPay();
  }, [form]);

  return (
    <form name="KSPayWeb" method="post" style={{ display: 'none' }}>
      <input type="text" name="sndPaymethod" value={form.sndPaymethod} />
      <input type="text" name="sndStoreid" value={form.sndStoreid} />
      <input type="text" name="sndOrdernumber" value={form.sndOrdernumber} />
      <input type="text" name="sndGoodname" value={form.sndGoodname} />
      <input type="text" name="sndAmount" value={form.sndAmount} />
      <input type="text" name="sndOrdername" value={form.sndOrdername} />
      <input type="text" name="sndEmail" value={form.sndEmail} />
      <input type="text" name="sndMobile" value={form.sndMobile} />
      <input type="text" name="sndReply" value={form.sndReply} />
      <input type="text" name="sndGoodType" value={form.sndGoodType} />
      <input type="text" name="sndCharSet" value={form.sndCharSet} />
      <input type="text" name="sndShowcard" value={form.sndShowcard} />
      <input type="text" name="sndCurrencytype" value={form.sndCurrencytype} />
      <input type="text" name="sndInstallmenttype" value={form.sndInstallmenttype} />
      <input type="text" name="sndInteresttype" value={form.sndInteresttype} />
      <input type="text" name="sndStoreCeoName" value={form.sndStoreCeoName} />
      <input type="text" name="sndStorePhoneNo" value={form.sndStorePhoneNo} />
      <input type="text" name="sndStoreAddress" value={form.sndStoreAddress} />
      <input type="text" name="sndEscrow" value={form.sndEscrow} />
      <input type="text" name="sndCashReceipt" value={form.sndCashReceipt} />
    </form>
  );
};

export default KSPayWebForm;

export const requestKSPay = () => {
  const isMobile = window.innerWidth < 806;
  const _frm = (document as KSPayExtendedDocument).KSPayWeb;
  if (isMobile) _frm.action = 'https://kspay.ksnet.to/store/KSPayMobileV1.4/KSPayPWeb.jsp';
  else _frm.action = 'https://kspay.ksnet.to/store/KSPayWebV1.4/KSPayPWeb.jsp';
  _frm.submit();
};
