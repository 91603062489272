import React, { forwardRef } from 'react';
import styled from 'styled-components';
// eslint-disable-next-line
import DatePicker from 'react-datepicker';
import SelectDate from '../selectDate';
import { Calendar } from 'react-feather';
import { ko } from 'date-fns/locale';
import SelectDateFuture from '../selectDateFuture';

// interface DatePickerProps {
//   withCalendar?: boolean;
//   startDate: Date;
//   endDate: Date;
//   startMinDate?: Date;
//   startMaxDate?: Date;
//   endMinDate?: Date;
//   endMaxDate?: Date;
//   setStartDate: (value: React.SetStateAction<Date | null>) => void;
//   setEndDate: (value: React.SetStateAction<Date | null>) => void;
// }

// interface CalendarSelectProps {
//   value?: string;
//   onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
// }

const CalendarSelect = forwardRef(({ value, onClick }, ref) => (
  <DatePickerS onClick={onClick} ref={ref}>
    {value}
    <Calendar />
  </DatePickerS>
));

const DatePickerComponent = ({
  withCalendar,
  withFuture,
  startDate,
  startMinDate,
  startMaxDate,
  endDate,
  endMinDate,
  endMaxDate,
  setStartDate,
  setEndDate,
}) => {
  return (
    <StyledDatePicker>
      <DatePicker
        selected={startDate}
        locale={ko}
        selectsStart
        minDate={startMinDate || undefined}
        maxDate={startMaxDate || undefined}
        onChange={(val) => setStartDate(val)}
        customInput={withCalendar ? <CalendarSelect /> : undefined}
      />
      <h5> ~ </h5>
      <DatePicker
        selected={endDate}
        locale={ko}
        selectsEnd
        minDate={endMinDate || undefined}
        maxDate={endMaxDate || undefined}
        onChange={(val) => setEndDate(val)}
        customInput={withCalendar ? <CalendarSelect /> : undefined}
      />
      {withFuture ? (
        <SelectDateFuture setStartDate={setStartDate} setEndDate={setEndDate} />
      ) : (
        <SelectDate setStartDate={setStartDate} setEndDate={setEndDate} />
      )}
    </StyledDatePicker>
  );
};

export default DatePickerComponent;

const DatePickerS = styled.button`
  background: #f8fcff;
  border: 1px solid #e9e9e9;
  border-radius: 4px;
  padding: 6px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  > svg {
    /* margin-left: 2vw; */
    width: 17px;
    color: #ccc;
  }
  :active,
  :focus {
    border: 1px solid #05c8da;
  }
`;

const StyledDatePicker = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 0.5rem;

  h5 {
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin: 0 8px; */
    font-weight: 400;
    margin: 0;
  }
  > div > div > button {
    width: 100%;
  }
  select {
    background: #f8fcff;
    border: 1px solid #e9e9e9;
    border-radius: 4px;

    /* margin-left: 10px; */
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    display: grid;
    grid-template-columns: auto 1rem auto auto;
    align-items: center;

    select,
    .react-datepicker-wrapper {
      width: 100%;
    }
  }

  @media screen and (max-width: 480px) {
    gap: 0.25rem;
    font-size: 0.75rem;
    svg {
      display: none;
    }
  }
`;
