import React from 'react';
import styled from 'styled-components';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import configDB from '../data/customizer/config';
import Arrow from '../assets/images/dashboard/pageArrow.svg';
import Arrow2 from '../assets/images/dashboard/pageArrow2.svg';

const primary = localStorage.getItem('default_color') || configDB.data.color.primary_color;

const PaginationItemS = styled(PaginationItem)`
  border: none;
  display: flex;
  align-items: center;
  margin: 0 0.1vw;
  :nth-child(${(props) => (props.page % 10 !== 0 ? (props.page % 10) + 2 : 12)}) {
    .page-link {
      color: #fff !important;
      background-color: ${primary};
      border-radius: 0.2vw;
      :focus {
        box-shadow: none;
      }
    }
  }

  .page-link {
    width: 1.25vw;
    height: 1.25vw;
    border: none;
    font-size: 0.62vw;
    padding: 0.2vw 0.4vw;
    background-color: #cfd8e2;
    color: #fff !important;
    border-radius: 0.2vw;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.26vw;
    :focus {
      box-shadow: none;
    }
    :hover {
      background-color: #b2f1f6;
    }
  }
  @media screen and (max-width: 805px) {
    font-size: 3.33vw;
    margin: 0 1.1vw;
    :nth-child(${(props) => (props.page % 10 !== 0 ? (props.page % 10) + 2 : 12)}) {
      .page-link {
        border-radius: 0.55vw;
      }
    }
    .page-link {
      padding: 1.1vw 2vw;
      width: 5.55vw;
      height: 5.55vw;
      border-radius: 0.55vw;
      font-size: 3.33vw;
      :hover {
        background-color: ${primary};
      }
    }
  }

  /* @media screen and (max-width: 768px) {
    .page-link {
      margin: 0;
    }
  }

  @media screen and (max-width: 480px) {
    .page-link {
      padding: 0.3rem 0.5rem;
    }
  }
  @media screen and (max-width: 375px) {
    .page-link {
      padding: 0.15rem 0.4rem;
    }
  } */
`;

const PageContainer = styled.div`
  .page-link {
    border: none !important;
    font-size: 0.62vw;
    width: 1.25vw;
    height: 1.25vw;
    color: #91979e !important;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.26vw;
  }
  @media screen and (max-width: 805px) {
    font-size: 3.33vw;
    .page-link {
      padding: 1.1vw 2vw;
      width: 5.55vw;
      height: 5.55vw;
      :hover {
        background-color: #ffffff;
        border: none;
      }
    }
  }
`;

const PaginationComponent = ({
  setCurrentPage,
  totalLength,
  postsPerPage,
  currentPage,
  pagecount,
  padding,
}) => {
  // pagination
  let page = pagecount ? totalLength : Math.ceil(totalLength / postsPerPage);
  let pageItem = [];
  //출력되는 페이지
  let printPage = [];
  let arrayNum =
    currentPage % 10 === 0 ? Math.floor(currentPage / 10) - 1 : Math.floor(currentPage / 10);

  if (page) {
    for (let i = 0; i < page; i++) {
      pageItem.push(
        <PaginationItemS
          page={currentPage}
          key={i + 1}
          onClick={({ target }) => {
            setCurrentPage(+target.innerText);
          }}
        >
          <PaginationLink>{i + 1}</PaginationLink>
        </PaginationItemS>,
      );
    }
    if (pageItem.length > 10) {
      // printPage = pageItem.division(10);
      for (let i = 0; i < pageItem.length; i += 10) printPage.push(pageItem.slice(i, i + 10));
    }
  }

  return (
    <div
      style={{
        justifyContent: 'center',
        padding: padding === 0 ? '1vw 0 0' : '2vw 0 7vw',
        display: 'flex',
      }}
    >
      <Pagination className="pagination-primary pagination">
        <PaginationItem
          onClick={() => {
            currentPage - 10 > 0 ? setCurrentPage(currentPage - 10) : setCurrentPage(1);
          }}
        >
          <PageContainer style={{ color: '#91979e !important' }}>
            <PaginationLink>
              <span aria-hidden="true">
                <img style={{ transform: 'rotate(180deg)' }} src={Arrow2} alt="«" />
              </span>
              <span className="sr-only">{''}</span>
            </PaginationLink>
          </PageContainer>
        </PaginationItem>
        <PaginationItem
          onClick={() => {
            currentPage - 1 === 0 ? setCurrentPage(1) : setCurrentPage(currentPage - 1);
          }}
        >
          <PageContainer>
            <PaginationLink>
              <span aria-hidden="true">
                {' '}
                <img style={{ transform: 'rotate(180deg)' }} src={Arrow} alt="&lsaquo;" />
              </span>
              <span className="sr-only">{''}</span>
            </PaginationLink>
          </PageContainer>
        </PaginationItem>
        {printPage?.length > 0 ? printPage[arrayNum] : pageItem}
        <PaginationItem
          onClick={() => {
            +currentPage === +pageItem?.length
              ? setCurrentPage(+pageItem?.length)
              : setCurrentPage(currentPage + 1);
          }}
        >
          <PageContainer>
            <PaginationLink>
              <span aria-hidden="true">
                {' '}
                <img src={Arrow} alt="&rsaquo;" />
              </span>
              <span className="sr-only">{}</span>
            </PaginationLink>
          </PageContainer>
        </PaginationItem>
        <PaginationItem
          onClick={() => {
            currentPage + 10 < pageItem?.length
              ? setCurrentPage(currentPage + 10)
              : setCurrentPage(pageItem?.length);
          }}
        >
          <PageContainer>
            <PaginationLink>
              <span aria-hidden="true">
                <img src={Arrow2} alt="»" />
              </span>
              <span className="sr-only">{}</span>
            </PaginationLink>
          </PageContainer>
        </PaginationItem>
      </Pagination>
    </div>
  );
};
export default PaginationComponent;
