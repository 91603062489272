import React, { useState } from 'react';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import styled from 'styled-components';
import { Cookies } from 'react-cookie';
import {
  deleteImage,
  franchiseCalculate,
  getAdminMerchantList,
  getImagesAdmin,
  merchantCreate,
  merchantMsgAdd,
  postAdImgsAdmin,
  postBannerImgAdmin,
  userPassReset,
} from '../lib/api';
import Swal from 'sweetalert2';
import ConfigDB from '../data/customizer/config';
import { useEffect } from 'react';
import { joinRoom, merchantUpdateKiosk } from '../lib/socket';
import moment from 'moment';
import { ExampleCustomInput, LabelRow } from './ticketCustom';
import { ko } from 'date-fns/locale';
import DatePicker from 'react-datepicker';
import * as xlsx from 'xlsx';

import SelectDate from '../components/selectDate';
import { DatePickerStyle } from './seatInfo';
import PaginationComponent from '../components/pagination';
import FranchiseList from '../components/merchantPage/franchiseTable';
import NoticeBoard from './noticeBoard';
import { allCho, etcCho } from '../util/util';
import * as Hangul from 'hangul-js';
import MessageChargeInfo from '../components/merchantPage/messageChargeInfo';
import Loading from '../components/Loading';
import AdminCoupon from './adminCoupon';
import Franchise from '../components/merchantPage/franchise';

import Logo from '../assets/images/logo/logo.svg';
import { deleteLogout, postAdminMerchant } from '../lib/api/v2/auth';
import { ExcelButton } from '../components/common/Button';

const primary = localStorage.getItem('default_color') || ConfigDB.data.color.primary_color;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 5vw;
  margin: 0 auto;
  height: 100vh;
  background-color: #f8f8f8;
  > a {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 15vw;
      margin: 2vw 0 1vw;
    }
  }
  > ul {
    display: flex;
  }
  > button {
    width: 15%;
  }
  .show-480 {
    display: none;
  }
  @media screen and (max-width: 480px) {
    padding: 0 3vw;
    > a {
      margin: 20px;
      img {
        width: 50vw;
      }
    }
    > button {
      width: 40%;
    }
    .show-480 {
      display: block;
    }
  }
`;

const NavList = styled.li`
  background-color: #fff;

  border-bottom: ${(props) => (props.tab ? `1px solid #fff` : primary)};
  padding: 0.6vw 1vw;
  box-shadow: ${(props) => (props.tab ? `0px -3px 6px rgba(89, 102, 122, 0.1)` : 0)};

  cursor: pointer;
  z-index: ${(props) => props.tab && '99'};

  @media screen and (max-width: 425px) {
    padding: 0.6vw 2vw;
    margin-top: 20px;
  }
`;

const MerchantListS = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 2vw 0;
  background-color: #fff;
  > div {
    width: 60%;
    margin-bottom: 1vw;
    h4 {
      margin-bottom: 1vw;
    }
  }
  border-radius: 6px;
  border-top-left-radius: 0;
  box-shadow: 0px 3px 12px rgba(89, 102, 122, 0.15);
  .pw_input {
    display: flex;
    width: 40%;
    justify-content: space-between;
    .react-datepicker-wrapper {
      width: 45%;
    }
    p {
      width: 40%;
    }
  }
  .topbar {
    width: 90%;
    .selectContainer {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: flex-end;
    }
    .postsPerPage {
      select {
        background: #f8fcff;
        border: 1px solid #e9e9e9;
        border-radius: 4px;
        padding: 9px 12px;
        font-size: 14px;
        margin-right: 10px;
        margin-left: 15px;
      }
      .active {
        font-weight: 700;
      }
      p {
        margin: 0;
        margin-left: 2vw;
      }
      display: flex;
    }
    .date-pickers {
      display: flex;
      div {
        margin: 0;
      }
      p {
        margin: 0;
        cursor: pointer;
      }
    }
  }

  .imageComponent {
    .fileContainer {
      display: flex;
      button {
        width: 20%;
        margin-left: 5%;
      }
    }
    .sortInput {
      width: 10%;
    }
  }
  @media screen and (max-width: 480px) {
    form {
      display: flex;
      justify-content: center;
    }
    > div {
      margin-bottom: 3vw;
      width: 90%;
    }
    .pw_input {
      width: 90%;
      p {
        width: 40%;
      }
    }

    .topbar {
      width: 90%;
    }
  }
`;
const List = styled.ul`
  width: 50%;
  li {
    border: 1px solid ${primary};
    padding: 0.6vw 1vw;
    border-radius: 6px;
    margin-bottom: 0.6vw;
    cursor: pointer;
    :hover {
      background-color: ${primary};
      color: #fff;
    }
    transition: all 0.2s ease;
  }
  @media screen and (max-width: 480px) {
    width: 80%;
    padding: 20px 0;
    li {
      padding: 10px;
      margin-bottom: 10px;
    }
  }
`;
const FormS = styled(Form)``;
const FormGroupS = styled(FormGroup)`
  display: flex;
  align-items: center;
  justify-content: space-between;

  > label {
    margin-right: 10px;
  }

  > input {
    width: 60%;
  }
`;

const SearchContainer = styled(LabelRow)`
  width: 72%;
  margin: auto;
  > i {
    position: absolute;
    right: 10px;
  }
  @media screen and (max-width: 480px) {
    width: 80%;
  }
`;
export const checkPwFunc = (e) => {
  let pwCheck = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{6,}$/;
  let booleanCheck = pwCheck.test(e);

  if (!booleanCheck) {
    Swal.fire({
      icon: 'error',
      title: '비밀번호 구성이 올바르지 않습니다.',
      text: '비밀번호는 영문,숫자,특수문자(!@$%^&* 만 허용)를\n조합하여 6자 이상으로 구성하세요',
    });
  } else {
    return booleanCheck;
  }
};
const TableContainer = styled.div`
  width: 90% !important;
  overflow: scroll;

  table {
    width: 100%;
    border-top: 2px solid #222222;
  }
  tr {
    border-bottom: 1px solid #e2e2e2;
    :last-child {
      border-bottom: 2px solid #222222;
    }
  }
  @media screen and (max-width: 480px) {
    table {
      width: 850px;
    }
  }
`;
const SalesTable = styled.table`
  width: 60%;
  @media screen and (max-width: 480px) {
    width: 640px;

    .form-control {
      width: 100%;
    }
  }
`;

const TabContainer = styled.div`
  width: 100% !important;
  display: flex !important;
  justify-content: center;
`;
const ChoTabBtn = styled.button`
  background-color: ${({ selected }) => (selected ? primary : '#fff')};
  border: 1px ${primary} solid;
  color: ${({ selected }) => (selected ? '#fff' : primary)};
  padding: 4px 10px;
  width: 60px;
  border-radius: 50px;
  margin: 0 4px;
`;

const cookies = new Cookies();
const MerchantList = () => {
  const [lists, setLists] = useState([]);
  const [tab, setTab] = useState(0);
  const [distributor_id, setDistributor_id] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [form, setForm] = useState({
    code: '',
    name: '',
    contact: '',
    password: '',
  });
  const [merchantListData, setMerchantlistData] = useState([]);
  const [defaultData, setDefaultData] = useState([]);
  //검색전 기본데이터(가맹점관리)
  const [merchantListSearchData, setMerchantListSearchData] = useState([]);
  //검색어(가맹점관리)
  const [merchantListSearchKeyword, setMerchantListSearchKeyword] = useState('');
  const [pw, setPw] = useState({ password1: '', password2: '' });
  //전지점 매출현황
  const [startDate, setStartDate] = useState(
    moment(new Date()).subtract(1, 'M').startOf('month')._d,
  );
  const [endDate, setEndDate] = useState(moment(new Date()).startOf('month').subtract(1, 'd')._d);
  const level = localStorage.getItem('level');
  let totalArr = [];
  let regex = /[^0-9]/g;

  //페이지네이션
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);
  const indexOfLast = currentPage * postsPerPage;
  const indexOfFirst = indexOfLast - postsPerPage;

  const currentPosts = (tmp) => {
    let currentPosts = 0;
    currentPosts = tmp.slice(indexOfFirst, indexOfLast);
    return currentPosts;
  };

  //이미지(광고)
  const [bannerImage, setBannerImage] = useState([]);
  const [adImage, setAdImage] = useState([]);
  let image_count = [1, 2, 3, 4, 5];

  const [searchingCho, setSearchingCho] = useState(allCho);

  // 지점매출현황
  const [franchiseData, setFranchiseData] = useState([]);
  const [merchantSearchKeyword, setMerchantSearchKeyword] = useState('');
  const [franchiseDefaultData, setFranchiseDefaultData] = useState([]);

  //loading
  const [ready, setReady] = useState(true);

  const merchantList = async () => {
    try {
      const data = await getAdminMerchantList();
      if (data.status === 200) {
        let listData = [
          data.data,
          data.data.map((item, idx) => {
            item.addSMS = 0;
            item.name = item.name?.replace('공간샘', '').trim();
          }),
        ];

        setMerchantlistData(listData[0]);
        setMerchantListSearchData(listData[0]);
        // getAllmerchantSales();
        // getAdminImage();
      }
    } catch (error) {
      console.log(error);
      // Swal.fire('인증 관련 에러가 발생했습니다.\n 다시 접속해주세요').then(() => {
      //   localStorage.clear();
      //   window.location.href = `${process.env.PUBLIC_URL}/`;
      // });
    }
  };

  var doubleSubmitFlag = false;
  function doubleSubmitCheck() {
    if (doubleSubmitFlag) {
      return doubleSubmitFlag;
    } else {
      doubleSubmitFlag = true;
      return false;
    }
  }

  const handleSelectMerchant = async (mid) => {
    if (doubleSubmitCheck()) return;
    try {
      await postAdminMerchant(mid);
    } catch (err) {
      if (err.toString().includes('401' || '500')) {
        Swal.fire(`아이디 또는 비밀번호가 틀렸습니다.`);
      } else {
        Swal.fire(`에러가 발생했습니다. 잠시후 다시 시도해 주세요`);
      }
      doubleSubmitFlag = false;
    }
  };

  const handleOnChange = ({ target }) => {
    setForm({ ...form, [target.name]: target.value });
  };

  const handleSMSChange = ({ target }) => {
    let changeData;

    if (target.value.includes('-')) {
      changeData = merchantListData.map((item, idx) =>
        item.id == target.id
          ? { ...item, addSMS: +target.value.replace(regex, '') * -1 }
          : { ...item },
      );
    } else {
      changeData = merchantListData.map((item, idx) =>
        item.id == target.id ? { ...item, addSMS: +target.value.replace(regex, '') } : { ...item },
      );
    }

    setMerchantlistData(changeData);
  };

  const handleLogout = async () => {
    await deleteLogout();
  };

  const passwordOnSubmit1 = async (e) => {
    e.preventDefault();

    if (pw.password1 === pw.password2) {
      if (checkPwFunc(pw.password1)) {
        const res = await userPassReset({
          password: pw.password1,
          phoneNum: 'admin',
          merchantId: null,
        });

        if (res.status === 200) {
          Swal.fire({
            icon: 'success',
            title: '패스워드가 변경됐습니다.',
            timer: 2000,
          }).then(() => {
            window.location.reload();
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: '수정에 실패했습니다. 다시 시도해주세요.',
            timer: 2000,
          });
        }
      }
    } else {
      Swal.fire({
        icon: 'error',
        title: '비밀번호가 일치하지 않습니다. 똑같이 입력해주세요',
        timer: 2000,
      });
    }
  };

  const handlePassword = ({ target }) => {
    setPw({ ...pw, [target.name]: target.value });
  };

  //지점생성 관련 함수
  const createMerchant = async () => {
    if (doubleSubmitCheck()) return;

    if (form.code.length !== 6) {
      Swal.fire({
        icon: 'error',
        title: '지점번호는 6자리로 입력해주세요',
        timer: 2000,
      }).then((doubleSubmitFlag = false));
    } else if (
      form.code === '' ||
      form.password === '' ||
      form.name === '' ||
      form.contact === ''
    ) {
      Swal.fire({
        icon: 'error',
        title: '모든 데이터를 입력해주세요',
        timer: 2000,
      }).then((doubleSubmitFlag = false));
    } else if (form.password.length < 6) {
      Swal.fire({
        icon: 'error',
        title: '비밀번호는 최소 6자리로 입력해주세요',
        timer: 2000,
      });
    } else {
      let data = await merchantCreate(form);

      if (data.status === 202) {
        Swal.fire({
          icon: 'error',
          title: '이미 생성된 지점 코드입니다.',
          timer: 2000,
        }).then(setForm({ ...form, code: '' }), (doubleSubmitFlag = false));
      } else {
        Swal.fire({
          icon: 'success',
          title: '생성이 완료되었습니다.',
          timer: 2000,
        }).then(
          setForm({ code: '', name: '', contact: '', password: '' }),
          (doubleSubmitFlag = false),
        );
      }
    }
  };

  //전지점 매출 가져오기
  const getAllmerchantSales = async () => {
    try {
      setReady(true);
      let startDateFormet = moment(startDate).format('YYYY-MM-DD');
      let endDateFormet = moment(endDate).format('YYYY-MM-DD');
      let data = await franchiseCalculate(startDateFormet, endDateFormet);
      if (data.status === 200) {
        setMerchantSearchKeyword(null);
        totalData(data.data);
        setDefaultData(
          data.data,
          data.data.map(
            (item) => (item.createdAt = moment(item.createdAt).format('YYYY-MM-DD HH:mm:ss')),
          ),
        );
        setReady(false);
      }
    } catch (error) {
      // Swal.fire({
      //   icon: 'warning',
      //   title: '데이터를 가져올 수 없습니다. 다시 시도해주세요',
      //   timer: 2000,
      // });
    }
  };

  const addSMSData = async (idx) => {
    let result = await merchantMsgAdd({
      count: merchantListData[idx].addSMS,
      merchantId: merchantListData[idx].id,
    });
    if (result.status === 200) {
      Swal.fire({ title: '메시지가 충전되었습니다.', timer: 2000 }).then(merchantList);
    } else {
      Swal.fire({
        title: '메시지 충전 중 에러가 발생하였습니다..',
        timer: 2000,
      });
    }
  };
  useEffect(() => {
    merchantList();
    setCurrentPage(1);
    if (tab === 6) getAdminImage();
  }, [tab]);

  useEffect(() => {
    if (tab === 10) getAllmerchantSales();
  }, [startDate, endDate, tab]);

  const exportExcel = async () => {
    //table로 출력
    // let tableData = document.getElementById("franchiseTable");
    // const wb = xlsx.utils.table_to_book(tableData);
    // xlsx.writeFile(wb, `Text.xlsx`);

    //json로 출력
    const workbook = xlsx.utils.book_new();
    const data = xlsx.utils.json_to_sheet(defaultData);
    xlsx.utils.book_append_sheet(workbook, data, 'sheet1');
    xlsx.writeFile(
      workbook,
      `${moment(startDate).format('YYYY.MM.DD')}-${moment(endDate).format(
        'YYYY.MM.DD',
      )}매출데이터.xlsx`,
    );
  };

  const totalData = (e) => {
    let merchantNameList = e.map((item) => item.name);
    merchantNameList = Array.from(new Set(merchantNameList));
    for (let i = 0; i < merchantNameList.length; i++) {
      totalArr[i] = {
        name: merchantNameList[i],
        credit: 0,
        cash: 0,
        kakaopay: 0,
        webPayment: 0,
        virtualPrice: 0,
        credit_cancel: 0,
        cash_cancel: 0,
        kakaopay_cancel: 0,
        webPayment_cancel: 0,
        virtualPrice_cancel: 0,
        totalPrice: 0,
      };
      for (let j = 0; j < e.length; j++) {
        if (e[j].name === totalArr[i].name) {
          if (e[j].paymentType === 'credit' || e[j].paymentType === 'applepay') {
            totalArr[i].credit += e[j].amount;
          } else if (
            e[j].paymentType === 'credit-cancel' ||
            e[j].paymentType === 'applepay-cancel'
          ) {
            totalArr[i].credit_cancel += e[j].amount;
          } else if (e[j].paymentType === 'cash') {
            totalArr[i].cash += e[j].amount;
          } else if (e[j].paymentType === 'cash-cancel') {
            totalArr[i].cash_cancel += e[j].amount;
          } else if (e[j].paymentType === 'virtual') {
            totalArr[i].virtualPrice += e[j].amount;
          } else if (e[j].paymentType === 'virtual-cancel') {
            totalArr[i].virtualPrice_cancel += e[j].amount;
          } else if (e[j].paymentType === 'toss' || e[j].paymentType === 'hecto') {
            totalArr[i].webPayment += e[j].amount;
          } else if (e[j].paymentType === 'toss-cancel' || e[j].paymentType === 'hecto-cancel') {
            totalArr[i].webPayment_cancel += e[j].amount;
          } else if (
            e[j].paymentType === 'kakaopay' ||
            e[j].paymentType === 'naverpay' ||
            e[j].paymentType === 'payco'
          ) {
            totalArr[i].kakaopay += e[j].amount;
          } else if (
            e[j].paymentType === 'kakaopay-cancel' ||
            e[j].paymentType === 'naverpay-cancel' ||
            e[j].paymentType === 'payco-cancel'
          ) {
            totalArr[i].kakaopay_cancel += e[j].amount;
          }
        }
      }
    }

    let franchiseAllData = [
      totalArr,
      totalArr.map((item) => {
        item.creditPrice = item.credit - item.credit_cancel;
        item.cashPrice = item.cash - item.cash_cancel;
        item.kakaopayPrice = item.kakaopay - item.kakaopay_cancel;
        item.webPaymentPrice = item.webPayment - item.webPayment_cancel;
        item.virtualPricePrice = item.virtualPrice - item.virtualPrice_cancel;

        // item.credit1 = numberWithCommas(item.credit - item.credit_cancel);
        // item.cash1 = numberWithCommas(item.cash - item.cash_cancel);
        // item.kakaopay1 = numberWithCommas(item.kakaopay - item.kakaopay_cancel);
        // item.webPayment1 = numberWithCommas(
        //   item.webPayment - item.webPayment_cancel
        // );
        // item.virtualPrice1 = numberWithCommas(
        //   item.virtualPrice - item.virtualPrice_cancel
        // );

        item.credit1 = item.credit - item.credit_cancel;
        item.cash1 = item.cash - item.cash_cancel;
        item.kakaopay1 = item.kakaopay - item.kakaopay_cancel;
        item.webPayment1 = item.webPayment - item.webPayment_cancel;
        item.virtualPrice1 = item.virtualPrice - item.virtualPrice_cancel;
        item.totalAddPrice =
          item.credit + item.cash + item.kakaopay + item.webPayment + item.virtualPrice;

        item.totalSubPrice =
          item.credit_cancel +
          item.cash_cancel +
          item.kakaopay_cancel +
          item.webPayment_cancel +
          item.virtualPrice_cancel;
        // item.totalPrice = numberWithCommas(
        //   item.totalAddPrice - item.totalSubPrice
        // );
        item.totalPrice = item.totalAddPrice - item.totalSubPrice;
      }),
    ];
    setFranchiseDefaultData(franchiseAllData[0]);
    setFranchiseData(franchiseAllData[0]);
  };

  const searchData = (datalist) => {
    if (!merchantListSearchKeyword) {
      merchantList();
      setCurrentPage(1);
    } else {
      let searchData = merchantListSearchData.filter((item) =>
        item.name?.includes(merchantListSearchKeyword),
      );

      setMerchantlistData(searchData);
    }
  };

  const searchDataPayList = () => {
    if (!merchantSearchKeyword) {
      setFranchiseData(franchiseDefaultData);
      setCurrentPage(1);
    } else {
      let searchData = franchiseData.filter((item) => item.name?.includes(merchantSearchKeyword));

      setFranchiseData(searchData);
    }
  };

  const handleSearchKeyword = (keyword) => {
    if (keyword === '') {
      setMerchantListSearchKeyword(null);
    } else {
      setMerchantListSearchKeyword(keyword);
    }
  };

  const setImage = (e, index, imageType) => {
    const reader = new FileReader();
    if (imageType === 'banner') {
      let image_tag = document.querySelectorAll('.bannerImageData');
      if (bannerImage[index] && typeof bannerImage[index] !== 'string') {
        image_tag[index].removeChild(image_tag[index].childNodes[1]);
      }
      reader.onload = function (event) {
        var img = document.createElement('img');
        img.setAttribute('src', event.target.result);
        img.setAttribute('style', 'width:50%');
        image_tag[index].appendChild(img);
      };
      reader.readAsDataURL(e.target.files[0]);
      setBannerImage(
        [
          ...bannerImage,
          bannerImage.map((item, idx) => (idx === index ? e.target.files[0] : item)),
        ][5],
      );
    } else {
      let image_tag = document.querySelectorAll('.adImageData');
      if (adImage[index] && typeof adImage[index] !== 'string') {
        image_tag[index].removeChild(image_tag[index].childNodes[1]);
      }
      reader.onload = function (event) {
        var img = document.createElement('img');
        img.setAttribute('src', event.target.result);
        img.setAttribute('style', 'width:40%');
        image_tag[index].appendChild(img);
      };
      reader.readAsDataURL(e.target.files[0]);
      setAdImage(
        [...adImage, adImage.map((item, idx) => (idx === index ? e.target.files[0] : item))][5],
      );
    }
  };

  const saveAdImg = async (e) => {
    Swal.fire({
      showCancelButton: true,
      confirmButtonColor: '#04C9DB',
      cancelButtonColor: '#F53C14',
      confirmButtonText: '등록',
      cancelButtonText: '취소',
      title: '광고이미지 등록',
      html: `광고이미지를 등록하시겠습니까?`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const formData = new FormData();

        formData.append('adImg1', adImage[0]);
        formData.append('adImg2', adImage[1]);
        formData.append('adImg3', adImage[2]);
        formData.append('adImg4', adImage[3]);
        formData.append('adImg5', adImage[4]);
        formData.append('merchantId', 'null');
        let result = await postAdImgsAdmin(formData);
        if (result.status === 200) {
          Swal.fire({
            icon: 'success',
            title: '광고이미지 등록이 완료되었습니다.',
            timer: 2000,
          });
        } else {
          Swal.fire({
            icon: 'error',
            timer: 3000,
            title: '이미지 등록에 실패했습니다. 다시 시도해주세요.',
          });
        }
      }
    });
  };

  const saveBanner = async (e) => {
    Swal.fire({
      showCancelButton: true,
      confirmButtonColor: '#04C9DB',
      cancelButtonColor: '#F53C14',
      confirmButtonText: '등록',
      cancelButtonText: '취소',
      title: '배너이미지 등록',
      html: `배너이미지를 등록하시겠습니까?`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const formData = new FormData();

        formData.append('bannerImg1', bannerImage[0]);
        formData.append('bannerImg2', bannerImage[1]);
        formData.append('bannerImg3', bannerImage[2]);
        formData.append('bannerImg4', bannerImage[3]);
        formData.append('bannerImg5', bannerImage[4]);
        formData.append('merchantId', 'null');
        let result = await postBannerImgAdmin(formData);
        if (result.status === 200) {
          Swal.fire({
            icon: 'success',
            title: '배너이미지 등록이 완료되었습니다.',
            timer: 2000,
          });
        } else {
          Swal.fire({
            icon: 'error',
            timer: 3000,
            title: '배너이미지 등록에 실패했습니다. 다시 시도해주세요.',
          });
        }
      }
    });
  };

  const getAdminImage = async () => {
    let imageData = await getImagesAdmin();
    if (imageData.status === 200) {
      let BannerChangeValues = Object.values(imageData.data.bannersAdmin);
      let AdChangeValues = Object.values(imageData.data.adsAdmin);
      setAdImage(AdChangeValues.splice(2, 7));
      setBannerImage(BannerChangeValues.splice(2, 7));
    }
  };

  const deleteData = async (image) => {
    Swal.fire({
      showCancelButton: true,
      confirmButtonColor: '#F53C14',
      cancelButtonColor: '#04C9DB',
      confirmButtonText: '삭제',
      cancelButtonText: '취소',
      title: '이미지삭제',
      html: `이미지를 삭제하시겠습니까?`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const resultData = await deleteImage(null, image);
        if (resultData.status === 200) {
          Swal.fire({
            icon: 'success',
            timer: 4000,
            title: '이미지를 삭제하였습니다.',
          }).then(() => window.location.reload());
        } else {
          Swal.fire({
            icon: 'error',
            timer: 4000,
            title: '삭제중 오류가 발생했습니다. 다시 시도해주세요',
          });
        }
      }
    });
  };
  const handleSearchingCho = (searching) => {
    setCurrentPage(1);
    setSearchingCho(searching);
    let copyData = [...merchantListSearchData];
    let searchData = copyData.filter((item) =>
      searching?.some((cho) => Hangul.disassemble(item.name, true)[0][0]?.includes(cho)),
    );
    setMerchantlistData(searchData);
  };

  const changePostsPerPage = (num) => {
    setPostsPerPage(+num);
    setCurrentPage(1);
  };

  return (
    <Container>
      {level === 'admin' && (
        <>
          <a className="logo" href="#javascript">
            <img className="img-fluid for-light" src={Logo} alt="" />
          </a>
          <button className="btn btn-primary" onClick={handleLogout}>
            로그아웃
          </button>

          <ul>
            <NavList tab={tab === 0} onClick={() => setTab(0)}>
              지점로그인
              <br className="show-480" />
              (점주)
            </NavList>
            <NavList tab={tab === 1} onClick={() => setTab(1)}>
              비밀번호 <br className="show-480" />
              변경
            </NavList>
            <NavList tab={tab === 2} onClick={() => setTab(2)}>
              지점생성
            </NavList>
            <NavList tab={tab === 3} onClick={() => setTab(3)}>
              전지점 <br className="show-480" />
              매출현황
            </NavList>
            <NavList tab={tab === 10} onClick={() => setTab(10)}>
              프랜차이즈
            </NavList>
            <NavList tab={tab === 4} onClick={() => setTab(4)}>
              지점별
              <br className="show-480" /> 메시지 충전
            </NavList>
            <NavList tab={tab === 6} onClick={() => setTab(6)}>
              키오스크 배너관리
            </NavList>
            <NavList tab={tab === 7} onClick={() => setTab(7)}>
              공지사항
            </NavList>
            <NavList tab={tab === 9} onClick={() => setTab(9)}>
              쿠폰발급
            </NavList>
            <NavList tab={tab === 8} onClick={() => setTab(8)}>
              메시지 충전내역
            </NavList>
            <NavList tab={tab === 11} onClick={() => setTab(11)}>
              키오스크 업데이트
            </NavList>
          </ul>
          {tab === 0 && (
            <MerchantListS>
              <div>
                <h4>가맹점 관리페이지</h4>
                <p>로그인 할 지점을 선택해주세요.</p>
              </div>
              <Form
                style={{
                  width: window.innerWidth > 480 ? '50%' : '100%',
                  marginBottom: '1vw',
                }}
                onSubmit={(e) => {
                  e.preventDefault();
                  searchData();
                }}>
                <SearchContainer>
                  <Input
                    className="form-control"
                    type="text"
                    placeholder="검색어를 입력해주세요"
                    value={merchantListSearchKeyword}
                    onChange={(e) => handleSearchKeyword(e.target.value)}
                  />

                  <i onClick={searchData} className="fa fa-search" />
                </SearchContainer>
              </Form>
              <TabContainer>
                <ChoTabBtn
                  selected={searchingCho === allCho}
                  onClick={() => handleSearchingCho(allCho)}>
                  전체
                </ChoTabBtn>
                <ChoTabBtn
                  selected={searchingCho?.join('') === 'ㄱㄴ'}
                  onClick={() => handleSearchingCho(['ㄱ', 'ㄴ'])}>
                  ㄱ~ㄴ
                </ChoTabBtn>
                <ChoTabBtn
                  selected={searchingCho?.join('') === 'ㄷㄹ'}
                  onClick={() => handleSearchingCho(['ㄷ', 'ㄹ'])}>
                  ㄷ~ㄹ
                </ChoTabBtn>
                <ChoTabBtn
                  selected={searchingCho?.join('') === 'ㅁㅂ'}
                  onClick={() => handleSearchingCho(['ㅁ', 'ㅂ'])}>
                  ㅁ~ㅂ
                </ChoTabBtn>
                <ChoTabBtn
                  selected={searchingCho?.join('') === 'ㅅㅇ'}
                  onClick={() => handleSearchingCho(['ㅅ', 'ㅇ'])}>
                  ㅅ~ㅇ
                </ChoTabBtn>
                <ChoTabBtn
                  selected={searchingCho?.join('') === 'ㅈㅊ'}
                  onClick={() => handleSearchingCho(['ㅈ', 'ㅊ'])}>
                  ㅈ~ㅊ
                </ChoTabBtn>
                <ChoTabBtn
                  selected={searchingCho?.join('') === 'ㅋㅌ'}
                  onClick={() => handleSearchingCho(['ㅋ', 'ㅌ'])}>
                  ㅋ~ㅌ
                </ChoTabBtn>
                <ChoTabBtn
                  selected={searchingCho?.join('') === 'ㅍㅎ'}
                  onClick={() => handleSearchingCho(['ㅍ', 'ㅎ'])}>
                  ㅍ~ㅎ
                </ChoTabBtn>
                <ChoTabBtn
                  selected={searchingCho === etcCho}
                  onClick={() => handleSearchingCho(etcCho)}>
                  기타
                </ChoTabBtn>
              </TabContainer>
              <List>
                {merchantListData &&
                  currentPosts(
                    merchantListData?.map((list) => (
                      <li key={list?.id + 'cc'} onClick={() => handleSelectMerchant(list.id)}>
                        {list.id}. {list?.name} <small>({list.merchant_code})</small> &rarr;
                      </li>
                    )),
                  )}
                {merchantListData.length === 0 && <p>검색된 지점이 없습니다.</p>}
              </List>
              <PaginationComponent
                setCurrentPage={setCurrentPage}
                totalLength={merchantListData.length}
                postsPerPage={postsPerPage}
                currentPage={currentPage}
              />
            </MerchantListS>
          )}
          {tab === 1 && (
            <MerchantListS>
              <div>
                <h4>비밀번호 변경</h4>
                <p>(영문,숫자,특수문자(!@$%^&* 만 허용)를 조합하여 6자 이상)</p>
              </div>
              <div className="pw_input">
                <p>비밀번호</p>
                <Input
                  type="password"
                  name="password1"
                  placeholder="비밀번호 규칙에 맞게 입력해주세요"
                  onChange={handlePassword}
                  value={pw.password1}
                />
              </div>
              <div className="pw_input">
                <p>비밀번호 확인</p>
                <Input
                  type="password"
                  name="password2"
                  placeholder="비밀번호 규칙에 맞게 입력해주세요"
                  onChange={handlePassword}
                  value={pw.password2}
                />
              </div>
              <button className="btn btn-primary" onClick={passwordOnSubmit1}>
                비밀번호 변경
              </button>
            </MerchantListS>
          )}
          {tab === 2 && (
            <MerchantListS>
              <h4>지점 생성</h4>

              <FormGroupS>
                <Label>지점코드</Label>
                <Input
                  type="text"
                  name="code"
                  value={form.code}
                  onChange={handleOnChange}
                  placeholder="숫자6자리"
                />
              </FormGroupS>
              <FormGroupS>
                <Label>비밀번호(백오피스)</Label>
                <Input
                  type="password"
                  name="password"
                  value={form.password}
                  onChange={handleOnChange}
                  placeholder="6자리"
                />
              </FormGroupS>
              <FormGroupS>
                <Label>지점이름</Label>
                <Input type="text" name="name" value={form.name} onChange={handleOnChange} />
              </FormGroupS>
              <FormGroupS>
                <Label>전화번호(문자)</Label>
                <Input type="text" name="contact" value={form.contact} onChange={handleOnChange} />
              </FormGroupS>

              <Button color="primary" onClick={() => createMerchant()}>
                지점생성
              </Button>
            </MerchantListS>
          )}
          {tab === 3 && (
            <MerchantListS>
              <div className="topbar">
                <h4>전지점 매출 현황</h4>
                <ExcelButton onClick={() => exportExcel()} />

                <div className="selectContainer">
                  <DatePickerStyle style={{ margin: 0 }}>
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => {
                        setStartDate(date);
                      }}
                      selectsStart
                      locale={ko}
                      startDate={startDate}
                      maxDate={endDate}
                      endDate={endDate}
                      customInput={<ExampleCustomInput />}
                    />
                    <h5> ~ </h5>
                    <DatePicker
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      selectsEnd
                      locale={ko}
                      startDate={startDate}
                      endDate={endDate}
                      minDate={startDate}
                      customInput={<ExampleCustomInput />}
                    />

                    <SelectDate
                      setStartDate={(val) => setStartDate(val)}
                      setEndDate={(val) => setEndDate(val)}
                      nowTime={'저번 달'}
                    />
                  </DatePickerStyle>

                  <div className="postsPerPage">
                    <LabelRow className="m-0">
                      <Input
                        style={{ margin: 0 }}
                        className="form-control"
                        type="text"
                        placeholder="검색 할 지점을 입력해주세요"
                        value={merchantSearchKeyword ? merchantSearchKeyword : ''}
                        onChange={(e) => setMerchantSearchKeyword(e.target.value)}
                      />

                      <i onClick={searchDataPayList} className="fa fa-search" />
                    </LabelRow>
                    <select onChange={(e) => changePostsPerPage(e.target.value)}>
                      <option value={10}>10개씩 보기</option>
                      <option value={50}>50개씩 보기</option>
                      <option value={100}>100개씩 보기</option>
                      <option value={300}>300개씩 보기</option>
                    </select>
                  </div>
                </div>
              </div>
              {ready ? (
                <Loading />
              ) : franchiseData.length !== 0 ? (
                <>
                  <TableContainer>
                    <FranchiseList
                      tableData={franchiseData}
                      currentPosts={currentPosts}
                      setCurrentPage={setCurrentPage}
                      currentPage={currentPage}
                    />
                  </TableContainer>

                  <PaginationComponent
                    setCurrentPage={setCurrentPage}
                    totalLength={franchiseData.length}
                    postsPerPage={postsPerPage}
                    currentPage={currentPage}
                  />
                </>
              ) : (
                <>
                  <p>검색한 지점이 존재하지 않습니다.</p>
                  <p>기간과 검색어를 확인하신 후 다시 입력해주세요</p>
                </>
              )}
              {/* <SalesTable id="franchiseTable">
                <thead>
                  <tr>
                    <td>id</td>
                    <td>지점명</td>
                    <td>카드매출</td>
                    <td>현금매출</td>
                    <td>간편결제</td>
                    <td>웹결제</td>
                    <td>가매입/가매출</td>
                    <td>총매출</td>
                  </tr>
                </thead>
                <tbody>
                  {franchiseData.length > 0
                    ? franchiseData.map((item) => (
                        <tr>
                          <td>{numberWithCommas(item.name)}</td>
                          <td>{numberWithCommas(item.credit)}</td>
                          <td>{numberWithCommas(item.cash)}</td>
                          <td>{numberWithCommas(item.kakaopay)}</td>
                          <td>{numberWithCommas(item.webPayment)}</td>
                          <td>{numberWithCommas(item.virtualPrice)}</td>
                          <td>{numberWithCommas(item.totalPrice)}</td>
                        </tr>
                      ))
                    : "정보가 없습니다."}
                </tbody>
              </SalesTable> */}
            </MerchantListS>
          )}
          {tab === 4 && (
            <MerchantListS>
              <div className="topbar">
                <h4>지점별 메시지 충전</h4>
              </div>
              <TableContainer>
                <SalesTable id="franchiseTable">
                  <thead>
                    <tr>
                      <td>id</td>
                      <td>지점명</td>
                      <td>현재 SMS 전송한도(1년치)</td>
                      <td>충전할 SMS 전송한도</td>
                      <td>충전 후 SMS 전송한도</td>
                      <td>저장</td>
                    </tr>
                  </thead>
                  <tbody>
                    {merchantListData.length > 0
                      ? merchantListData.map((item, idx) => (
                          <tr key={item.merchant_code}>
                            <td>{item.id}</td>
                            <td>{item.name}</td>
                            <td>{item.maxSendMessage}</td>
                            <td
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                              }}>
                              <Input
                                style={{
                                  width: window.innerWidth > 480 ? '80px' : '100px',
                                  display: 'block',
                                }}
                                type="text"
                                name="addSMS"
                                id={item.id}
                                value={item.addSMS}
                                onChange={handleSMSChange}
                              />
                            </td>
                            <td>{item.maxSendMessage + item.addSMS}</td>
                            <td>
                              <button onClick={() => addSMSData(idx)}>저장</button>
                            </td>
                          </tr>
                        ))
                      : '정보가 없습니다.'}
                  </tbody>
                </SalesTable>
              </TableContainer>
            </MerchantListS>
          )}
          {tab === 6 && (
            <MerchantListS>
              <div>
                <h4>키오스크 배너 관리</h4>
              </div>
              <h5>배너</h5>
              {image_count.map((item, index) => (
                <div className="imageComponent bannerImageData">
                  <div className="fileContainer">
                    <Input
                      key={item + 'ban'}
                      type="file"
                      accept="image/*"
                      name={item}
                      className="form-control m-input"
                      onChange={(e) => setImage(e, index, 'banner')}
                    />
                    <button
                      className="btn btn-secondary"
                      onClick={() => deleteData('bannerImg' + (index + 1))}>
                      삭제하기
                    </button>
                  </div>
                  {typeof bannerImage[index] === 'string' && (
                    <img style={{ width: '60%' }} alt=" " src={bannerImage[index] || ''} />
                  )}
                </div>
              ))}
              <button className="btn btn-primary" onClick={() => saveBanner()}>
                배너이미지 등록
              </button>
              <h5>광고</h5>
              {image_count.map((item, index) => (
                <div className="imageComponent adImageData">
                  <div className="fileContainer">
                    <Input
                      key={item + 'ad'}
                      type="file"
                      accept="image/*"
                      name={item}
                      className="form-control m-input"
                      onChange={(e) => setImage(e, index, 'adImage')}
                    />
                    <button
                      className="btn btn-secondary"
                      onClick={() => deleteData('adImg' + (index + 1))}>
                      삭제하기
                    </button>
                  </div>
                  {typeof adImage[index] === 'string' && (
                    <img style={{ width: '40%' }} alt=" " src={adImage[index] || ''} />
                  )}
                </div>
              ))}
              <button className="btn btn-primary" onClick={() => saveAdImg()}>
                광고이미지 등록
              </button>
            </MerchantListS>
          )}
          {tab === 7 && (
            <MerchantListS>
              <div>
                <h4>공지사항</h4>
                <NoticeBoard />
              </div>
            </MerchantListS>
          )}
          {tab === 9 && (
            <MerchantListS>
              <div>
                <h4>쿠폰 발급</h4>
                <AdminCoupon />
              </div>
            </MerchantListS>
          )}
          {tab === 8 && (
            <MerchantListS>
              <h4>메시지 충전내역</h4>
              <MessageChargeInfo />
            </MerchantListS>
          )}

          {tab === 10 && (
            <MerchantListS>
              <div style={{ width: '96%' }}>
                <h4>프랜차이즈</h4>
                <Franchise />
              </div>
            </MerchantListS>
          )}
          {tab === 11 && (
            <MerchantListS>
              <h4>키오스크 업데이트</h4>
              <Button onClick={() => merchantUpdateKiosk()}>전지점 키오스크 업데이트</Button>
            </MerchantListS>
          )}
          {/* {tab === 5 && <HealthCheck MerchantListS={MerchantListS} />} */}
        </>
      )}
    </Container>
  );
};

export default MerchantList;
