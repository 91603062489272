import React, { useEffect, useState } from 'react';
import { Input, Modal, ModalHeader, ModalBody } from 'reactstrap';
import Swal from 'sweetalert2';
import { merchantId_api, postTicketCreate } from '../../lib/api';
import { Button, Label1 } from '../../pages/lockerChange';
import { ToggleBtn } from '../../pages/seatList';
import { InputS, InputWithP } from '../../pages/ticketList';
import { ModalBackground } from './choiceModal';
import configDB from '../../data/customizer/config';
import { LabelRow } from '../../pages/ticketCustom';

const primary = localStorage.getItem('default_color') || configDB.data.color.primary_color;

let merchantId = '';

const LockersInsertModal = ({ isOpen, onClose, setData, setReload }) => {
  const [info, setInfo] = useState({
    T_type: 5,
    T_name: '',
    T_description: '',
    T_price: 0,
    T_discount: 0,
    T_expireDate: 0,
    T_level: 1,
    T_time: 0,
    T_day: 0,
    T_count: -1,
    seatType: 0,
  });
  const [count, setCount] = useState(false);

  useEffect(() => {
    getMerchantId();
  }, []);

  const getMerchantId = async () => {
    const { data } = await merchantId_api();
    if (data) {
      merchantId = data.id;
    }
  };
  const handleOnchange = ({ target }) => {
    setInfo({ ...info, [target.name]: target.value });
  };

  const handleTicketTime = ({ target }) => {
    setInfo({ ...info, T_day: target.value, T_expireDate: target.value });
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();

    if (info.T_expireDate <= 0) {
      Swal.fire({
        icon: 'error',
        title: '사용기간은 최소 1일 이상 설정합니다.',
        timer: 2000,
      });
    } else if (info.T_name === '' || info.T_price <= 0) {
      Swal.fire({
        icon: 'error',
        title: '필수항목을 전부 기입해주세요.',
        timer: 2000,
      });
    } else {
      try {
        const res = await postTicketCreate({
          ...info,
          merchantId,
        });
        if (res.status === 200) {
          setReload();
          Swal.fire('사물함이 등록됐습니다.').then(
            setInfo({
              T_type: 5,
              T_name: '',
              T_description: '',
              T_price: 100,
              T_discount: 0,
              T_expireDate: 0,
              T_level: 1,
              T_time: 0,
              T_day: 0,
              T_count: -1,
              seatType: 0, // 자유석 or 고정석
            }),
            onClose(),
          );
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <>
      <Modal centered isOpen={isOpen} toggle={onClose}>
        <ModalHeader className="modal-title" toggle={onClose}>
          사물함 추가
        </ModalHeader>
        <ModalBody>
          <Label1>
            <h5>사물함 이름</h5>
            <InputS
              type="text"
              name="T_name"
              onChange={handleOnchange}
              value={info?.T_name || ''}
            />
          </Label1>
          <Label1>
            <h5>사물함 설명</h5>
            <Input
              type="text"
              placeholder=""
              name="T_description"
              onChange={handleOnchange}
              value={info?.T_description || ''}
            />
          </Label1>
          <Label1>
            <h5>사물함 가격</h5>
            <InputS
              type="number"
              min="100"
              name="T_price"
              onChange={handleOnchange}
              value={info?.T_price || ''}
            />
          </Label1>
          <Label1>
            <h5>사물함 할인률</h5>
            <InputWithP>
              <Input
                type="number"
                min="0"
                name="T_discount"
                onChange={handleOnchange}
                value={info?.T_discount || ''}
              />
              <p>%</p>
            </InputWithP>
          </Label1>
          {/* <FormGroup>
                    <h5>사물함 유효기간</h5>
                    <InputWithP>
                      <InputS
                        type="number"
                        min="0"
                        name="T_expireDate"
                        onChange={handleOnchange}
                        value={info?.T_expireDate || ""}
                      />
                      <p>일</p>
                    </InputWithP>
                  </FormGroup> */}
          <Label1>
            <h5>사물함 사용기간</h5>
            <InputWithP>
              <InputS
                type="number"
                min="0"
                name="T_day"
                onChange={handleTicketTime}
                value={info?.T_day || ''}
              />
              <p>일</p>
            </InputWithP>
          </Label1>
          <LabelRow>
            <h5>이용권 수 제한</h5>
            <ToggleBtn>
              <InputS
                id="count"
                type="checkbox"
                onChange={() => setCount(!count)}
                checked={count}
              />

              <span className="onoff-switch"></span>
            </ToggleBtn>
            {count && (
              <InputWithP>
                <InputS
                  type="number"
                  min="0"
                  name="T_count"
                  onChange={handleOnchange}
                  value={info?.T_count || ''}
                />
                <p>개</p>
              </InputWithP>
            )}
          </LabelRow>

          <div
            style={{
              display: 'flex',
              width: '100%',
              gap: '10px',
            }}
          >
            <Button color="#fff" onClick={onClose}>
              취소
            </Button>
            <Button onClick={handleOnSubmit} color={primary}>
              등록
            </Button>
          </div>
        </ModalBody>
      </Modal>
      <ModalBackground />
    </>
  );
};

export default LockersInsertModal;
