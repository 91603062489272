import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Form, Input, Button } from 'reactstrap';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import { getTerms, getUserMerchantList, msgAuth, msgSend, signUp } from '../lib/api';
import drop from '../assets/images/dropdownRight.svg';
import { Checkbox } from '../pages/messageBulk';
import { Label1 } from '../pages/lockerChange';
import configDB from '../data/customizer/config';
import { checkPwFunc } from '../pages/merchantList';
import TermsModal from '../components/modal/termsModal';
import AuthHeader from './headerComponent';

const primary = localStorage.getItem('default_color') || configDB.data.color.primary_color;

const TermContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  .color {
    color: ${primary};
    width: 100%;
    font-size: 1.2vw;
    margin-bottom: 0;
  }

  .loginBtn {
    width: 70% !important;
  }

  @media screen and (max-width: 1080px) {
    .color {
      font-size: 3.2vw;
      margin-bottom: 2vw;
    }
  }
  @media screen and (max-width: 480px) {
    .color {
      font-size: 5vw;
      padding: 0 5vw;
      margin-bottom: 2vw;
    }
  }
`;

const Title = styled.div`
  line-height: 1.2;
  width: 100%;
  font-weight: 500;
  color: #2b3644;
  line-height: 140%;
  p,
  strong {
    font-size: 2vw;
    line-height: 1.4;
    margin-bottom: 0;
  }

  @media screen and (max-width: 1080px) {
    p {
      font-size: 4vw;
    }
  }

  @media screen and (max-width: 480px) {
    font-weight: 600;
    p {
      font-size: 7vw;
      padding: 0 5vw;
      color: #474b50;
    }
  }
`;

const CheckeBoxList = styled.div`
  padding: 0.8vw 1vw;
  border-bottom: 1px solid #e5e5e5;
  display: flex;
  > label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    > div {
      display: flex;
      align-items: center;
      > p {
        margin-left: 1vw;
        margin-bottom: 0;
        color: #676971;
        font-weight: 500;
      }
    }
  }
  @media screen and (max-width: 480px) {
    padding: 3.8vw 5vw;
    > label {
      > div {
        > p {
          margin-left: 6vw;
          font-size: 3.5vw;
        }
      }
    }
  }
`;
const CheckBoxes = styled.div`
  width: 100%;
  margin-bottom: 3vw;
  ${Checkbox} {
    label {
      p {
        color: #586680;
        font-weight: 400;
      }
    }
    button {
      border: none;
      background-color: transparent;
      img {
        width: 0.4vw;
      }
    }
    @media screen and (max-width: 1080px) {
      button {
        img {
          width: 1.2vw;
        }
      }
    }

    @media screen and (max-width: 480px) {
      button {
        img {
          width: 2vw;
        }
      }
    }
  }
  @media screen and (max-width: 480px) {
    margin-bottom: 15vw;
  }
`;

const SignUpForm = styled.div`
  .signUp-form {
    display: flex;
    width: 100%;
    justify-content: space-between;
    .col-6 {
      max-width: 100%;
      padding: 0;
      display: block;
      flex: none;
    }
  }

  .authDataInput {
    display: none;
  }
  @media screen and (max-width: 480px) {
    width: 100%;
  }
`;

const InputWithBtn = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  > input {
    margin-top: 0;
  }
`;
const InputRadio = styled.div`
  display: flex;
  align-items: center;
`;

const OTPBtn = styled.button.attrs({ type: 'button' })`
  width: 115px;
  font-size: 12px;
  height: 33px;
  padding: 2px 5px;
  background-color: ${primary};
  color: #fff;
  border: none;
  border-radius: 4px;
  margin-left: 5px;
  :disabled {
    background-color: #dedede;
  }
`;
const Label1S = styled(Label1)`
  display: ${(props) => (props.show ? 'flex' : 'none')};
`;
const Label = styled.label`
  display: flex;
  align-items: center;
  margin-right: 5px;
  > input {
    margin: 0;
    margin-right: 5px;
  }
  font-size: 12px;
  color: #555;
`;
const OTPCounterS = styled.h6`
  position: absolute;
  right: 125px;
  color: #2d55e2;
  font-size: 12px;
  font-weight: 600;
`;

function secondsToDhms(num) {
  const m = Math.floor((num % 3600) / 60);
  const s = Math.floor(num % 60);

  const mDisplay = m > 0 ? `${m}분` : '';
  const sDisplay = s > 0 ? ` ${s}초` : '';
  return mDisplay + sDisplay;
}

let submitFlag = false;

function submitCheck() {
  if (submitFlag) {
    return submitFlag;
  }
  submitFlag = true;
}

const SearchLists = styled.ul`
  width: 75.5%;
  padding: 15px 20px;
  border-radius: 4px;
  border: 1px solid #eee;
  background-color: #fff;
  box-shadow: 0px 2px 8px rgba(34, 34, 34, 0.15);
  @media screen and (max-width: 480px) {
    width: 100%;
    height: 12vh;
    overflow-y: scroll;
  }
`;
const SearchList = styled.li`
  color: ${(props) => (props.selected ? primary : '#555')};
  :not(:last-child) {
    margin-bottom: 7px;
  }
`;

export const CheckBoxRound = styled(Checkbox)`
  margin: 0;
  .checkbox_icon {
    border-radius: 100%;
    padding: 10px;
  }
  input:checked + .blackColor {
    border-color: #00171a;
    background-color: #00171a;
  }

  input:checked + .checkbox_icon::after {
    height: 9px;
  }

  .checkbox_icon::after {
    top: 13.6px;
    left: 10px;
  }
  input:checked + .checkbox_icon::before {
    height: 5.5px;
  }

  .checkbox_icon::before {
    top: 11px;
    left: 5px;
    transform: rotate(-56deg);
  }
  .marginP {
    margin-left: 10px;
  }
`;

const RouteLoginBtn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1.5vw;
  p {
    margin: 0;
  }
`;

//pc버전 회원가입 폼

const SignUp = () => {
  const [signUpData, setSignUpData] = useState({
    phoneNum: '010',
    certNumber: '',
    merchantId: '',
    gender: null,
    parentPhoneNum: '',
    password: '',
    name: '',
    email: '',
    job: '',
    smsDeny: true,
    alertKaKaoToggle: true,
  });
  const {
    phoneNum,
    certNumber,
    merchantId,
    gender,
    parentPhoneNum,
    password,
    name,
    email,
    job,
    smsDeny,
    alertKaKaoToggle,
  } = signUpData;
  const [isSmsDeny, setIsSmsDeny] = useState(true);
  const [merchantListData, setMerchantlistData] = useState([]);
  const [isThermOk, setIsThermOk] = useState(false);
  const history = useHistory();
  const [counter, setCounter] = useState(100);
  const [isCounterStart, setIsCounterStart] = useState(false);
  const [pinNum, setPinNum] = useState({ pinNum1: '', pinNum2: '' });
  const { pinNum1, pinNum2 } = pinNum;
  const [isPinOk, setIsPinOk] = useState('true');
  const [index, setIndex] = useState(-1);

  const [checkItems, setCheckItems] = useState([1, 2, 3]);
  const [isShow1, setIsShow1] = useState(false);
  const [isShow2, setIsShow2] = useState(false);
  const [isShow3, setIsShow3] = useState(false);
  const [isShow4, setIsShow4] = useState(false);
  const [authNumOk, setAuthNumOk] = useState(false);
  const [certInput, setCertInput] = useState(false);
  const [userMerchantData, setUserMerchantData] = useState({});
  const [searchMerchant, setSearchMerchant] = useState('');
  const [merchantList, setMerchantList] = useState([]);
  const [selectedMerchant, setSelectedMerchant] = useState(null);
  const [termData, setTermData] = useState([]);
  const [isModal, setIsModal] = useState(false);
  const [content, setContent] = useState('');
  const [indexData, setIndexData] = useState(0);
  const [titleArray, setTitleArray] = useState([
    '[필수] 렛미업 스카 클라우드 서비스 이용약관 및 운영 매장 이용수칙',
    '[필수] 운영매장 이용수칙',
    '[필수] 개인정보 수집 및 이용 동의',
    '[선택] 마케팅 수신동의',
  ]);
  const [title, setTitle] = useState('');
  var isMobile = /Mobi/i.test(window.navigator.userAgent);
  // const merchantListFunc = async () => {
  //   const data = await getMerchantList();
  //   if (data.status === 200) {
  //     setMerchantlistData(data.data);
  //   } else {
  //     Swal.fire("정보를 가지고 오던 중 오류가 생겼습니다. 다시 시도해주세요.");
  //   }
  // };

  // useEffect(() => {
  //   merchantListFunc();
  // }, []);

  const getTermData = async () => {
    const data = await getTerms();
    if (data.status === 200) {
      setTermData(data.data);
    }
  };
  const sendMsg = async () => {
    submitCheck();
    try {
      if (signUpData.phoneNum.length !== 11) {
        Swal.fire({
          icon: 'error',
          title: '전화번호를 정확히 입력해주세요',
          timer: 3000,
        });
      } else {
        const res = await msgSend(phoneNum);
        if (res.status === 200 && res.data !== 0) {
          // if (res.data !== 1)
          // setUserMerchantData(
          //   merchantListData.filter((item) => item.id === res.data)[0]
          // );
          // setSignUpData({
          //   ...signUpData,
          //   merchantId: merchantListData.filter(
          //     (item) => item.id === res.data
          //   )[0].id,
          // });

          setCertInput(true);
          setIsCounterStart(true);
        } else {
          Swal.fire({
            icon: 'error',
            title: '이미 가입된 회원입니다.\n 로그인을 진행해주세요.',
            timer: 3000,
          });
        }
      }
    } catch (err) {
      Swal.fire({
        icon: 'error',
        title: '에러가 발생했습니다.\n 다시 시도해주세요.',
        timer: 3000,
      });
    }
    submitFlag = false;
  };
  const authMsg = async () => {
    submitCheck();
    try {
      const res = await msgAuth(phoneNum, certNumber);
      if (res.status === 200) {
        Swal.fire({
          icon: 'success',
          title: '인증이 완료되었습니다.',
          timer: 2000,
        });
        setAuthNumOk(true);
        setCertInput(false);
        setIsCounterStart(false);
      } else if (res.status === 401) {
        Swal.fire({
          title: '인증번호가 일치하지 않습니다.',
          icon: 'error',
          timer: 2000,
        });
      } else if (res.status === 201) {
        Swal.fire({
          icon: 'success',
          title: '인증이 완료되었습니다.',
          timer: 2000,
        });
        //res.data.name => 지점 이름
        // res.data.merchantId => 지점번호
        setUserMerchantData({ id: res.data.merchantId, name: res.data.name });
        setAuthNumOk(true);
        setCertInput(false);
        setIsCounterStart(false);
      }
    } catch (err) {}
  };
  const handleChange = (e) => {
    if (e.target.name === 'gender') {
      setSignUpData({ ...signUpData, [e.target.name]: +e.target.value });
    } else {
      setSignUpData({ ...signUpData, [e.target.name]: e.target.value });
    }
  };

  const checkPinNum = ({ target }) => {
    setPinNum({
      ...pinNum,
      [target.name]: target.value,
    });
  };

  useEffect(() => {
    if (pinNum1.length >= 6 && pinNum2.length >= 6) {
      if (pinNum1 === pinNum2) {
        setIsPinOk('true');
        setSignUpData({ ...signUpData, password: pinNum1 });
      } else {
        setIsPinOk('false');
      }
    } else {
      setIsPinOk('');
    }
  }, [pinNum]);

  const onSubmit = async (e) => {
    if (window.event.keyCode === 13) {
      e.preventDefault();
    } else {
      e.preventDefault();

      try {
        if (checkPwFunc(password)) {
          const data = await signUp({
            ...signUpData,
            merchantId: userMerchantData.id ? userMerchantData.id : selectedMerchant?.id,
          });
          if (data.status === 200) {
            Swal.fire({
              icon: 'success',
              title: '회원가입에 성공했습니다.',
              timer: 3000,
            }).then(() => {
              history.push('/auth/login');
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: '회원가입 중 오류가 발생했습니다.\n 다시 시도해 주세요',
              timer: 3000,
            });
          }
        }
      } catch (err) {}
    }
  };

  const noneEnter = (e) => {
    if (window.event.keyCode === 13) {
      e.preventDefault();
    }
  };

  //지점검색
  const handleSearchKeyword = ({ target }) => {
    const regexp = /[a-z0-9]|[ \[\]{}()<>?|`~!@#$%^&*-_+=,.;:\"'\\]/g;
    setSearchMerchant(target.value.replace(regexp, ''));
  };

  const keyword = ['스터디카페', '스터디', '카페', '스터'];
  const handleSearchData = async (e) => {
    e.preventDefault();
    if (searchMerchant.length < 2) {
      return Swal.fire({
        icon: 'error',
        title: '2글자 이상 검색해주세요.',
        timer: 3500,
      });
    }
    if (keyword.some((word) => searchMerchant.includes(word))) {
      return;
    }
    const { data } = await getUserMerchantList(null, searchMerchant);

    if (data?.length > 0) {
      setMerchantList(data);
    }
  };
  useEffect(() => {
    const goHome =
      isCounterStart &&
      setTimeout(() => {
        setCounter(counter - 1);
      }, 1000);
    if (counter === 0) {
      setCounter(100);
      setIsCounterStart(false);
      setCertInput(false);
      setSignUpData({ ...signUpData, certNumber: '' });
      Swal.fire({
        icon: 'error',
        title: '인증시간이 초과됐습니다.',
        timer: 3000,
      });
    }
    return () => clearTimeout(goHome);
  }, [counter, isCounterStart, authNumOk]);

  // 체크박스 전체 단일 개체 선택
  const handleSingleCheck = (checked, id) => {
    if (checked) {
      if (id === 'isSmsDeny') {
        setIsSmsDeny(true);
      } else {
        let checkInData = checkItems.some((item) => item === +id);
        if (!checkInData) {
          setCheckItems([...checkItems, +id]);
        }
      }
    } else {
      // 체크 해제
      if (id === 'isSmsDeny') {
        setIsSmsDeny(false);
      } else {
        setCheckItems(checkItems.filter((el) => el !== id));
      }
    }
  };

  // // 체크박스 전체 선택
  const handleAllCheck = (checked) => {
    if (checked) {
      setCheckItems([1, 2, 3]);
      setIsSmsDeny(true);
    } else {
      setCheckItems([]);
      setIsSmsDeny(false);
    }
  };

  const searchEnter = (e) => {
    if (window.event.keyCode == 13) {
      handleSearchData(e);
    }
  };

  const checkData = () => {
    let filter1 = checkItems.some((item) => item === 1);
    let filter2 = checkItems.some((item) => item === 2);
    let filter3 = checkItems.some((item) => item === 3);

    if (filter1 && filter2 && filter3) {
      setIsThermOk(true);
    } else {
      Swal.fire({ text: '필수 항목을 모두 선택해주세요', timer: 3500 });
    }
    //
  };

  //클릭시 모달 출력
  const showModal = (index) => {
    setIsModal(true);
    setContent(termData[index]);
    setTitle(titleArray[index]);
    setIndexData(index);
  };

  const closeModal = () => {
    setIsModal(false);
    setContent('');
    setTitle('');
    setIndexData();
  };
  useEffect(() => {
    getTermData();
  }, []);

  return (
    <>
      <TermsModal
        show={isModal}
        content={content}
        title={title}
        index={indexData}
        onClose={closeModal}
        agreeFunc={handleSingleCheck}
      />
      <Container fluid={true} className="p-0">
        {<AuthHeader />}
        <div className="login-card" style={{ paddingTop: '100px' }}>
          <div className={!isThermOk || window.innerWidth < 1080 ? '' : 'card-style'}>
            {/* <div>
              <a className="logo" href="#javascript">
                <img
                  style={{ width: 140, maxHeight: 35, objectFit: "cover" }}
                  className="img-fluid for-light"
                  src={require("../assets/images/logo/login.svg")}
                  alt="looginpage"
                />
                <img
                  style={{ width: 140, maxHeight: 35, objectFit: "cover" }}
                  className="img-fluid for-dark"
                  src={require("../assets/images/logo/logo_dark.png")}
                  alt="looginpage"
                />
              </a>
            </div> */}
            <div
              className="login-main"
              style={{
                width:
                  window.innerWidth < 480
                    ? '100%'
                    : window.innerWidth < 1080
                    ? '70vw'
                    : isThermOk
                    ? '100%'
                    : '',
                padding: window.innerWidth < 480 ? 0 : '',
              }}>
              {isThermOk ? (
                <SignUpForm>
                  <Form onKeyDown={noneEnter}>
                    {/* <NextBtn
                      style={{ marginBottom: 15 }}
                      onClick={(e) => {
                        e.preventDefault();
                        setIsThermOk(false);
                      }}
                    >
                      &larr; 이전으로
                    </NextBtn> */}
                    <h3 style={{ marginBottom: '30px' }}>회원가입</h3>
                    <Label1>
                      <h5>
                        전화번호 <strong>* </strong>
                        {/* <p> ('-'를 제외하고 입력해주세요.)</p> */}
                      </h5>
                      <InputWithBtn>
                        <input
                          className="signUpInput"
                          type="text"
                          value={phoneNum}
                          name="phoneNum"
                          onChange={handleChange}
                          required
                          placeholder="-를 제외하고 입력해주세요."
                          disabled={authNumOk}
                        />

                        <OTPBtn onClick={sendMsg}>인증번호 받기</OTPBtn>
                      </InputWithBtn>
                    </Label1>

                    <Label1S show={certInput} style={{ position: 'relative' }}>
                      <InputWithBtn>
                        <input
                          type="text"
                          value={certNumber}
                          name="certNumber"
                          onChange={handleChange}
                          required
                          placeholder="인증번호"
                        />
                        {isCounterStart && <OTPCounterS>{secondsToDhms(counter)}</OTPCounterS>}
                        <OTPBtn onClick={authMsg}>인증하기</OTPBtn>
                      </InputWithBtn>
                    </Label1S>
                    <Label1>
                      <h5>
                        비밀번호 <strong>*</strong>
                      </h5>

                      <input
                        type="password"
                        name="pinNum1"
                        onChange={checkPinNum}
                        value={pinNum1}
                        required
                        placeholder="영문, 숫자, 기호 조합 6자 이상 입력해주세요"
                      />
                    </Label1>
                    <Label1 style={{ marginBottom: '40px', position: 'relative' }}>
                      <h5>
                        비밀번호 확인 <strong>*</strong>
                      </h5>

                      <Input
                        type="password"
                        name="pinNum2"
                        onChange={checkPinNum}
                        value={pinNum2}
                        required
                        placeholder="영문, 숫자, 기호 조합 6자 이상 입력해주세요"
                      />
                      {isPinOk === 'false' && (
                        <strong
                          style={{
                            position: 'absolute',
                            bottom: '-38%',
                            fontWeight: 800,
                            color: 'red',
                          }}>
                          비밀번호가 일치하지 않습니다.
                        </strong>
                      )}
                    </Label1>
                    <Label1>
                      <h5>
                        이름 <strong>*</strong>
                      </h5>

                      <input
                        type="text"
                        value={name}
                        name="name"
                        onChange={handleChange}
                        required
                        placeholder="이름을 입력해주세요"
                      />
                    </Label1>

                    <Label1>
                      <h5>
                        가입할 지점 <strong>*</strong>
                      </h5>
                      <div style={{ display: 'flex' }}>
                        <input
                          style={{ margin: 0 }}
                          className="signUpInput"
                          type="text"
                          value={userMerchantData ? userMerchantData.name : searchMerchant}
                          // name="parentPhoneNum"
                          onChange={handleSearchKeyword}
                          disabled={userMerchantData.id}
                          placeholder=""
                        />
                        {!userMerchantData.id && (
                          <button
                            style={{
                              marginLeft: '5px',
                              width: '40%',
                            }}
                            className="btn btn-primary"
                            onClick={handleSearchData}
                            onKeyDown={searchEnter}>
                            검색
                          </button>
                        )}
                      </div>
                      {!userMerchantData.id && merchantList?.length > 0 && (
                        <SearchLists>
                          {merchantList.map((li) => (
                            <SearchList
                              selected={selectedMerchant?.name === li.name}
                              key={li.id}
                              onClick={() => setSelectedMerchant(li)}>
                              {li.name}
                            </SearchList>
                          ))}
                        </SearchLists>
                      )}
                      {selectedMerchant && (
                        <p style={{ margin: '15px 0  0 7px' }}>
                          <strong>{selectedMerchant?.name}</strong> 으로 선택
                        </p>
                      )}
                      {/* {!userMerchantData ? (
                        <>
                          <select name="merchantId" onChange={handleChange}>
                            <option>-</option>
                            {merchantListData.map((item, index) => (
                              <>
                                {index > 1 ? (
                                  <option value={item.id} key={item.id}>
                                    {item.name}
                                  </option>
                                ) : (
                                  ""
                                )}
                              </>
                            ))}
                          </select>
                        </>
                      ) : (
                        <p>{userMerchantData.name}</p>
                      )} */}
                    </Label1>
                    <Label1>
                      <h5>이메일</h5>

                      <Input
                        type="email"
                        name="email"
                        onChange={handleChange}
                        value={email}
                        placeholder="이메일을 입력해주세요"
                      />
                    </Label1>

                    <Label1>
                      <h5>성별</h5>{' '}
                      <InputRadio>
                        <Label htmlFor={0}>
                          <CheckBoxRound>
                            <div style={{ display: 'flex' }}>
                              <input
                                type="checkbox"
                                name="gender"
                                value={0}
                                onChange={handleChange}
                                checked={gender === 0}
                              />
                              <span className="checkbox_icon" />
                              <p className="marginP">남성</p>
                            </div>
                          </CheckBoxRound>
                        </Label>
                        <Label htmlFor={1}>
                          <CheckBoxRound>
                            <div style={{ display: 'flex' }}>
                              <input
                                type="checkbox"
                                name="gender"
                                value={1}
                                onChange={handleChange}
                                checked={gender === 1}
                              />
                              <span className="checkbox_icon" />
                              <p className="marginP">여성</p>
                            </div>
                          </CheckBoxRound>
                        </Label>
                      </InputRadio>
                    </Label1>

                    <Label1>
                      <h5>
                        부모님 전화번호
                        <p> ('-'를 제외하고 입력해주세요.)</p>
                      </h5>

                      <input
                        type="text"
                        value={parentPhoneNum}
                        name="parentPhoneNum"
                        onChange={handleChange}
                        placeholder="-를 제외하고 입력해주세요."
                      />
                    </Label1>
                    <Label1>
                      <h5>
                        문자수신 <strong>*</strong>
                      </h5>
                      <InputRadio>
                        <Label>
                          <CheckBoxRound>
                            <div style={{ display: 'flex' }}>
                              <input
                                type="checkbox"
                                name="smsDeny"
                                onChange={() =>
                                  setSignUpData({
                                    ...signUpData,
                                    smsDeny: true,
                                  })
                                }
                                value={true}
                                checked={smsDeny === true}
                              />
                              <span className="checkbox_icon" />
                              <p className="marginP">동의</p>
                            </div>
                          </CheckBoxRound>
                        </Label>
                        <Label>
                          <CheckBoxRound>
                            <div style={{ display: 'flex' }}>
                              <input
                                type="checkbox"
                                name="smsDeny"
                                onChange={() =>
                                  setSignUpData({
                                    ...signUpData,
                                    smsDeny: false,
                                  })
                                }
                                value={false}
                                checked={smsDeny === false}
                              />
                              <span className="checkbox_icon" />
                              <p className="marginP">미동의</p>
                            </div>
                          </CheckBoxRound>
                        </Label>
                      </InputRadio>
                    </Label1>
                    <Label1>
                      <h5>
                        카카오톡수신 <strong>*</strong>
                      </h5>
                      <InputRadio>
                        <Label>
                          <CheckBoxRound>
                            <div style={{ display: 'flex' }}>
                              <input
                                type="checkbox"
                                name="alertKaKaoToggle"
                                onChange={() =>
                                  setSignUpData({
                                    ...signUpData,
                                    alertKaKaoToggle: true,
                                  })
                                }
                                value={true}
                                checked={alertKaKaoToggle === true}
                              />
                              <span className="checkbox_icon" />
                              <p className="marginP">동의</p>
                            </div>
                          </CheckBoxRound>
                        </Label>
                        <Label>
                          <CheckBoxRound>
                            <div style={{ display: 'flex' }}>
                              <input
                                type="checkbox"
                                name="alertKaKaoToggle"
                                onChange={() =>
                                  setSignUpData({
                                    ...signUpData,
                                    alertKaKaoToggle: false,
                                  })
                                }
                                value={false}
                                checked={alertKaKaoToggle === false}
                              />
                              <span className="checkbox_icon" />
                              <p className="marginP">미동의</p>
                            </div>
                          </CheckBoxRound>
                        </Label>
                      </InputRadio>
                    </Label1>

                    <Label1>
                      <h5>회원 유형</h5>
                      {/* {isMobile && ( */}
                      <select>
                        <option>중학생</option>
                        <option>고등학생/N수생</option>
                        <option>대학생</option>
                        <option>성인</option>
                      </select>

                      {/* {!isMobile && (
                        <InputRadio>
                          <Label htmlFor="middle">
                            <CheckBoxRound>
                              <div style={{ display: "flex" }}>
                                <input
                                  type="checkbox"
                                  id="middle"
                                  name="job"
                                  onChange={handleChange}
                                  value="middle"
                                  checked={job === "middle"}
                                />
                                <span className="checkbox_icon" />
                                <p className="marginP">중학생</p>
                              </div>
                            </CheckBoxRound>
                          </Label>
                          <Label htmlFor="high">
                            <CheckBoxRound>
                              <div style={{ display: "flex" }}>
                                <input
                                  type="checkbox"
                                  id="high"
                                  name="job"
                                  onChange={handleChange}
                                  value="high"
                                  checked={job === "high"}
                                />
                                <span className="checkbox_icon" />
                                <p className="marginP">고등학생/N수생</p>
                              </div>
                            </CheckBoxRound>
                          </Label>
                          <Label htmlFor="college">
                            <CheckBoxRound>
                              <div style={{ display: "flex" }}>
                                <input
                                  type="checkbox"
                                  id="college"
                                  name="job"
                                  onChange={handleChange}
                                  value="college"
                                  checked={job === "college"}
                                />
                                <span className="checkbox_icon" />
                                <p className="marginP">대학생</p>
                              </div>
                            </CheckBoxRound>
                          </Label>
                          <Label htmlFor="adult">
                            <CheckBoxRound>
                              <div style={{ display: "flex" }}>
                                <input
                                  type="checkbox"
                                  id="adult"
                                  name="job"
                                  onChange={handleChange}
                                  value="adult"
                                  checked={job === "adult"}
                                />
                                <span className="checkbox_icon" />
                                <p className="marginP">성인</p>
                              </div>
                            </CheckBoxRound>
                          </Label>
                        </InputRadio>
                      )} */}
                    </Label1>
                  </Form>
                  <Button
                    style={{
                      width: isMobile ? '100%' : '40%',
                      display: 'block',
                      margin: 'auto',
                    }}
                    color="primary"
                    disabled={
                      !(isPinOk === 'true' && authNumOk) ||
                      (!userMerchantData.name && selectedMerchant === null)
                    }
                    onClick={onSubmit}>
                    회원가입
                  </Button>
                </SignUpForm>
              ) : (
                <TermContainer>
                  <p className="color">이용 약관 동의</p>
                  <Title>
                    <p>서비스 이용을 위한</p>
                    <p>회원가입이 필요합니다.</p>
                    <p>이용약관에 동의해주세요.</p>
                  </Title>

                  <CheckBoxRound
                    style={{
                      margin: '30px 0 0',
                      width: '100%',
                      borderTop: '0.5px solid #04C6D8',
                      borderBottom: ' 0.5px solid #04C6D8',
                      background: '#fafafa',
                      alignItems: 'center',
                      padding: window.innerWidth < 480 ? '3.8vw 5vw' : '0 1vw',
                    }}
                    htmlFor="all">
                    <input
                      id="all"
                      type="checkbox"
                      onChange={({ target }) => handleAllCheck(target.checked)}
                      checked={checkItems.length === 3 && isSmsDeny}
                    />
                    <span className="checkbox_icon" />

                    <p
                      style={{
                        margin: '1vw',
                        marginLeft: window.innerWidth < 480 ? '6vw' : '1vw',
                        fontSize: window.innerWidth < 480 ? '3.5vw' : '13px',
                        color: '#45474D',
                        fontWeight: 600,
                      }}>
                      전체선택
                    </p>
                  </CheckBoxRound>

                  <CheckBoxes id={index.toString()}>
                    <CheckeBoxList id={index.toString()}>
                      <CheckBoxRound htmlFor="1">
                        <div>
                          {' '}
                          <Input
                            id="1"
                            name="type"
                            type="checkbox"
                            onChange={({ target }) => handleSingleCheck(target.checked, 1)}
                            checked={checkItems.includes(1)}
                          />
                          <span className="checkbox_icon blackColor" />
                          <p>{titleArray && titleArray[0]}</p>
                          {/* <p>
                            [필수] I. 렛미업 스카 클라우드 서비스 이용약관 및
                            II. 운영매장 이용수칙
                          </p> */}
                        </div>
                        <button
                          onClick={() => {
                            showModal(0);
                          }}
                          type="button">
                          <img src={drop} alt="drop" draggable={false} />
                        </button>
                      </CheckBoxRound>
                      {/* <ContentsWrapper
                        style={{
                          height: isShow1 && index === 0 ? "23.3vw" : "0",
                        }}
                      >
                        <Contents>{termData && termData[0]}</Contents>
                      </ContentsWrapper> */}
                    </CheckeBoxList>
                    <CheckeBoxList id={index !== null && index}>
                      <CheckBoxRound htmlFor="2">
                        <div>
                          <Input
                            id="2"
                            name="type"
                            type="checkbox"
                            onChange={({ target }) => handleSingleCheck(target.checked, 2)}
                            checked={checkItems.includes(2)}
                          />
                          <span className="checkbox_icon blackColor" />
                          <p>{titleArray && titleArray[1]}</p>
                        </div>
                        <button
                          onClick={() => {
                            showModal(1);
                          }}
                          type="button">
                          <img src={drop} alt="drop" draggable={false} />
                        </button>
                      </CheckBoxRound>
                      {/* <ContentsWrapper
                        style={{
                          height: isShow2 && index === 1 ? "23.3vw" : "0",
                        }}
                      >
                        <Contents>{termData && termData[1]}</Contents>
                      </ContentsWrapper> */}
                    </CheckeBoxList>

                    <CheckeBoxList id={index !== null && index}>
                      <CheckBoxRound htmlFor="3">
                        <div>
                          <Input
                            id="3"
                            name="type"
                            type="checkbox"
                            onChange={({ target }) => handleSingleCheck(target.checked, 3)}
                            checked={checkItems.includes(3)}
                          />
                          <span className="checkbox_icon blackColor" />
                          <p> {titleArray && titleArray[2]}</p>
                        </div>
                        <button
                          onClick={() => {
                            showModal(2);
                          }}
                          type="button">
                          <img src={drop} alt="drop" draggable={false} />
                        </button>
                      </CheckBoxRound>
                      {/* <ContentsWrapper
                        style={{
                          height: isShow3 && index === 2 ? "23.3vw" : "0",
                        }}
                      >
                        <Contents>{termData && termData[2]}</Contents>
                      </ContentsWrapper> */}
                    </CheckeBoxList>
                    <CheckeBoxList id={index !== null && index}>
                      <CheckBoxRound htmlFor="4">
                        <div>
                          <Input
                            id="4"
                            name="type"
                            type="checkbox"
                            onChange={({ target }) =>
                              handleSingleCheck(target.checked, 'isSmsDeny')
                            }
                            checked={isSmsDeny}
                          />
                          <span className="checkbox_icon blackColor" />
                          <p> {titleArray && titleArray[3]}</p>
                        </div>
                        <button
                          onClick={() => {
                            showModal(3);
                          }}
                          type="button">
                          <img src={drop} alt="drop" draggable={false} />
                        </button>
                      </CheckBoxRound>
                      {/* <ContentsWrapper
                        style={{
                          height: isShow4 && index === 3 ? "23.3vw" : "0",
                        }}
                      >
                        <Contents>{termData && termData[3]}</Contents>
                      </ContentsWrapper> */}
                    </CheckeBoxList>
                  </CheckBoxes>

                  <button
                    className="loginBtn"
                    onClick={(e) => {
                      e.preventDefault();
                      checkData();
                    }}>
                    다음
                  </button>
                </TermContainer>
              )}
              <RouteLoginBtn>
                <p>이미 렛미업 회원이신가요?</p>
                <a className="ml-2" href="#javascript" onClick={() => history.push('/auth/login')}>
                  로그인
                </a>
              </RouteLoginBtn>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default SignUp;
