import React, { useEffect, useState } from 'react';
import { Container, Col } from 'reactstrap';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import { backUserCheckReset, backUserMsgAuth, userPassReset } from '../lib/api';
import { Label1 } from '../pages/lockerChange';
import AuthHeader from './headerComponent';

import Logo from '../assets/images/logo/login.svg';

const InputWithBtn = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 100%;
  > input {
    margin-top: 0;
    width: 100%;
  }
`;

const Label1S = styled(Label1)`
  display: ${(props) => (props.show ? 'flex' : 'none')};
`;

const OTPCounterS = styled.h6`
  position: absolute;
  top: 8%;
  right: 14px;
  color: #2d55e2;
  font-size: 12px;
  font-weight: 600;
  @media screen and (max-width: 480px) {
    top: 7%;
    right: 8px;
  }
`;

function secondsToDhms(num) {
  const m = Math.floor((num % 3600) / 60);
  const s = Math.floor(num % 60);

  const mDisplay = m > 0 ? `${m}분` : '';
  const sDisplay = s > 0 ? ` ${s}초` : '';
  return mDisplay + sDisplay;
}

let submitFlag = false;

function submitCheck() {
  if (submitFlag) {
    return submitFlag;
  }
  submitFlag = true;
}

const FindPassword = () => {
  const [phoneNum, setPhoneNum] = useState();
  const [certNumber, setCertNumber] = useState();
  const [counter, setCounter] = useState(100);
  const [pw1, setPw1] = useState();
  const [pw2, setPw2] = useState();
  const [isCounterStart, setIsCounterStart] = useState(false);
  const [pinNum, setPinNum] = useState({ pinNum1: '', pinNum2: '' });
  const { pinNum1, pinNum2 } = pinNum;
  const [isPinOk, setIsPinOk] = useState('');
  const [authNumOk, setAuthNumOk] = useState(false);
  const [certInput, setCertInput] = useState(false);
  const [pwCheckOk, setPwCheckOk] = useState('');
  const [isOtpCodeSend, setIsOtpCodeSend] = useState(false);
  const [mid, setMid] = useState();
  var isMobile = /Mobi/i.test(window.navigator.userAgent);

  const sendMsg = async () => {
    submitCheck();
    try {
      if (phoneNum.length !== 11) {
        Swal.fire({
          icon: 'error',
          title: '전화번호를 정확히 입력해주세요',
          timer: 3000,
        });
      } else {
        setIsOtpCodeSend(true);
        const res = await backUserCheckReset({ phoneNum: phoneNum });

        if (res.status === 200 && res.data === 1) {
          setCertInput(true);
          setIsCounterStart(true);
        } else {
          Swal.fire({
            icon: 'error',
            title: '회원이 아닙니다. 회원가입을 먼저 진행해주세요',
            timer: 3000,
          });
        }
      }
    } catch (err) {
      Swal.fire({
        icon: 'error',
        title: '에러가 발생했습니다.\n 다시 시도해주세요.',
        timer: 3000,
      });
    }
    submitFlag = false;
  };

  const authMsg = async () => {
    submitCheck();
    try {
      const res = await backUserMsgAuth({
        phoneNum: phoneNum,
        certNumber: certNumber,
      });

      if (res.status === 200 && res.data !== 0) {
        Swal.fire({
          icon: 'success',
          title: '인증이 완료되었습니다.',
          timer: 2000,
        });
        setMid(res.data[0].merchantId);
        setAuthNumOk(true);
        setCertInput(false);
        setIsCounterStart(false);
      } else {
        Swal.fire({
          title: '인증번호가 일치하지 않습니다.',
          icon: 'error',
          timer: 2000,
        });
      }
    } catch (err) {}
  };

  useEffect(() => {
    if (pinNum1.length >= 6 && pinNum2.length >= 6) {
      if (pinNum1 === pinNum2) {
        setIsPinOk('true');
      } else {
        setIsPinOk('false');
      }
    } else {
      setIsPinOk('');
    }
  }, [pinNum]);

  const pwCheck = () => {
    if (pw1 !== pw2) {
      Swal.fire({
        icon: 'error',
        title: '변경할 비밀번호와 확인된 비밀번호를 맞춰주세요',
        timer: 3000,
      });
    } else {
      let pwCheck = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{6,}$/;
      let booleanCheck = pwCheck.test(pw1);

      if (!booleanCheck) {
        Swal.fire({
          icon: 'error',
          title: '비밀번호 구성이 올바르지 않습니다.',
          text: '비밀번호는 영문,숫자,특수문자(!@$%^&* 만 허용)를\n조합하여 6자 이상으로 구성하세요',
        });
      } else {
        Swal.fire({
          icon: 'success',
          title: '비밀번호 확인이 완료되었습니다.',
          text: '비밀번호 변경을 눌러주세요',
        });
        setPwCheckOk(booleanCheck);
      }
    }
  };

  const changePw = async () => {
    let pwResult = await userPassReset({
      password: pw1,
      phoneNum: phoneNum,
      merchantId: mid,
    });
    if (pwResult.status === 200) {
      Swal.fire({
        icon: 'success',
        title: '비밀번호 변경이 완료되었습니다.',
        timer: 3000,
      }).then(() => (window.location.href = `${process.env.PUBLIC_URL}/auth/login`));
    } else {
      Swal.fire({
        icon: 'error',
        title: '변경 중 에러가 발생했습니다. 다시한번 시도해주세요',
        timer: 3000,
      });
    }
  };
  useEffect(() => {
    const goHome =
      isCounterStart &&
      setTimeout(() => {
        setCounter(counter - 1);
      }, 1000);
    if (counter === 0) {
      setCounter(100);
      setIsCounterStart(false);

      Swal.fire({
        icon: 'error',
        title: '인증시간이 초과됐습니다.',
        timer: 3000,
      });
    }
    return () => clearTimeout(goHome);
  }, [counter, isCounterStart, authNumOk]);

  return (
    <Container fluid={true} className="p-0">
      {window.innerWidth > 480 && <AuthHeader />}
      <Col xs="12">
        <div className="login-card">
          <div className={window.innerWidth < 1080 ? '' : 'card-style'}>
            <div>
              <a className="logo" href="/">
                <img className="img-fluid for-light" src={Logo} alt="loginpage" />
              </a>
            </div>
            <div
              className="login-main"
              style={{
                width: isMobile ? '100%' : window.innerWidth > 480 ? '100%' : '100%',
              }}>
              <p className="main-color">비밀번호 변경</p>
              <Label1>
                <input
                  className="inputStyle"
                  type="text"
                  value={phoneNum}
                  name="phoneNum"
                  onChange={(e) => setPhoneNum(e.target.value)}
                  required
                  placeholder="휴대폰 번호 '-'를 제외하고 입력해주세요"
                  disabled={authNumOk}
                />

                {!isOtpCodeSend && (
                  <button
                    style={{ marginTop: isMobile ? '20vw' : '5vw' }}
                    className="loginBtn"
                    onClick={sendMsg}>
                    인증번호 받기
                  </button>
                )}
              </Label1>
              <Label1S show={certInput} style={{ position: 'relative' }}>
                <InputWithBtn>
                  <input
                    className="inputStyle"
                    type="text"
                    value={certNumber}
                    name="certNumber"
                    onChange={(e) => setCertNumber(e.target.value)}
                    required
                    placeholder="인증번호"
                  />
                  {isCounterStart && <OTPCounterS>{secondsToDhms(counter)}</OTPCounterS>}
                  <button
                    style={{ marginTop: isMobile ? '20vw' : '5vw' }}
                    className="loginBtn"
                    onClick={authMsg}>
                    인증하기
                  </button>
                </InputWithBtn>
              </Label1S>
              {authNumOk && (
                <>
                  <Label1>
                    <h5>변경할 비밀번호</h5>
                    <p>
                      비밀번호 형식
                      <br />
                      :영문,숫자,특수문자(!@$%^&* 만 허용)를
                      <br />
                      조합하여 6자 이상
                    </p>
                    <InputWithBtn>
                      <input
                        type="text"
                        value={pw1}
                        name="pw1"
                        onChange={(e) => setPw1(e.target.value)}
                        required
                        placeholder="형식에 맞춰 입력해주세요"
                      />
                    </InputWithBtn>
                  </Label1>
                  <Label1>
                    <h5>비밀번호 확인</h5>
                    <InputWithBtn>
                      <input
                        type="text"
                        value={pw2}
                        name="pw2"
                        onChange={(e) => setPw2(e.target.value)}
                        required
                        placeholder="형식에 맞춰 입력해주세요"
                      />
                    </InputWithBtn>
                  </Label1>
                  <button className="loginBtn" onClick={pwCheck}>
                    비밀번호 확인
                  </button>
                  {pwCheckOk && (
                    <button className="loginBtn" onClick={changePw}>
                      비밀번호 변경
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </Col>
    </Container>
  );
};

export default FindPassword;
