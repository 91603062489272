import { userWithMerchantToken, userWithUserToken } from '.';

/**
 * 사용자가 이용 중인 지점 목록
 * @returns
 */
export const getMerchant = () => userWithUserToken.get(`/merchant`);

/**
 * 사용자 정보 조회
 * @returns
 */
export const getInfo = () => userWithMerchantToken.get('/info');
