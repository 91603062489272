// dashbaord
import Dashboard from '../components/dashboard';

//user
import UserList from '../pages/userList';
import UserEnroll from '../pages/userEnroll';

// pages
import ProductList from '../pages/productList';
import TicketList from '../pages/ticketList';
import SeatInfo from '../pages/seatInfo';
import SeatList from '../pages/seatList';
import Setting from '../pages/setting';
import PaylogInfo from '../pages/paylogInfo';
import PaylogChart from '../pages/paylogChart';
import Sales from '../pages/paylogChart';
import MessageInfo from '../pages/messageInfo';
import MessageSend from '../pages/messageSend';
import MessageBulk from '../pages/messageBulk';
import storeSetting from '../pages/merchantSetting';
import SeatChange from '../pages/seatChange';
import LockerChange from '../pages/lockerChange';
import Seminar from '../pages/seminarReservation';
import SeminarInfo from '../pages/seminarInfo';
// import TicketCustom from '../pages/ticketCustom';
import Mileage from '../pages/mileage';
import Coupon from '../pages/coupon/coupon';
import SignUp from '../auth/signup';

import MerchantList from '../pages/merchantList';
import Vending from '../pages/vending';
import Kiosk_setting from '../pages/kiosk_setting';
import ExitLog from '../pages/exitLog';
import UserTicketInfo from '../pages/user/userTicketInfo';
import UserTicketBuy from '../pages/user/userTicketBuy';
import UserInfo from '../pages/user/userInfo';
import UserPaymentInfo from '../pages/user/paymentInfo';
import UserSeminarInfo from '../pages/user/seminarInfo';
import UserSeminarBuy from '../pages/user/seminarBuy';
import UserMileage from '../pages/user/mileage';
import Usercoupon from '../pages/user/coupon';
import UserSeatList from '../pages/user/UserSeatList';
import User from '../pages/user/user';

import Success from '../pages/user/success';
import AdminDashboard from '../components/dashboard/admin';
import FindPassword from '../auth/password';
import ImageSetting from '../pages/imgsetting';

//이용약관 - terms
import InformationAgreement from '../pages/terms/informationAgreement';
import MarketingAgreement from '../pages/terms/marketingAgreement';
import ServiceRule from '../pages/terms/serviceRule';
import UseRule from '../pages/terms/useRule';
import MessageCharge from '../pages/messageCharge';
import SalesDay from '../pages/salesDay';
import SalesMonth from '../pages/salesMonth';
import MessageSuccess from '../pages/messageSuccess';
import FailPayment from '../pages/failPayment';
import MessageFail from '../pages/messageFail';
import PaymentSetting from '../pages/paymentSetting';
import WebReceipt from '../pages/webReceipt';
import SeatCategory from '../pages/seat/SeatCategory';
import SelectMerchant from '../pages/user/SelectMerchant';
import PaymentResult from '../pages/user/PaymentResult';
import LockerChangeV2 from '../pages/lockerChangeV2';
import SeatChangeV2 from '../pages/seatChangeV2';
import MessageSendV2 from '../pages/message/MessageSendV2';
import MessageBulkV2 from '../pages/message/MessageBulkV2';
import CouponV2 from '../pages/coupon/CouponV2';

export const routes = [
  {
    path: `${process.env.PUBLIC_URL}/admin/dashboard`,
    Component: AdminDashboard,
  },
  { path: `${process.env.PUBLIC_URL}/user/userlist`, Component: UserList },
  { path: `${process.env.PUBLIC_URL}/user/userEnroll`, Component: UserEnroll },
  {
    path: `${process.env.PUBLIC_URL}/auth/join`,
    Component: SignUp,
  },
  {
    path: `${process.env.PUBLIC_URL}/auth/password`,
    Component: FindPassword,
  },

  {
    path: `${process.env.PUBLIC_URL}/product/product-list`,
    Component: ProductList,
  },
  {
    path: `${process.env.PUBLIC_URL}/product/vending`,
    Component: Vending,
  },
  {
    path: `${process.env.PUBLIC_URL}/ticket/ticket-list`,
    Component: TicketList,
  },
  {
    path: `${process.env.PUBLIC_URL}/seat/info`,
    Component: SeatInfo,
  },
  {
    path: `${process.env.PUBLIC_URL}/seat/list`,
    Component: SeatList,
  },
  {
    path: `${process.env.PUBLIC_URL}/setting/default-setting`,
    Component: Setting,
  },
  {
    path: `${process.env.PUBLIC_URL}/setting/store-setting`,
    Component: storeSetting,
  },
  {
    path: `${process.env.PUBLIC_URL}/setting/payment-setting`,
    Component: PaymentSetting,
  },
  {
    path: `${process.env.PUBLIC_URL}/pay/info`,
    Component: PaylogInfo,
  },
  {
    path: `${process.env.PUBLIC_URL}/pay/chart`,
    Component: PaylogChart,
  },
  {
    path: `${process.env.PUBLIC_URL}/sales`,
    Component: Sales,
  },
  {
    path: `${process.env.PUBLIC_URL}/message/info`,
    Component: MessageInfo,
  },
  // {
  //   path: `${process.env.PUBLIC_URL}/message/send`,
  //   Component: MessageSend,
  // },

  {
    path: `${process.env.PUBLIC_URL}/message/send`,
    Component: MessageSendV2,
  },
  // {
  //   path: `${process.env.PUBLIC_URL}/message/bulk`,
  //   Component: MessageBulk,
  // },
  {
    path: `${process.env.PUBLIC_URL}/message/bulk`,
    Component: MessageBulkV2,
  },
  {
    path: `${process.env.PUBLIC_URL}/message/charge`,
    Component: MessageCharge,
  },
  // {
  //   path: `${process.env.PUBLIC_URL}/seat/change`,
  //   Component: SeatChange,
  // },
  // {
  //   path: `${process.env.PUBLIC_URL}/locker/change`,
  //   Component: LockerChange,
  // },
  // {
  //   path: `${process.env.PUBLIC_URL}/seat/category`,
  //   Component: SeatCategory,
  // },
  {
    path: `${process.env.PUBLIC_URL}/seminar/reservation`,
    Component: Seminar,
  },
  {
    path: `${process.env.PUBLIC_URL}/seminar/info`,
    Component: SeminarInfo,
  },
  {
    path: `${process.env.PUBLIC_URL}/mileage`,
    Component: Mileage,
  },

  {
    path: `${process.env.PUBLIC_URL}/setting/kiosk-setting`,
    Component: Kiosk_setting,
  },
  {
    path: `${process.env.PUBLIC_URL}/exit-data`,
    Component: ExitLog,
  },
  {
    path: `${process.env.PUBLIC_URL}/setting/image-setting`,
    Component: ImageSetting,
  },

  {
    path: `${process.env.PUBLIC_URL}/success`,
    Component: Success,
  },

  {
    path: `${process.env.PUBLIC_URL}/admin/success`,
    Component: MessageSuccess,
  },

  // 웹결제시 실패했을경우(취소,오류)
  {
    path: `${process.env.PUBLIC_URL}/fail`,
    Component: FailPayment,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/fail`,
    Component: MessageFail,
  },

  {
    path: `${process.env.PUBLIC_URL}/sales/day`,
    Component: SalesDay,
  },
  {
    path: `${process.env.PUBLIC_URL}/sales/month`,
    Component: SalesMonth,
  },
  {
    path: `${process.env.PUBLIC_URL}/receipt/:token`,
    Component: WebReceipt,
  },
];

export const v1Route = [
  {
    path: `${process.env.PUBLIC_URL}/seat/change`,
    Component: SeatChange,
  },
  {
    path: `${process.env.PUBLIC_URL}/locker/change`,
    Component: LockerChange,
  },
  {
    path: `${process.env.PUBLIC_URL}/coupon`,
    Component: Coupon,
  },
];

export const v2Route = [
  {
    path: `${process.env.PUBLIC_URL}/seat/change`,
    Component: SeatChangeV2,
  },
  {
    path: `${process.env.PUBLIC_URL}/locker/change`,
    Component: LockerChangeV2,
  },
  {
    path: `${process.env.PUBLIC_URL}/seat/category`,
    Component: SeatCategory,
  },
  {
    path: `${process.env.PUBLIC_URL}/coupon`,
    Component: CouponV2,
  },
];

export const merchantUserRoute = [
  // user
  { path: `${process.env.PUBLIC_URL}/dashboard`, Component: Dashboard },
  {
    path: `${process.env.PUBLIC_URL}/user/ticket/info`,
    Component: UserTicketInfo,
  },
  {
    path: `${process.env.PUBLIC_URL}/user/ticket/buy`,
    Component: UserTicketBuy,
  },
  {
    path: `${process.env.PUBLIC_URL}/user/user-info`,
    Component: UserInfo,
  },
  {
    path: `${process.env.PUBLIC_URL}/user/payment-info`,
    Component: UserPaymentInfo,
  },
  {
    path: `${process.env.PUBLIC_URL}/user/seat/list`,
    Component: UserSeatList,
  },
  {
    path: `${process.env.PUBLIC_URL}/user/info`,
    Component: User,
  },
  {
    path: `${process.env.PUBLIC_URL}/user/seminar/info`,
    Component: UserSeminarInfo,
  },
  {
    path: `${process.env.PUBLIC_URL}/user/seminar/buy`,
    Component: UserSeminarBuy,
  },
  {
    path: `${process.env.PUBLIC_URL}/user/mileage`,
    Component: UserMileage,
  },
  {
    path: `${process.env.PUBLIC_URL}/user/coupon`,
    Component: Usercoupon,
  },
  {
    path: `${process.env.PUBLIC_URL}/user/payment-result/:statusOrTransactionId/:ticketType`,
    Component: PaymentResult,
  },
  {
    path: `${process.env.PUBLIC_URL}/user/payment-result/:statusOrTransactionId`,
    Component: PaymentResult,
  },
];

export const userRoute = [
  { path: `${process.env.PUBLIC_URL}/select-merchant`, Component: SelectMerchant },
];

export const termsRoute = [
  {
    path: `${process.env.PUBLIC_URL}/auth/terms/1`,
    Component: ServiceRule,
  },
  {
    path: `${process.env.PUBLIC_URL}/auth/terms/2`,
    Component: UseRule,
  },
  {
    path: `${process.env.PUBLIC_URL}/auth/terms/3`,
    Component: InformationAgreement,
  },
  {
    path: `${process.env.PUBLIC_URL}/auth/terms/4`,
    Component: MarketingAgreement,
  },
];
