import React, { Fragment, useEffect, useState } from 'react';
import Breadcrumb from '../layout/breadcrumb';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import styled from 'styled-components';
import DataTable from 'react-data-table-component';
import { ko } from 'date-fns/locale';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import {
  PaylogDataList,
  getMerchantInfo,
  getPaylogList,
  merchantId_api,
  postPayLog,
  putRefundLog,
  userList,
  webpayRefund,
} from '../lib/api';
import { paylogListColumns } from '../data/ticketList';
import 'moment/locale/ko';
import PaginationComponent from '../components/pagination';
import { payCancel, recetipReprint, socket } from '../lib/socket';
import Swal from 'sweetalert2';
import DetailsModal from '../components/modal/detailsModal';
import { ExampleCustomInput, LabelRow } from './ticketCustom';
import { DatePickerStyle } from './seatInfo';
import SelectDate from '../components/selectDate';
import { exportExcel, numberWithCommas } from '../components/common';
import { paymentTypeConversionsKr } from '../util/util';
import InputPhoneNumModal from '../components/modal/inputPhoneNumModal';
import PerPageSelect from '../components/table/PerPageSelect';
import { FlexCenterV } from '../style/flexStyle';
import { postTicketKspayRefund } from '../lib/api/v2/payment';
import Table, { TableHeaderContainer, TableHeaderContainerV2 } from '../components/common/Table';
import PageContainer from '../components/common/PageContainer';
import { SearchButton } from '../components/input';

import { Button, ExcelButton } from '../components/common/Button';
import IconExcel from '../assets/images/button/icon_button_excel.svg';
import DatePickerComponent from '../components/filter/DatePicker';
import Search from '../components/common/Search';
const ButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.5rem;

  @media screen and (max-width: 767px) {
    display: flex;
    flex-direction: column;
  }
`;

let merchantId = '';

const SalesInfo = ({ setIsLoading }) => {
  const [tagModal, setTagModal] = useState(false);
  const [lists, setLists] = useState([]);
  // const [startDate, setStartDate] = useState(new Date());
  // const [endDate, setEndDate] = useState(new Date());
  const [startDate, setStartDate] = useState(moment().startOf('isoWeek').toDate());
  const [endDate, setEndDate] = useState(moment().endOf('isoWeek').toDate());
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);
  const [postsPerPageAdd, setPostsPerPageAdd] = useState(10);
  const [postsPerPageAdd1, setPostsPerPageAdd1] = useState(10);
  const [pageNum, setPageNum] = useState(0);
  const [totalLength, setTotalLength] = useState(0);
  const [selected, setSelected] = useState(null);
  const [searchKeyword, setSearchKeyword] = useState(null);
  const [detailUserModal, setDetailUserModal] = useState(false);
  const [userData, setUserData] = useState({});
  const indexOfLast = currentPage * postsPerPage;
  const indexOfFirst = indexOfLast - postsPerPage;
  const [searchData, setSearchData] = useState([]);
  const [vanType, setVanType] = useState('');
  const [isInputPhoneNum, setIsInputPhoneNum] = useState(false);
  useEffect(() => {
    getMerchantId();
  }, []);

  const getMerchantId = async () => {
    const { data } = await merchantId_api();
    if (data) {
      merchantId = data.id;
      getPaylogListApi(data.id);
      getMerchantVanData(data.id);
    }
    socket.emit('JoinSocketRoom', data.id);
  };
  // Open Modal
  const handleSetLists = (e) => {
    setSelected(+e.target.id);
    setTagModal(true);
  };

  const tagToggle = () => {
    setTagModal(!tagModal);
    if (tagModal) {
      getPaylogListApi(merchantId);
    }
  };

  const sendDetailData = (phoneNum) => {
    getUserList(phoneNum);
  };

  const getUserList = async (phoneNum) => {
    const result = await userList(merchantId, phoneNum);
    if (result && result.status === 200) {
      setUserData(result.data[0]);
      setDetailUserModal(!detailUserModal);
    }
  };

  const showDetailModal = () => {
    setDetailUserModal(!detailUserModal);
  };

  const currentPosts = (tmp) => {
    let currentPosts = 0;
    currentPosts = tmp.slice(indexOfFirst, indexOfLast);
    return currentPosts;
  };

  // 마일리지 데이터 적립
  const changeMileageData = (logData) => {
    const result = [];

    const map = new Map();

    for (const row of logData) {
      const id = row.id;

      const existingRow = map.get(id);

      if (existingRow) {
        existingRow.amount1 = existingRow.amount1 || row.amount1;

        existingRow.amount2 = existingRow.amount2 || row.amount2;
      } else {
        map.set(id, row);
      }
    }

    for (const row of map.values()) {
      result.push(row);
    }

    return result;
  };

  const getMerchantVanData = async (id) => {
    const { data } = await getMerchantInfo(id);
    setVanType(data[0].card_type);
  };
  const getPaylogListApi = async (merchantId, page) => {
    setIsLoading(true);
    if (merchantId) {
      // // // 이전 결제로그.(마일리지 출력 x)
      // const { data } = await getPaylogList(
      //   merchantId,
      //   moment(startDate).format("YYYY-MM-DD 00:00:00"),
      //   moment(endDate).format("YYYY-MM-DD 23:59:59"),
      //   page ? 1 : currentPage,
      //   postsPerPage,
      //   searchKeyword
      // );

      // 결제로그(마일리지o)
      const { data } = await PaylogDataList(
        merchantId,
        moment(startDate).format('YYYY-MM-DD 00:00:00'),
        moment(endDate).format('YYYY-MM-DD 23:59:59'),
        1,
        '',
      );
      let resultData = [...data];
      if (data.length > 0) {
        resultData = changeMileageData(data);
        setTotalLength(resultData);
      }

      if (page) {
        setCurrentPage(1);
      }

      if (resultData.length > 0) {
        setLists(
          resultData,
          resultData.map((item, idx) => {
            item.p_type =
              item.type === 1 ? (
                <p style={{ color: '#EBAF22' }}>일회권</p>
              ) : item.type === 2 ? (
                <p style={{ color: '#E03D64' }}>기간권</p>
              ) : item.type === 3 ? (
                <p style={{ color: '#33946B' }}>시간정액권</p>
              ) : item.type === 4 ? (
                <p style={{ color: '#3E67B6' }}>세미나실</p>
              ) : item.type === 5 ? (
                <p style={{ color: '#AB84FF' }}>사물함</p>
              ) : item.meta8?.includes('자판기') ? (
                <p>자판기</p>
              ) : item.meta8?.includes('컵코인') ? (
                <p>컵코인</p>
              ) : item.meta8?.includes('백오피스') ? (
                <p>백오피스</p>
              ) : item.meta8?.includes('이전 데이터') ? (
                <p>이전 데이터</p>
              ) : (
                <p>-</p>
              );
            item.phoneNumTag = (
              <div
                onClick={() => {
                  sendDetailData(item.phoneNum);
                }}
                className="phoneNum-click">
                {item.phoneNum}
              </div>
            );
            item.addMileage = numberWithCommas(item?.amount1);
            item.minusMileage = numberWithCommas(Math.abs(+item?.amount2));
            item.createdAt = moment(item.createdAt).format('YYYY-MM-DD HH:mm:ss');
            item.price = item.amount === null ? null : `${numberWithCommas(item?.amount)}원`;
            item.cancel = item.paymentType?.includes('cancel') ? (
              <p
                style={{
                  textDecorationLine: 'line-through',
                  color: '#E00F2C',
                }}>
                결제완료(환불완료)
              </p>
            ) : (
              <p>결제완료</p>
            );
            item.action = (
              <button className="btn-primary btn-sm" id={idx} onClick={(e) => handleSetLists(e)}>
                자세히
              </button>
            );
          }),
        );

        if (searchKeyword?.length > 0) {
          const filterData = resultData.filter((list) => list?.phoneNum?.includes(searchKeyword));
          setSearchData(filterData);
        } else {
          setSearchData(resultData);
        }
      } else {
        setLists([]);
        setSearchData([]);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getPaylogListApi(merchantId, 1);
    setCurrentPage(1);
  }, [startDate, endDate]);

  const handleSearchKeyword = (keyword) => {
    if (keyword === '') {
      setSearchKeyword(null);
    } else {
      setSearchKeyword(keyword);
    }
  };

  const refundSuccess = (state) => {
    try {
      if (state) {
        Swal.fire('환불이 완료됐습니다.').then(() => {
          getPaylogListApi(merchantId, currentPage);
          setDetailUserModal(false);
          setTagModal(false);
          setSelected();
        });
      } else {
        Swal.fire('환불에 실패했습니다').then(() => {
          getPaylogListApi(merchantId, currentPage);
          setDetailUserModal(false);
          setTagModal(false);
          setSelected();
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const payLogCancel = async (type) => {
    console.log('⚠️ paylog cancel......');
    let transactionInfo;
    if (type === 'cash') {
      transactionInfo = {
        ticketId: lists[selected].ticketId,
        paymentType: `cash-cancel`,
        userId: lists[selected].userId,
        cashRecepitType: null,
        couponId: null,
        merchantId: lists[selected].merchantId,
        addTime: lists[selected].addTime,
        addDay: lists[selected].addDay,
        amount: lists[selected].amount,
        meta1: ``,
        meta2: ``,
        meta3: ``,
        meta4: ``,
        meta5: ``,
        meta6: ``,
        meta7: ``,
        meta9: ``,
        meta8: lists[selected].meta8,
        meta10: ``,
        meta11: ``,
        meta12: ``,
        meta13: ``,
        spread: null,
        mileage: lists[selected]?.mileage ? lists[selected]?.mileage : 0,
      };
    } else {
      transactionInfo = {
        ticketId: lists[selected].ticketId,
        paymentType: `virtual-cancel`,
        userId: lists[selected].userId,
        cashRecepitType: null,
        couponId: null,
        merchantId: lists[selected].merchantId,
        addTime: lists[selected].addTime,
        addDay: lists[selected].addDay,
        amount: lists[selected].amount,
        meta1: ``,
        meta2: ``,
        meta3: ``,
        meta4: ``,
        meta5: ``,
        meta6: ``,
        meta7: ``,
        meta9: ``,
        meta8: lists[selected].meta8,
        meta10: ``,
        meta11: ``,
        meta12: ``,
        meta13: ``,
        spread: null,
        mileage: lists[selected]?.mileage ? lists[selected]?.mileage : 0,
      };
    }

    const tId = await postPayLog(transactionInfo);

    if (tId.status === 200) {
      let refundDataResult = await putRefundLog({
        merchantId: lists[selected].merchantId,
        id: lists[selected]?.id,
      });

      if (refundDataResult.status === 200) {
        Swal.fire({
          icon: 'success',
          title: '매출취소가 완료되었습니다',
          timer: 5000,
        }).then(() => {
          getPaylogListApi(merchantId, currentPage);
          setDetailUserModal(false);
          setTagModal(false);
          setSelected();
        });
      }
    } else {
      Swal.fire({
        title: '매출 취소 실패',
        text: '매출 취소를 실패했습니다. 다시시도해주세요',
      });
    }
  };

  socket.on('refundSuccess', refundSuccess);

  const logCancelFunc = () => {
    Swal.fire({
      showCancelButton: true,
      confirmButtonColor: '#04c9db',
      cancelButtonColor: '#F53C14',
      confirmButtonText: '확인',
      cancelButtonText: '취소',
      reverseButtons: true,
      title: '현금을 키오스크에서 방출시키시겠습니까?',
      html: `확인 선택시 키오스크에서 해당금액이 방출됩니다.`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (vanType === 'KIS')
          payCancel(merchantId, {
            tid: lists[selected]?.id,
            isVan: false,
          });
        else payCancel(merchantId, lists[selected]?.id);
      } else if (result.isDismissed) {
        payLogCancel('cash');
      }
    });
  };
  const getCancle = (isVan) => {
    // 결제 매체(카드, 카카오페이)가 필요 없는 카드
    if (isVan) {
      Swal.fire({
        showCancelButton: true,
        confirmButtonColor: '#04c9db',
        cancelButtonColor: '#F53C14',
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        reverseButtons: true,
        title: '카드결제를 취소하시겠습니까?',
        html: `카드결제취소시 키오스크에서 환불처리가 진행되며, \n별도의 환불카드가 필요없습니다.`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          if (lists[selected]?.van && lists[selected]?.van !== vanType)
            return Swal.fire({
              item: 'error',
              text: 'van사가 동일하지 않아서 환불이 불가합니다.',
            });

          if (lists[selected]?.van === 'KIS')
            payCancel(merchantId, {
              tid: lists[selected]?.id,
              isVan: true,
            });
        }
      });
    } else {
      if (lists[selected]?.paymentType === 'cash') {
        Swal.fire({
          showCancelButton: true,
          confirmButtonColor: '#04c9db',
          cancelButtonColor: '#F53C14',
          confirmButtonText: '확인',
          cancelButtonText: '취소',
          reverseButtons: true,
          title: '현금결제매출취소',
          html: `현금결제매출을 취소하시겠습니까?`,
        }).then(async (result) => {
          if (result.isConfirmed) {
            logCancelFunc();
          }
        });
      } else if (['hecto', 'kspay'].includes(lists[selected]?.paymentType)) {
        Swal.fire({
          showCancelButton: true,
          confirmButtonColor: '#04c9db',
          cancelButtonColor: '#F53C14',
          confirmButtonText: '확인',
          cancelButtonText: '취소',
          reverseButtons: true,
          title: '백오피스 결제취소',
          html: `백오피스 결제를 취소하시겠습니까?`,
        }).then(async (result) => {
          if (result.isConfirmed) {
            try {
              const paymentType = lists[selected]?.paymentType;
              let refundResult;
              if (paymentType === 'hecto')
                refundResult = await webpayRefund({
                  transactionId: lists[selected]?.id,
                  merchantId: lists[selected]?.merchantId,
                });
              if (paymentType === 'kspay')
                refundResult = await postTicketKspayRefund(lists[selected]?.id);
              Swal.fire({
                icon: 'success',
                title: '환불이 완료되었습니다',
                timer: 5000,
              });
            } catch (error) {
              Swal.fire({
                icon: 'error',
                title: '환불하는 도중 에러가 발생했습니다.',
                timer: 5000,
              });
            }
          }
        });
      } else if (lists[selected]?.paymentType === 'virtual') {
        Swal.fire({
          showCancelButton: true,
          confirmButtonColor: '#04c9db',
          cancelButtonColor: '#F53C14',
          confirmButtonText: '확인',
          cancelButtonText: '취소',
          reverseButtons: true,
          title: '가매입/가매출 결제매출취소',
          html: `가매입/가매출을 취소하시겠습니까?`,
        }).then(async (result) => {
          if (result.isConfirmed) {
            payLogCancel('virtual');
          }
        });
      } else {
        Swal.fire({
          showCancelButton: true,
          confirmButtonColor: '#04c9db',
          cancelButtonColor: '#F53C14',
          confirmButtonText: '확인',
          cancelButtonText: '취소',
          reverseButtons: true,
          title: '카드결제를 취소하시겠습니까?',
          html: `카드결제취소시 키오스크에서 환불처리가 진행됩니다.`,
        }).then(async (result) => {
          if (!result.isConfirmed) return;
          // van이 존재하면서 van 타입이 다를경우
          if (lists[selected]?.van && lists[selected]?.van !== vanType)
            return Swal.fire({
              item: 'error',
              text: 'van사가 동일하지 않아서 환불이 불가합니다.',
            });

          if (vanType === 'KIS')
            payCancel(merchantId, {
              tid: lists[selected]?.id,
              isVan: false,
            });
          else payCancel(merchantId, lists[selected]?.id);
        });
      }
    }
  };

  const searchDataFunc = () => {
    if (searchKeyword !== null) {
      const filterData = lists.filter((list) => list?.phoneNum?.includes(searchKeyword));
      setSearchData(filterData);
      setCurrentPage(1);
    } else {
      setSearchData([]);
      setCurrentPage(1);
    }
  };

  const exportExcelData = async () => {
    // const data = await getPaylogList(
    //   merchantId,
    //   moment(startDate).format("YYYY-MM-DD 00:00:00"),
    //   moment(endDate).format("YYYY-MM-DD 23:59:59"),
    //   1,
    //   totalLength,
    //   searchKeyword
    // );
    // if (data.status === 200) {
    let excelPayData = [
      searchData,
      searchData.map((item) => {
        item.p_type =
          item.type === 1
            ? '일회권'
            : item.type === 2
            ? '기간권'
            : item.type === 3
            ? '시간정액권'
            : item.type === 4
            ? '세미나실'
            : item.type === 5
            ? '사물함'
            : item.meta8?.includes('자판기')
            ? '자판기'
            : item.meta8?.includes('컵코인')
            ? '컵코인'
            : item.meta8?.includes('백오피스')
            ? '백오피스'
            : item.meta8?.includes('이전 데이터')
            ? '이전 데이터'
            : '-';
        item.createdAt = moment(item.createdAt).format('YYYY-MM-DD HH:mm:ss');
        item.addMileage = numberWithCommas(item?.amount1);
        item.minusMileage = numberWithCommas(Math.abs(+item?.amount2));
        item.price = item.amount === null ? null : `${numberWithCommas(item?.amount)}`;
        item.cancel = item.paymentType?.includes('cancel') ? '결제완료(환불완료)' : '결제완료';
      }),
    ];
    if (excelPayData) {
      exportExcel(excelPayData[0], startDate, endDate, '결제내역');
    }
  };

  const showInputModal = () => {
    setIsInputPhoneNum(true);
  };

  const closeInputModal = () => {
    setIsInputPhoneNum(false);
  };

  return (
    <Fragment>
      {detailUserModal && (
        <DetailsModal
          isOpen={detailUserModal}
          close={showDetailModal}
          seat={userData}
          back={true}
        />
      )}
      {isInputPhoneNum && (
        <InputPhoneNumModal
          close={closeInputModal}
          userPhoneData={lists[selected]?.phoneNum}
          merchantId={lists[selected]?.merchantId}
          transactionId={lists[selected]?.id}
          userId={lists[selected]?.userId}
        />
      )}
      <Breadcrumb parent="결제정보 조회" title="결제내역" />

      <Modal centered isOpen={tagModal} toggle={tagToggle}>
        <ModalHeader className="modal-title" toggle={tagToggle}>
          {'결제정보'}
        </ModalHeader>
        <ModalBody>
          <div>
            <p>
              <strong>결제수단</strong>: {paymentTypeConversionsKr(lists[selected]?.paymentType)}
            </p>
            <p>
              <strong>결제내역</strong>: {lists[selected]?.price}
            </p>
            <p>
              <strong>거래고유번호</strong>: {lists[selected]?.meta1}
            </p>
            <p>
              <strong>승인번호</strong>: {lists[selected]?.meta2}
            </p>
            <p>
              <strong>카드번호</strong>: {lists[selected]?.meta3}
            </p>
            <p>
              <strong>전표번호</strong>: {lists[selected]?.meta4}
            </p>
            <p>
              <strong>카드종류</strong>: {lists[selected]?.meta5}
            </p>
            <p>
              <strong>발급사</strong>: {lists[selected]?.meta6}
            </p>
            <p>
              <strong>거래유형</strong>: {lists[selected]?.meta7}
            </p>
            <p>
              <strong>내부메시지</strong>:{lists[selected]?.meta8}
            </p>
            <p>
              <strong>매입사</strong>: {lists[selected]?.meta9}
            </p>
            <p>
              <strong>응답정보</strong>: {lists[selected]?.meta10}
            </p>
            <p>
              <strong>거래일자</strong>: {lists[selected]?.meta11}
            </p>
            <p>
              <strong>가맹점번호</strong>: {lists[selected]?.meta12}
            </p>
            <p>
              <strong>표시메시지</strong>: {lists[selected]?.meta13}
            </p>
            <p>
              <strong>결제일</strong>:{' '}
              {moment(lists[selected]?.updatedAt).utcOffset('+0900').format('YYYY-MM-DD HH:mm:ss')}
            </p>
            <ButtonWrapper>
              {lists[selected]?.paymentType.includes('cancel') ||
              lists[selected]?.isCancel === 1 ? (
                <>
                  <button className="btn btn-light btn-sm disabled">
                    {lists[selected]?.paymentType.includes('cash')
                      ? '현금 매출 취소하기'
                      : lists[selected]?.paymentType.includes('toss')
                      ? '백오피스 매출 취소하기'
                      : lists[selected]?.paymentType.includes('virtual')
                      ? '가매입/가매출 취소하기'
                      : '카드 매출 취소하기'}
                  </button>
                  {lists[selected]?.van === 'KIS' && (
                    <button className="btn btn-light btn-sm disabled">원격 결제 취소하기</button>
                  )}
                </>
              ) : (
                <>
                  <button onClick={() => getCancle()} className="btn btn-danger btn-sm">
                    {lists[selected]?.paymentType === 'cash'
                      ? '현금 매출 취소하기'
                      : lists[selected]?.paymentType === 'toss'
                      ? '백오피스 매출 취소하기'
                      : lists[selected]?.paymentType === 'virtual'
                      ? '가매입/가매출 취소하기'
                      : '카드 매출 취소하기'}
                  </button>
                  {lists[selected]?.van === 'KIS' && (
                    <button onClick={() => getCancle(true)} className="btn btn-danger btn-sm">
                      원격 환불 하기
                    </button>
                  )}
                </>
              )}

              <button
                className="btn btn-sm"
                style={{ backgroundColor: '#282b46', color: '#fff' }}
                onClick={() => recetipReprint(merchantId, lists[selected]?.id)}>
                영수증 재출력
              </button>
              <button
                className="btn btn-sm"
                style={{ backgroundColor: '#282b46', color: '#fff' }}
                onClick={() => showInputModal()}>
                영수증 재발급
              </button>
            </ButtonWrapper>
          </div>
        </ModalBody>
      </Modal>
      <PageContainer
        title="결제 내역"
        itemsRightSideTitle={[<ExcelButton onClick={() => exportExcelData()} />]}>
        <TableHeaderContainerV2>
          <DatePickerComponent
            withCalendar
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            startMaxDate={endDate}
            endMinDate={startDate}
          />
          {/* <DatePickerStyle>
              <DatePicker
                selected={startDate}
                onChange={(date) => {
                  setStartDate(date);
                }}
                selectsStart
                locale={ko}
                startDate={startDate}
                maxDate={endDate}
                endDate={endDate}
                customInput={<ExampleCustomInput />}
              />
              <h5> - </h5>
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                selectsEnd
                locale={ko}
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                customInput={<ExampleCustomInput />}
              />
              <SelectDate
                setStartDate={(val) => setStartDate(val)}
                setEndDate={(val) => setEndDate(val)}
                nowTime={'이번 주'}
              />
            </DatePickerStyle> */}
          <PerPageSelect onChange={setPostsPerPage} />
          <Search
            onSubmit={searchDataFunc}
            searchKeyword={searchKeyword}
            setSearchKeyword={handleSearchKeyword}
          />
          {/* <Form
              onSubmit={(e) => {
                e.preventDefault();
                searchDataFunc();
              }}>
              <LabelRow className="m-0">
                <Input
                  style={{ margin: 0 }}
                  placeholder="전화번호를 입력해주세요"
                  className="form-control"
                  type="text"
                  defaultValue={searchKeyword}
                  onChange={(e) => handleSearchKeyword(e.target.value)}
                />

                <SearchButton onClick={() => searchDataFunc()} />
              </LabelRow>
            </Form> */}
          {/* <FlexCenterV gap={1}>
              
            </FlexCenterV> */}
        </TableHeaderContainerV2>
        <Table
          data={currentPosts(searchData)}
          columns={paylogListColumns}
          striped={true}
          center={true}
        />

        {searchData.length > 0 && (
          <PaginationComponent
            setCurrentPage={setCurrentPage}
            setPostsPerPageAdd={setPostsPerPageAdd}
            setPostsPerPageAdd1={setPostsPerPageAdd1}
            setPageNum={setPageNum}
            totalLength={searchData.length}
            postsPerPage={postsPerPage}
            currentPage={currentPage}
            pageNum={pageNum}
            postsPerPageAdd={postsPerPageAdd}
            postsPerPageAdd1={postsPerPageAdd1}
          />
        )}
      </PageContainer>
    </Fragment>
  );
};

export default SalesInfo;
