import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Input } from 'reactstrap';
import styled from 'styled-components';
import Swal from 'sweetalert2';

import PaginationComponent from '../components/pagination';
import { noticeColumns } from '../data/ticketList';
import {
  announcementCreate,
  announcementDelete,
  announcementRead,
  announcementUpdate,
} from '../lib/api';
import Table from '../components/common/Table';

export const InputBox = styled.div`
  p {
    float: left;
  }
  textarea {
    width: 100%;
    height: 40vw;
    resize: none;
  }
`;

export const MainTextContainer = styled.div`
  border-top: 2px solid #222222;
  border-bottom: 2px solid #222222;
  margin: 2vw;
  .title {
    border-bottom: 1px solid #e2e2e2;
    p:first-child {
      font-size: 20px;
      font-weight: 700;
    }
    span {
      margin: 0 2vw;
    }
  }

  .text {
    text-align: left;
    padding: 10px;
    min-height: 30vw;
    margin: 2vw 0;
  }
  ol {
    display: block;
  }
`;

const EditorContainer = styled.div`
  p {
    text-align: left;
  }
  ol {
    display: block;
  }
`;
const NoticeBoard = () => {
  const [isWrite, setIsWrite] = useState(false);
  const [isShowText, setIsShowText] = useState(false);
  const [title, setTitle] = useState('');
  const [mainText, setMainText] = useState('');
  const [isAmend, setIsAmend] = useState(false);
  const [textLength, setTextLength] = useState(0);
  const [titleLength, setTitleLength] = useState(0);
  const [noticeList, setNoticeList] = useState([]);
  const [detailData, setDetailData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);
  const [amendText, setAmendText] = useState({});
  const indexOfLast = currentPage * postsPerPage;
  const indexOfFirst = indexOfLast - postsPerPage;

  const getNoticeData = async () => {
    let getData = await announcementRead('admin');

    setNoticeList(
      getData.data,
      getData.data.map((item, index) => {
        item.create = moment(item.createdAt).format('YYYY-MM-DD');
        item.idx = index + 1;
        item.titleData = (
          <span style={{ cursor: 'pointer' }} onClick={() => showData(item)}>
            {item.title}
          </span>
        );
      }),
    );
  };

  const showData = (item) => {
    setIsShowText(true);
    let itemData = item.text
      .replace(/&amp;/g, '&')
      .replace(/&lt;/g, '<')
      .replace(/&gt;/g, '>')
      .replace(/&quot;/g, '"')
      .replace(/&#039;/g, "'")
      .replace(/&#39;/g, "'");
    setDetailData({ ...item, text: itemData });
  };

  const writeMainText = (e) => {
    if (e.target.name === 'main') {
      let changeData = e.target.value.replace('\r\n', '<br>');
      if (changeData.length < 1001) {
        setMainText(changeData);
        setTextLength(changeData.length);
      } else {
        Swal.fire({
          text: '본문내용은 1000자를 초과하여 작성할 수 없습니다.',
          timer: 2000,
        });
      }
    } else {
      if (e.target.value.length < 51) {
        setTitle(e.target.value);
        setTitleLength(e.target.value.length);
      } else {
        Swal.fire({
          text: '제목은 50자를 초과하여 작성할 수 없습니다.',
          timer: 2000,
        });
      }
    }
  };

  const amendMainText = (e) => {
    if (e.target.name === 'main') {
      let changeData = e.target.value.replace('\r\n', '<br>');
      if (changeData.length < 1001) {
        setAmendText({ ...amendText, text: changeData });
      } else {
        Swal.fire({
          text: '본문내용은 1000자를 초과하여 작성할 수 없습니다.',
          timer: 2000,
        });
      }
    } else {
      if (e.target.value.length < 51) {
        setAmendText({ ...amendText, title: e.target.value });
      } else {
        Swal.fire({
          text: '제목은 50자를 초과하여 작성할 수 없습니다.',
          timer: 2000,
        });
      }
    }
  };

  const exitEditer = () => {
    Swal.fire({
      title: '뒤로가기',
      text: '뒤로가실경우 입력한 내용이 초기화 됩니다. 계속 하시겠습니까?',
      showCancelButton: true,
      confirmButtonColor: '#F53C14',
      cancelButtonColor: '#04c9db',
      confirmButtonText: '뒤로가기',
      cancelButtonText: '취소',
    }).then(async (result) => {
      if (result.isConfirmed) {
        setIsWrite(false);
        setTitle('');
        setMainText('');
      }
    });
  };

  const createNotice = async () => {
    if (title.length === 0) {
      Swal.fire({ text: '제목을 입력해주세요', timer: 3500 });
    } else if (mainText.length === 0) {
      Swal.fire({ text: '내용을 입력해주세요', timer: 3500 });
    } else {
      const result = await announcementCreate({ title: title, text: mainText });

      if (result.status === 200) {
        Swal.fire({
          text: '공지사항 등록이 완료되었습니다.',
          timer: 3000,
        }).then(() => setIsWrite(false), setTitle(''), setMainText(''));
      }
    }
  };

  const deletePost = () => {
    Swal.fire({
      title: '삭제하기',
      text: '공지사항을 삭제하시겠습니까?',
      showCancelButton: true,
      confirmButtonColor: '#F53C14',
      cancelButtonColor: '#04c9db',
      confirmButtonText: '삭제하기',
      cancelButtonText: '취소',
    }).then(async (result) => {
      if (result.isConfirmed) {
        let result = await announcementDelete(detailData.id);
        if (result.status === 200) {
          Swal.fire({ text: '공지사항이 삭제되었습니다.' }).then(
            () => setIsWrite(false),
            setIsAmend(false),
            setIsShowText(false),
            setTitle(''),
            setMainText(''),
            setTextLength(0),
            setTitleLength(0),
            setAmendText({}),
            setDetailData({}),
          );
        }
      }
    });
  };

  const currentPosts = (tmp) => {
    let currentPosts = 0;
    currentPosts = tmp.slice(indexOfFirst, indexOfLast);
    return currentPosts;
  };

  const amendPost = () => {
    setIsAmend(true);
    let textData = detailData.text
      .replace(/&amp;/g, '&')
      .replace(/&lt;/g, '<')
      .replace(/&gt;/g, '>')
      .replace(/&quot;/g, '"')
      .replace(/&#039;/g, "'")
      .replace(/&#39;/g, "'");
    setAmendText({ ...detailData, text: textData });
  };

  const overwrite = async () => {
    if (amendText.title.length === 0) {
      Swal.fire({ text: '제목을 입력해주세요', timer: 3500 });
    } else if (amendText.text.length === 0) {
      Swal.fire({ text: '내용을 입력해주세요', timer: 3500 });
    } else {
      let result = await announcementUpdate({
        title: amendText.title,
        text: amendText.text,
        id: amendText.id,
      });

      if (result.status === 200) {
        Swal.fire({
          text: '수정이 완료되었습니다.',
          timer: 3000,
        }).then(
          () => setIsWrite(false),
          setIsAmend(false),
          setIsShowText(false),
          setTitle(''),
          setMainText(''),
          setTextLength(0),
          setTitleLength(0),
          setAmendText({}),
          setDetailData({}),
        );
      }
    }
  };

  const cancelAmend = () => {
    Swal.fire({
      title: '취소하기',
      text: '취소하실경우 수정한 내용이 초기화 됩니다. 계속 하시겠습니까?',
      showCancelButton: true,
      confirmButtonColor: '#F53C14',
      cancelButtonColor: '#04c9db',
      confirmButtonText: '수정취소하기',
      cancelButtonText: '취소',
    }).then(async (result) => {
      if (result.isConfirmed) {
        setIsWrite(false);
        setIsAmend(false);
        setIsShowText(false);
        setTitle('');
        setMainText('');
        setTextLength(0);
        setTitleLength(0);
        setAmendText({});
        setDetailData({});
      }
    });
  };

  useEffect(() => {
    getNoticeData();
  }, [isWrite, isAmend, isShowText]);

  return (
    <>
      {!isWrite && !isShowText && (
        <>
          <button
            style={{ margin: '1vw', float: 'right' }}
            className="btn-success btn-sm"
            onClick={() => setIsWrite(true)}>
            글쓰기
          </button>
          <Table
            data={currentPosts(noticeList)}
            columns={noticeColumns}
            striped={true}
            center={true}
          />

          {noticeList.length > 0 && (
            <PaginationComponent
              setCurrentPage={setCurrentPage}
              totalLength={noticeList.length}
              postsPerPage={postsPerPage}
              currentPage={currentPage}
            />
          )}
        </>
      )}
      {detailData && isShowText && !isWrite && !isAmend && (
        <>
          <MainTextContainer>
            <div className="title">
              <p>{detailData.title}</p>
              <p>
                <span>작성일 | {detailData.create}</span>
                <span>작성자 | 샘랩</span>
              </p>
            </div>
            <div>
              <div
                className="text"
                dangerouslySetInnerHTML={{
                  __html: detailData.text,
                }}></div>
            </div>
          </MainTextContainer>
          <div className="btns" style={{ float: 'right', marginRight: '30px' }}>
            <button
              className="btn-success btn-sm"
              onClick={() => {
                setIsShowText(false);
              }}>
              뒤로가기
            </button>
            <button
              className="btn-primary btn-sm"
              style={{ margin: '0 2vw' }}
              onClick={() => {
                amendPost();
              }}>
              수정하기
            </button>
            <button className="btn-secondary btn-sm" onClick={() => deletePost()}>
              삭제하기
            </button>
          </div>
        </>
      )}
      {isAmend && (
        <>
          <h2>게시물 수정</h2>
          <InputBox>
            <p>
              제목 <span>({amendText.title.length}/50)</span>
            </p>
            <Input name="title" value={amendText.title} onChange={(e) => amendMainText(e)} />
          </InputBox>
          <EditorContainer style={{ display: 'block' }}>
            <p>
              내용 <span>({amendText.text.length}/1000)</span>
            </p>

            <CKEditor
              editor={ClassicEditor}
              id="editor"
              data={amendText.text}
              config={{
                fullPage: true,
                allowedContent: true,
                autoGrow_onStartup: true,
                htmlEncodeOutput: false,
                autoParagraph: false,
                placeholder: '내용을 입력하세요.',
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                setAmendText({ ...amendText, text: data });
              }}
            />
          </EditorContainer>
          <button className="btn btn-primary" onClick={() => overwrite()}>
            수정하기
          </button>
          <button className="btn btn-secondary" onClick={() => cancelAmend()}>
            취소하기
          </button>
        </>
      )}
      {isWrite && (
        <>
          <h2>게시물 작성</h2>
          <InputBox>
            <p>
              제목 <span>({titleLength}/50)</span>
            </p>
            <Input name="title" value={title} onChange={(e) => writeMainText(e)} />
          </InputBox>

          <EditorContainer style={{ display: 'block' }}>
            <p>
              내용 <span>({mainText.length}/1000)</span>
            </p>

            <CKEditor
              editor={ClassicEditor}
              config={{
                placeholder: '내용을 입력하세요.',
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                setMainText(data);
              }}
            />
          </EditorContainer>
          <button className="btn btn-primary" onClick={() => createNotice()}>
            등록하기
          </button>
          <button className="btn btn-secondary" onClick={() => exitEditer()}>
            뒤로가기
          </button>
        </>
      )}
    </>
  );
};

export default NoticeBoard;
