import React, { Fragment, useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardHeader, CardBody } from 'reactstrap';
import { options } from '../storeOverview';
import Swal from 'sweetalert2';
import { decryptFunc, numberWithCommas } from '../common';
import {
  getLockerList,
  getSeatList,
  getUserData,
  getUserTicketList,
  getUserQrCode,
  userLog,
  getPaylogList,
  getMileageLog,
  ticketExit,
} from '../../lib/api';
import { List, User } from 'react-feather';
import styled from 'styled-components';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import PaginationComponent from '../pagination';
import { secondToHour } from '../../pages/ticketList';
import { userLogColumn, UserpaylogListColumns2, UserTicketListColumn } from '../../data/ticketList';
import Barcode from 'react-barcode';

import BarcodeIcon from '../../assets/images/barcode.svg';
import ReactApexChart from 'react-apexcharts';
import { Cookies } from 'react-cookie';
import ChannelService from './channelTalk';
import { getInfo } from '../../lib/api/v2/user';
import Table from '../common/Table';

const TopContainer = styled.div`
  display: flex;
  align-items: center;
  p {
    font-size: 16px;
    margin-bottom: 0;
    margin-left: 10px;
    font-weight: 500;
  }
  > svg {
    width: 20px;
  }
`;

const DataContainer = styled.div`
  .datachart {
    display: flex;
    align-items: center;
    margin-top: -30px;
    margin-left: -25px;
    p {
      margin-bottom: 0;
      margin-left: 1vw;
    }
  }
  @media screen and (max-width: 768px) {
  }
`;

const DashboardStyle = styled.div`
  width: 100%;
  padding: 2.5rem;
  .userData {
    margin-bottom: 1rem;
  }
  .flex_container {
    width: 100%;
    display: flex;
    height: 93%;
    justify-content: space-between;
    div.card {
      height: 93%;
    }
    .col-sm-6 {
      max-width: 49%;
      padding: 0;
    }
  }
  .col-sm-12 {
    max-width: 100%;
    padding: 0;
    flex: 1;
  }

  @media screen and (max-width: 768px) {
    padding: 1.5rem;
    .flex_container {
      flex-direction: column;
      .col-sm-6 {
        max-width: 100%;
      }
    }
    .col-sm-8 {
      max-width: 100%;
      flex: 1;
    }
    .userData {
    }
    .myProfile {
    }
  }
`;

const Dashboard = (props) => {
  const [userInfoData, setUserInfoData] = useState();

  const [startDate, setStartDate] = useState(moment(new Date()).subtract(40, 'y')._d);
  const [endDate, setEndDate] = useState(new Date());
  const [lists, setTicketLists] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(5);
  const [currentPage1, setCurrentPage1] = useState(1);
  const [postsPerPage1, setPostsPerPage1] = useState(5);
  const [currentPage2, setCurrentPage2] = useState(1);
  const [postsPerPage2, setPostsPerPage2] = useState(5);
  const [total, setTotal] = useState(0);
  const [barcode, setBarcode] = useState('');
  const [seatShare, setSeatShare] = useState();
  const [lockerShare, setLockerShare] = useState(0);
  const [seatLength, setSeatLength] = useState(0);
  const [occData, setOccData] = useState(0);
  const [usingLocker, setUsingLocker] = useState(0);
  const [lockerLength, setLockerLength] = useState(0);
  const [userInOutData, setUserInOutData] = useState([]);
  const [totalCount, setTotalcount] = useState([]);
  const [payLists, setPayLists] = useState([]);
  const [totalLength, setTotalLength] = useState(0);
  const [totalMileage, setTotalMileage] = useState(0);

  const [isReload, setIsReload] = useState(false);

  const userData = async () => {
    try {
      const { data } = await getInfo();
      const { merchantId, phoneNum, qrCode } = data;
      setUserInfoData(data);
      getUserTicketListApi(merchantId, phoneNum);
      setBarcode(qrCode);
      seatData(merchantId);
      LockerData(merchantId);
      getUserInfoData(merchantId, phoneNum);
      getPaylogListApi(merchantId, phoneNum);
      getTotalMileage(merchantId, phoneNum);
    } catch (error) {}
  };

  const getUserTicketListApi = async (merchantId, phoneNum) => {
    if (merchantId) {
      const data = await getUserTicketList(
        merchantId,
        moment(startDate).format('YYYYMMDD000000'),
        moment(endDate).format('YYYYMMDD235959'),
        currentPage,
        postsPerPage,
        phoneNum,
      );

      if (data.status === 200) {
        setTotal(data.data.count);
        const changeList = [
          data.data.result,
          data.data.result.map((item) => {
            item.typeName =
              item.type === 1
                ? ` 일회권`
                : item.type === 2
                ? ` 기간권 - ${item.seatType === 0 ? '자유석' : '고정석'}`
                : item.type === 3
                ? ` 시간정액권`
                : item.type === 4
                ? ' 세미나실'
                : '사물함';

            item.type1 =
              item.type === 1 ? (
                <p style={{ color: '#EBAF22' }}>일회권</p>
              ) : item.type === 2 ? (
                <>
                  {item.seatType === 0 ? (
                    <p style={{ color: '#E03D64' }}>기간권 - 자유석</p>
                  ) : (
                    <p style={{ color: '#1BBABC' }}>기간권 - 고정석</p>
                  )}
                </>
              ) : item.type === 3 ? (
                <p style={{ color: '#33946B' }}>시간정액권</p>
              ) : item.type === 4 ? (
                <p style={{ color: '#3E67B6' }}>세미나실</p>
              ) : (
                <p style={{ color: '#AB84FF' }}>사물함</p>
              );

            item.lastIn = item.lastIn ? moment(item.lastIn).format('YYYY-MM-DD HH:mm:ss') : '';

            item.startDate = item.startDate
              ? moment(item.startDate).format('YYYY-MM-DD HH:mm:ss')
              : '';

            const t1 = moment(item.endDate, 'YYYY-MM-DD HH:mm:ss');
            const t2 = moment(item.startDate, 'YYYY-MM-DD HH:mm:ss');
            const t3 = moment(new Date(), 'YYYY-MM-DD HH:mm:ss');
            const diffTime = {
              years: moment.duration(moment(t1).diff(t2)).years(),
              month: moment.duration(moment(t1).diff(t2)).months(),
              day: moment.duration(moment(t1).diff(t2)).days(),
              hours: moment.duration(moment(t1).diff(t2)).hours(),
              minutes: moment.duration(moment(t1).diff(t2)).minutes(),
              seconds: moment.duration(moment(t1).diff(t2)).seconds(),
            };

            item.action =
              +item.type === 2 && +item.seatType === 0
                ? secondToHour(item.remainTime)
                : secondToHour(item.remainTime + item.usedTime);

            item.expireDate = item.type === 3 ? `${diffTime.day} 일` : '-';
            item.expireDate2 =
              (item.type === 3 || item.type === 5) &&
              Math.ceil(moment.duration(moment(t1).diff(t3)).asDays()) > 0
                ? `${Math.ceil(moment.duration(moment(t1).diff(t3)).asDays())}일`
                : '-';
            item.T_remainTime =
              +item.type === 2 && +item.seatType === 0
                ? secondToHour(item.remainTime - item.usedTime)
                : secondToHour(item.remainTime);
            item.T_isUsing =
              item.isUsing === 1 ? (
                <div>사용중</div>
              ) : item.isUsing === 2 ? (
                <div style={{ color: '#F04E36' }}>시간 초과</div>
              ) : item.lastOut ? (
                <div style={{ color: '#53852A' }}>퇴장완료</div>
              ) : (
                <div>사용전</div>
              );
            item.blockKeyword = item.block === 1 ? '사용불가' : '사용가능';
            item.T_block =
              item.block === 1 ? <p style={{ color: '#F04E36' }}>사용불가</p> : <p>사용가능</p>;

            item.isUsingKeyword =
              item.isUsing === 1
                ? '강제퇴장처리'
                : item.isUsing === 2
                ? '초과결제해제'
                : '퇴장처리';
            item.last2 = item.lastOut ? moment(item.lastOut).format('YYYY-MM-DD HH:mm:ss') : '';
            const t11 = moment();
            const t22 = moment(item.endDate);
            const t23 = moment(item.startDate);
            const t33 = moment(item.lastIn);
            const t44 = moment(item.lastOut);
            const today_diff = moment.duration(t22.diff(t11)).asMilliseconds();
            //현재시간기준으로 시작시간하고의 차이
            const today_diff2 = moment.duration(t11.diff(t23)).asMilliseconds();
            const remainTimeData = moment.duration(t11.diff(t33)).asSeconds();
            const seminarNotUsing = moment.duration(t22.diff(t11)).asSeconds();
            const excessData = moment.duration(t11.diff(t44)).asSeconds();
            //잔여시간 조회
            item.remainTime2 =
              // 일회권, 기간권, 시간정액권 사용 중이면서, 퇴장 기록이 없는 경우
              item.isUsing === 1 &&
              !item.lastOut &&
              item.lastIn &&
              (item.type === 1 || item.type === 2 || item.type === 3)
                ? secondToHour(item.remainTime - Math.ceil(remainTimeData))
                : // 세미나실인 경우 (입실 처리x, 시간 초과x)
                item.type === 4 && today_diff > 0 && today_diff2 > 0
                ? secondToHour(Math.ceil(seminarNotUsing))
                : // 세미나실인 경우 (입실 처리x, 시간 초과o)
                item.type === 4 && today_diff < 0 && today_diff2 > 0
                ? '00:00:00'
                : // 일회권, 기간권, 시간정액권 사용 중이면서 시간을 초과한 경우
                item.isUsing === 2 && item.lastOut
                ? `${secondToHour(+Math.ceil(excessData))} 초과`
                : // 그외에 조건
                item.type === 2 && item.endDate
                ? `${moment(item.endDate).diff(moment(), 'days') + 1}일 남음`
                : item.type === 4 && item.endDate
                ? `${moment(item.endDate).diff(moment(), 'days') + 1}일 남음`
                : item.type === 5 && item.endDate
                ? `${moment(item.endDate).diff(moment(), 'days') + 1}일 남음`
                : secondToHour(item.remainTime);

            // 퇴장처리 버튼
            item.exitBtn =
              item.isUsing === 1 ? (
                <button className="btn-secondary btn-sm" onClick={() => userTicketExit(item)}>
                  퇴장하기
                </button>
              ) : item.isUsing === 2 ? (
                <button className="btn-secondary btn-sm" onClick={() => overTimeExit(item.isUsing)}>
                  퇴장하기
                </button>
              ) : (
                <button className="btn-sm" disabled>
                  퇴장하기
                </button>
              );
          }),
        ];

        setTicketLists(changeList[0]);
        setIsReload(false);
      }
    }
  };

  //사용자 퇴장처리
  const userTicketExit = (ticketData) => {
    if (ticketData.isUsing === 1) {
      Swal.fire({
        showCancelButton: true,
        confirmButtonColor: '#04c9db',
        cancelButtonColor: '#F53C14',
        confirmButtonText: '네',
        cancelButtonText: '아니오',
        reverseButtons: true,
        text: '퇴장하시겠습니까?',
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            let exitRes = await ticketExit({
              merchantId: ticketData.merchantId,
              id: ticketData.id,
              userId: ticketData.userId,
            });

            if (exitRes.status === 200) {
              Swal.fire('퇴장처리가 완료되었습니다.').then(() => setIsReload(true));
            } else if (exitRes.status === 400) {
              Swal.fire('퇴장처리 중 에러가 발생했습니다.');
            } else {
              Swal.fire('퇴장처리 중 에러가 발생했습니다.');
            }
          } catch (error) {
            console.log(error);
            Swal.fire('퇴장처리 중 에러가 발생했습니다.');
          }
        }
      });
    }
  };

  //초과결제(isUsing===2)
  const overTimeExit = (isUsingStatus) => {
    if (isUsingStatus === 2) {
      Swal.fire('초과 결제가 필요한 입장권은\n웹에서 퇴장이 불가능 합니다.');
    }
  };

  const seatData = async (e) => {
    let data = await getSeatList(e);
    if (data.status === 200) {
      setSeatLength(data.data.length);
      setOccData(data.data.filter((item) => item.isUsing === 1).length);
      setSeatShare(
        Math.floor(
          (data.data.filter((item) => item.isUsing === 1).length / data.data.length) * 100,
        ),
      );
    }
  };

  const LockerData = async (e) => {
    let data = await getLockerList(e);

    if (data.status === 200) {
      setLockerLength(data.data.length);
      setUsingLocker(data.data.filter((item) => item.userId).length);
      setLockerShare(
        Math.floor((data.data.filter((item) => item.userId).length / data.data.length) * 100),
      );
    }
  };
  const getUserInfoData = async (i, n) => {
    const userInfoDataResult = await userLog(
      i,
      moment(endDate).subtract(1, 'M').format('YYYYMMDD000000'),
      moment(endDate).format('YYYYMMDD235959'),
      currentPage1,
      postsPerPage1,
      n,
    );

    if (userInfoDataResult.status === 200) {
      setUserInOutData([
        ...userInfoDataResult.data.result.map((item, index) => ({
          idx: item.id,
          id: item.id * index,
          create: moment(item.updatedAt).format('YYYY-MM-DD HH:mm:ss'),
          seatNumber1: item.seatNumber + '번',
        })),
      ]);
      setTotalcount(userInfoDataResult.data.count);
    }
  };

  const getPaylogListApi = async (id, phoneNum) => {
    if (id) {
      const { data } = await getPaylogList(
        id,
        moment(endDate).subtract(1, 'M').format('YYYY-MM-DD 00:00:00'),
        moment(endDate).format('YYYY-MM-DD 23:59:59'),
        currentPage2,
        postsPerPage2,
        phoneNum,
      );
      setTotalLength(data.count);
      if (data.info.length > 0) {
        setPayLists([
          ...data.info.map((item) => ({
            ...item,
            p_type:
              item.type === 1 ? (
                <p style={{ color: '#EBAF22' }}>일회권</p>
              ) : item.type === 2 ? (
                <p style={{ color: '#E03D64' }}>기간권</p>
              ) : item.type === 3 ? (
                <p style={{ color: '#33946B' }}>시간정액권</p>
              ) : item.type === 4 ? (
                <p style={{ color: '#3E67B6' }}>세미나실</p>
              ) : item.type === 5 ? (
                <p style={{ color: '#AB84FF' }}>사물함</p>
              ) : item.meta8?.includes('자판기') ? (
                <p>자판기</p>
              ) : item.meta8?.includes('컵코인') ? (
                <p>컵코인</p>
              ) : item.meta8?.includes('백오피스') ? (
                <p>백오피스</p>
              ) : (
                <p>-</p>
              ),

            createdAt: moment(item.createdAt).format('YYYY-MM-DD HH:mm:ss'),

            price: numberWithCommas(item.amount),
            cancel: item.paymentType?.includes('cancel') ? (
              <p
                style={{
                  textDecorationLine: 'line-through',
                  color: '#E00F2C',
                }}>
                결제완료(환불완료)
              </p>
            ) : (
              <p>결제완료</p>
            ),
            paymentType: item.paymentType?.includes('cash')
              ? '현금'
              : item.paymentType?.includes('credit')
              ? '카드'
              : item.paymentType?.includes('kakaopay')
              ? '카카오페이'
              : item.paymentType?.includes('payco')
              ? '페이코'
              : item.paymentType?.includes('virtual')
              ? '가매입/가매출'
              : item.paymentType?.includes('coupon')
              ? '쿠폰'
              : item.paymentType?.includes('toss')
              ? '토스'
              : item.paymentType?.includes('applepay')
              ? '애플페이'
              : item.paymentType?.includes('naverpay')
              ? '네이버페이'
              : item.paymentType?.includes('hecto')
              ? '헥토파이낸셜'
              : '마일리지',
          })),
        ]);
      }
    }
  };

  const getTotalMileage = async (id, phoneNum) => {
    let mileageData = await getMileageLog(
      id,
      moment(endDate).subtract(40, 'y').format('YYYY-MM-DD 00:00:00'),
      moment(endDate).format('YYYY-MM-DD 23:59:59'),
      currentPage,
      postsPerPage,
      phoneNum,
    );

    if (mileageData.status === 200) {
      setTotalMileage(mileageData.data.mileage);
    }
  };

  useEffect(() => {
    userData();
  }, [currentPage, currentPage1, currentPage2, isReload]);

  useEffect(() => {
    if (userInfoData) {
      ChannelService.boot({
        pluginKey: 'c7244504-2def-4fd9-b399-3336a3cbcceb', //please fill with your plugin key
        profile: {
          name: userInfoData.name,
          mobileNumber: userInfoData.phoneNum,
          merchant: userInfoData.name,
          phoneNum: userInfoData.phoneNum,
        },
      });
    }
  }, [userInfoData]);

  const series = [
    {
      name: '사용 좌석',
      data: [occData],
    },
    {
      name: '남은 좌석',
      data: [seatLength - occData],
    },
  ];

  const lockerSeries = [
    {
      name: '사용 사물함',
      data: [usingLocker],
    },
    {
      name: '남은 사물함',
      data: [lockerLength - usingLocker],
    },
  ];

  return (
    <Fragment>
      {/* <Breadcrumbs parent="대시보드" title="대시보드" /> */}
      <DashboardStyle>
        <Container fluid={true}>
          <Row>
            <div className="flex_container">
              <Col sm="6">
                <Card>
                  <CardHeader>
                    <TopContainer>
                      <img src={BarcodeIcon} />
                      <p>입장용 바코드</p>
                    </TopContainer>
                  </CardHeader>
                  <CardBody
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}>
                    <Barcode
                      className="barcode"
                      displayValue={false}
                      width={1}
                      fontSize={10}
                      value={barcode}
                    />
                  </CardBody>
                </Card>
              </Col>
              <Col sm="6">
                <Card>
                  <CardHeader>
                    <TopContainer>
                      <User />
                      <p>나의 프로필</p>
                    </TopContainer>
                  </CardHeader>
                  <CardBody className="myProfile">
                    <div className="userData">
                      <p>{userInfoData?.userName || userInfoData?.phoneNum?.slice(-4)}님</p>
                      <p>이용 지점 - {userInfoData?.merchantName}</p>
                      {totalMileage > 0 && <p>마일리지 : {numberWithCommas(totalMileage)}</p>}
                    </div>
                    <DataContainer>
                      <p>좌석 점유율</p>
                      <div className="datachart">
                        <ReactApexChart
                          options={options}
                          series={series}
                          type="bar"
                          width="200"
                          height="60"
                        />

                        <p>
                          {seatShare ? seatShare : 0}%<br />
                          <span>
                            {occData}/{seatLength}
                          </span>
                        </p>
                      </div>

                      <p>사물함 점유율</p>
                      <div className="datachart">
                        <ReactApexChart
                          options={options}
                          series={lockerSeries}
                          type="bar"
                          width="200"
                          height="60"
                        />
                        <p>
                          {lockerShare ? lockerShare : 0}%<br />
                          <span>
                            {usingLocker}/{lockerLength}
                          </span>
                        </p>
                      </div>
                    </DataContainer>
                  </CardBody>
                </Card>
              </Col>
            </div>
            <Col sm="12">
              <Card>
                <CardHeader>
                  <TopContainer>
                    <List />
                    <p>나의 입장권</p>
                  </TopContainer>
                </CardHeader>
                <CardBody>
                  <Table data={lists} columns={UserTicketListColumn} striped={true} center={true} />
                  {lists.length > 0 && (
                    <PaginationComponent
                      setCurrentPage={setCurrentPage}
                      totalLength={total}
                      postsPerPage={postsPerPage}
                      currentPage={currentPage}
                    />
                  )}
                </CardBody>
              </Card>
            </Col>
            <Col sm="12">
              <Card>
                <CardHeader>
                  <TopContainer>
                    <List />
                    <p>나의 최근 한달간 이용내역</p>
                  </TopContainer>
                </CardHeader>
                <CardBody>
                  <Table
                    data={userInOutData}
                    columns={userLogColumn}
                    striped={true}
                    center={true}
                  />
                  {totalCount > 0 && (
                    <PaginationComponent
                      setCurrentPage={setCurrentPage1}
                      totalLength={totalCount}
                      postsPerPage={postsPerPage1}
                      currentPage={currentPage1}
                    />
                  )}
                </CardBody>
              </Card>
            </Col>
            <Col sm="12">
              <Card>
                <CardHeader>
                  <TopContainer>
                    <List />
                    <p>나의 최근 한달간 결제내역</p>
                  </TopContainer>
                </CardHeader>
                <CardBody>
                  <Table
                    data={payLists}
                    columns={UserpaylogListColumns2}
                    striped={true}
                    center={true}
                  />
                  {totalLength > 0 && (
                    <PaginationComponent
                      setCurrentPage={setCurrentPage2}
                      totalLength={totalLength}
                      postsPerPage={postsPerPage2}
                      currentPage={currentPage2}
                    />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </DashboardStyle>
    </Fragment>
  );
};

export default Dashboard;
