import axios, { AxiosInstance } from 'axios';
import {
  apply,
  withAdminTokenAccepted,
  withMerchantTokenAccepted,
  withRefreshTokenAccepted,
  withTokenError,
  withTokenRejected,
  withUserTokenAccepted,
} from '../../interceptors';
import {
  apiWithAdminToken,
  apiWithMerchantToken,
  apiWithMerchantTokenV2,
  apiWithRefreshToken,
  franchiseApiWithAdminToken,
  imageApiWithAdminToken,
  imageApiWithMerchantToken,
} from '../../api';

/**
 * v2 API
 */

const API_URL_V2 = process.env.REACT_APP_API_URL_V2;
const API_IMG_URL = process.env.REACT_APP_API_IMG_URL;

const createInstance = (url?: string, timeout?: number) =>
  axios.create({ baseURL: `${API_URL_V2}${url}`, timeout: timeout || 30000 });
const createImageInstance = (url?: string, timeout?: number) =>
  axios.create({ baseURL: `${API_IMG_URL}${url}`, timeout: timeout || 30000 });

const auth = createInstance(`/auth`);
const seat = createInstance(`/seat`);
const user = createInstance(`/user`);
const ticket = createInstance(`/ticket`);

const authWithRefreshToken = createInstance('/auth');
export const authWithUserToken = createInstance('/auth');
const authWithAdminToken = createInstance('/auth');
export const couponWithAdminToken = createInstance('/admin/coupon');

export const userWithUserToken = createInstance('/user');
export const merchantWithMerchantToken = createInstance('/merchant');

export const seatWithMerchantToken = createInstance('/seat');
export const seatImageWithMerchantToken = createImageInstance('/v2/backoffice/seat');
export const userWithMerchantToken = createInstance('/user');
export const paymentWithMerchantToken = createInstance('/payment');
export const couponWithMerchantToken = createInstance('/coupon');
export const messageWithMerchantToken = createImageInstance('/v2/backoffice/message');

const instancesWithRefreshToken: AxiosInstance[] = [authWithRefreshToken, apiWithRefreshToken];
const instancesWithMerchantToken: AxiosInstance[] = [
  seat,
  user,
  ticket,
  apiWithMerchantToken,
  apiWithMerchantTokenV2,
  imageApiWithMerchantToken,
  userWithMerchantToken,
  paymentWithMerchantToken,
  seatWithMerchantToken,
  merchantWithMerchantToken,
  couponWithMerchantToken,
  messageWithMerchantToken,
  seatImageWithMerchantToken,
];
const instancesWithUserToken: AxiosInstance[] = [userWithUserToken, authWithUserToken];
const instancesWithAdminToken: AxiosInstance[] = [
  authWithAdminToken,
  apiWithAdminToken,
  imageApiWithAdminToken,
  couponWithAdminToken,
];
const instancesWithAdminTokenWithoutRefreshToken: AxiosInstance[] = [franchiseApiWithAdminToken];

instancesWithRefreshToken.forEach((instance) => {
  instance.interceptors.request.use(withRefreshTokenAccepted, withTokenError);
  instance.interceptors.response.use(apply, withTokenRejected);
});
instancesWithMerchantToken.forEach((instance) => {
  instance.interceptors.request.use(withMerchantTokenAccepted, withTokenError);
  instance.interceptors.response.use(apply, withTokenRejected);
});
instancesWithUserToken.forEach((instance) => {
  instance.interceptors.request.use(withUserTokenAccepted, withTokenError);
  instance.interceptors.response.use(apply, withTokenRejected);
});
instancesWithAdminToken.forEach((instance) => {
  instance.interceptors.request.use(withAdminTokenAccepted, withTokenError);
  instance.interceptors.response.use(apply, withTokenRejected);
});
instancesWithAdminTokenWithoutRefreshToken.forEach((instance) => {
  instance.interceptors.request.use(withAdminTokenAccepted, withTokenError);
});

export { auth, seat, user, ticket, authWithRefreshToken, authWithAdminToken };

/**
 * 회원 조회
 * @param {*} merchantId
 * @param {*} data : { phoneNum?: string; name?: string; }
 */
export const getUserList = (merchantId: number, data: any) => {
  const phoneQuery = data.phoneNum ? `phoneNum=${data.phoneNum}` : '';
  const nameQuery = data.name ? `${data.phoneNum ? '&' : ''}name=${data.name}` : '';

  return user.get(`/userList/${merchantId}?${phoneQuery}${nameQuery}`);
};

/**
 * 회원 정보 조회
 * @param {*} merchantId
 * @param {*} userId
 */
export const userTicketList = (merchantId: number, userId: number, type: any) =>
  ticket.get(`/userTicketList/${merchantId}/${userId}/${type}`);

/**
 * 해당 좌석에 입장
 * @param {*} data : { merchantId: string; userId: string; ticketId: string; seatId: string; }
 * @returns
 */
export const forceEnter = (data: any) => ticket.patch(`/forceEnter`, data);
