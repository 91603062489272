import React, { useState } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;

  select {
    background: #f8fcff;
    border: 1px solid #e9e9e9;
    border-radius: 4px;
    padding: 9px 12px;
    font-size: 14px;
  }
  @media screen and (max-width: 768px) {
  }

  @media screen and (max-width: 480px) {
    margin: 5px 0 0;

    select {
      margin-left: 5px;

      padding: 9px 5px;
    }
  }
`;

/**
 *
 * @param {*} props
 * @param list : 옵션 리스트(string[])
 * @param defaultOption : 기본 옵션(string|undefined)
 * @param onChange : 옵션 변경시 실행되는 함수((e)=>void)
 * @returns
 */
const Select = (props) => {
  const [searchOption, setSearchOption] = useState(
    props.defaultOption ? props.defaultOption : props.list[0],
  );

  const onChange = (e) => {
    setSearchOption(e.target.value);
    if (props.onChange) props.onChange(e);
  };

  return (
    <Container>
      <select onChange={onChange} value={searchOption}>
        {props.list.map((item, idx) => (
          <option key={item} id={idx}>
            {item}
          </option>
        ))}
      </select>
    </Container>
  );
};
export default Select;
