import React, { useEffect, useState } from 'react';
import PageContainer from '../../components/common/PageContainer';
import Disclaimer from '../../components/common/Disclaimer';
import { Button } from '../../components/common/Button';

import { seatCategoryColumn } from '../../data/ticketList';
import useModal from '../../lib/hooks/useModal';
import CreateSeatCategory from '../../components/modal/seat/CreateSeatCategory';
import { commonWrapperOptions } from '../../components/modal/CommonWrapper';
import { deleteSeatCategory, getSeatCategory } from '../../lib/api/v2/seat';
import { swalMessage } from '../../components/common';
import EditSeatCategory from '../../components/modal/seat/EditSeatCategory';
import Table from '../../components/common/Table';

import IconAdd from '../../assets/images/button/icon_button_add_inverted.svg';

const SeatCategory = () => {
  const [seatCategories, setSeatCategories] = useState([]);

  const { openModal } = useModal();

  const handleOpenCreateSeatCategoryModal = () => {
    openModal(() => <CreateSeatCategory onSuccess={fetch} />, commonWrapperOptions);
  };

  const handleDeleteSeatCategory = (seatCategoryId: number) => {
    swalMessage(
      'warning',
      '해당 카테고리를 삭제할까요?\n삭제 시 복구할 수 없어요.',
      '삭제',
      true,
      async () => {
        try {
          await deleteSeatCategory(seatCategoryId);
          swalMessage('success', '카테고리를 삭제했어요.', '확인', false, fetch, fetch);
        } catch (error) {
          swalMessage('error', '카테고리 삭제에 실패했어요.', '확인', false, fetch, fetch);
        }
      },
    );
  };

  const handleOpenEditSeatCategoryModal = (seatCategory: any) => {
    openModal(
      () => <EditSeatCategory seatCategory={seatCategory} onSuccess={fetch} />,
      commonWrapperOptions,
    );
  };

  const fetch = async () => {
    try {
      const { data } = await getSeatCategory();
      const result = data.map((seatCategory: any) => ({
        ...seatCategory,
        edit: (
          <button
            className="btn-primary btn-sm"
            onClick={() => handleOpenEditSeatCategoryModal(seatCategory)}>
            수정
          </button>
        ),
        delete: (
          <button
            className="btn-secondary btn-sm"
            onClick={() => handleDeleteSeatCategory(seatCategory.id)}>
            삭제
          </button>
        ),
      }));

      setSeatCategories(result);
    } catch (error) {}
  };

  useEffect(() => {
    fetch();
  }, []);

  return (
    <PageContainer
      title="좌석 카테고리"
      itemsRightSideTitle={[
        <Button icon={IconAdd} buttonType="default" onClick={handleOpenCreateSeatCategoryModal}>
          카테고리 등록
        </Button>,
      ]}>
      <Disclaimer
        type="misc"
        message="키오스크에서 좌석이나 세미나실을 선택할 때 보는 사진을 설정할 수 있어요."
      />
      <div className="datatable-container">
        <Table data={seatCategories} columns={seatCategoryColumn} striped={true} />
      </div>
    </PageContainer>
  );
};

export default SeatCategory;
