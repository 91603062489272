import React, { Fragment, useEffect, useState } from 'react';
import Breadcrumb from '../layout/breadcrumb';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  ModalBody,
  ModalHeader,
  Input,
} from 'reactstrap';
import styled from 'styled-components';
import {
  deleteSeat,
  getLockerList,
  getMerchantInfo,
  merchantId_api,
  postCreateSeat,
} from '../lib/api';
import 'moment/locale/ko';
import Swal from 'sweetalert2';
import configDB from '../data/customizer/config';
import { TabButton, ToggleBtn } from './seatList';
import PageContainer from '../components/common/PageContainer';
import { SeatLegend, SeatLegendItem } from '../components/SeatLegend';

import { ActionButton, Button as TopButton } from '../components/common/Button';
import theme from '../theme';
import { swalMessage } from '../components/common';
import { swalReturnWarning } from '../util/commonFunctions';

import _, { add, filter } from 'lodash';
import { Selected } from '../constant';
import { LockerViewV2 } from '../components/common/Seat';

import IconAddLocker from '../assets/images/button/icon_button_add_locker.svg';
import IconReset from '../assets/images/button/icon_button_reset.svg';
import IconDeleteAll from '../assets/images/button/icon_button_delete.svg';
import IconSave from '../assets/images/button/icon_button_save_inverted.svg';

const primary = localStorage.getItem('default_color') || configDB.data.color.primary_color;

const CardContainer = styled.div`
  .cardBody {
    display: flex;
  }
  .date-pickers {
    display: flex;
  }
  .topbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .m-0 {
      display: flex;
      align-items: center;
      position: relative;
      width: 100%;
      i {
        position: absolute;
        top: 0.8vw;
        right: 0.5vw;
      }
      input {
        width: 100%;
      }
    }
  }
  .floorBtn {
    display: 'flex';
    flex-direction: 'column';
  }
  @media screen and (max-width: 768px) {
    .floorBtn {
      flex-direction: row;
    }
  }
  @media screen and (max-width: 480px) {
    .topbar {
      align-items: baseline;
      flex-direction: column;
      .m-0 {
        margin: 3vw 0 !important;
      }
    }
    button {
      margin: 1vw 0 5vw;
    }
    input {
      width: 80% !important;
    }
    .cardBody {
      flex-direction: column;
    }
  }
`;

export const LockersArray = styled.div`
  width: 920px;
  position: relative;
  display: grid;
  grid-template-columns: repeat(20, 1fr);
  @media screen and (max-width: 480px) {
    grid-template-columns: repeat(5, 1fr);
  }
`;

export const LockerS = styled.div`
  width: 71px;
  height: 71px;
  background-color: ${(props) => (props.isUse ? '#888EA5' : '#fff')};
  border: 1px solid #546382;
  color: ${(props) => (props.isUse ? '#ccc' : '#222')};
  display: flex;
  flex-direction: column;
  /* justify-content: center;
  align-items: center; */
  font-size: 2.3vw;
  font-weight: 600;
  top: ${(props) => `${props.top}vw`};
  left: ${(props) => `${props.left}vw`};
  padding: 5px;

  p {
    margin-bottom: 0;
    /* text-align: center; */
    font-size: 11px;
    line-height: 1.2;
  }
`;

export const Locker = styled.div`
  position: absolute;
  top: ${(props) => `${props.top}px`};
  left: ${(props) => `${props.left}px`};

  width: 3rem;
  height: 3rem;
  background-color: ${(props) =>
    props.blocked ? props.theme.unavailableSeat : props.isUse ? props.theme.occupiedSeat : '#fff'};
  border: 1px solid #546382;
  color: ${({ isUse, blocked, theme }) =>
    blocked ? theme.neutralGray[400] : isUse ? theme.neutralGray[300] : theme.occupiedSeat};
  display: flex;
  flex-direction: column;
  /* justify-content: center;
  align-items: center; */
  font-size: 2.3vw;
  font-weight: 600;
  /* top: ${(props) => `${props.top}vw`};
  left: ${(props) => `${props.left}vw`}; */
  padding: 5px;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin-bottom: 0;
    /* text-align: center; */
    font-size: 1.125rem;
  }
`;

export const Button = styled.button`
  width: 100%;
  height: 44px;
  color: #fff;
  border-radius: 4px;
  font-weight: 700;
  font-size: 16px;
  border: none;
  background-color: ${(props) => props.color};
  border: 1px solid ${(props) => (props.color === '#fff' ? '#ccc' : props.color)};
  color: ${(props) => (props.color === '#fff' ? '#222' : '#fff')};
`;

export const Btns = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 12%;
  margin-left: 0.6vw;

  > button {
    background-color: ${primary};

    :last-child {
      background-color: #f53c14;
      margin-top: 0.6vw;
    }
  }
  @media screen and (max-width: 768px) {
    width: 20%;
  }
  @media screen and (max-width: 480px) {
    flex-direction: row;
    width: 100%;
    margin-left: 0vw;
    > button {
      :last-child {
        margin-top: 1vw;
      }
    }
  }
`;

export const AddBtn = styled(Button)`
  width: 12%;
  margin-right: 0.6vw;
  color: ${primary};
  border: 1px solid ${primary};
  background-color: transparent;
  :active,
  :hover {
    background-color: ${primary};
    color: #fff;
  }
  transition: all 0.1s ease-in-out;

  @media screen and (max-width: 768px) {
    width: 20%;
  }
  @media screen and (max-width: 480px) {
    /* flex-direction: row; */
    font-size: 12px;
    width: 40%;
  }
`;
const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 40%;
  font-weight: 500;
  margin-bottom: 1vw;
  @media screen and (max-width: 480px) {
    width: 50%;
  }
`;
export const Label1 = styled.label`
  width: fit-content;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  h5 {
    width: fit-content;
    font-size: 14px;
    strong {
      color: red;
    }
  }
  p {
    margin-bottom: 0;
    font-size: 12px;
    color: #555;
  }
  :not(:last-child) {
    margin-bottom: 15px;
  }
  input,
  select {
    margin-top: 5px;
    background: #f8fcff;
    border: 1px solid #e9e9e9;
    border-radius: 4px;
    padding: 6px 13px;
  }

  @media screen and (max-width: 480px) {
    width: 100%;
    h5 {
      font-size: 13px;
    }
    input,
    select {
      padding: 9px 12px;
      font-size: 13px;
    }
  }
`;

// let merchantId = '';
// let posX,
//   posY = 0;
const LockerChangeV2 = ({ setIsLoading }) => {
  const [merchantId, setMerchantId] = useState(-1);

  const [dragPosition, setDragPosition] = useState({ x: 0, y: 0 });

  const [defaultData, setDefaultData] = useState([]);
  const [lists, setLists] = useState([]);
  const [addList, setAddList] = useState([]);
  const [tagModal, setTagModal] = useState(false);
  const [selected, setSelected] = useState();
  // const [changeData, setChangeData] = useState([]);
  const [multiNum, setMultiNum] = useState(null);
  const [floorNum, setFloorNum] = useState(0);
  const [floorList, setFloorList] = useState([]);

  // 드래그 시작시 이벤트
  const dragStartHandler = (e) => {
    setDragPosition({ x: e.clientX, y: e.clientY });
    // posX = e.clientX;
    // posY = e.clientY;
  };

  //드래그 중일때
  const dragHandler = (e) => {
    const { x, y } = dragPosition;
    e.target.style.left = `${e.target.offsetLeft + e.clientX - x}px`;
    e.target.style.top = `${e.target.offsetTop + e.clientY - y}px`;
    setDragPosition({ x: e.clientX, y: e.clientY });
    // posX = e.clientX;
    // posY = e.clientY;
  };

  //드래그 종료 핸들러
  const dragEndHandler = (e, seat) => {
    const { x, y } = dragPosition;
    const calculatedTop =
      e.target.offsetTop + e.clientY - y > 0
        ? e.target.offsetTop + e.clientY - y < 1444 - 48
          ? e.target.offsetTop + e.clientY - y
          : 1444 - 48
        : 0;
    const calculatedLeft =
      e.target.offsetLeft + e.clientX - x > 0
        ? e.target.offsetLeft + e.clientX - x < 920 - 48
          ? e.target.offsetLeft + e.clientX - x
          : 920 - 48
        : 0;
    e.target.style.left = `${calculatedLeft}px`;
    e.target.style.top = `${calculatedTop}px`;
    setDragPosition({ x: e.clientX, y: e.clientY });
    const changedCurrentSeat = { ...seat, pos_top: calculatedTop, pos_left: calculatedLeft };
    if (seat.fakeId)
      return setAddList([
        ...addList.map((addSeat) =>
          addSeat.fakeId === seat.fakeId ? changedCurrentSeat : addSeat,
        ),
      ]);
    return setLists([
      ...lists.map((existingSeat) =>
        existingSeat.id === seat.id ? changedCurrentSeat : existingSeat,
      ),
    ]);
  };

  useEffect(() => {
    getMerchantId();
    // return () => clearTimeout(getId);
  }, []);

  const getMerchantId = async () => {
    const { data } = await merchantId_api();
    if (data) {
      setMerchantId(data.id);
      const multiNumData = await getMerchantInfo(data.id);
      if (multiNumData && multiNumData.status === 200 && multiNumData.data[0].multiKioskFloor > 0) {
        setMultiNum(multiNumData.data[0].multiKioskFloor);
        setFloorNum(1);
        getLockerListApi(data.id, 1);
      } else {
        getLockerListApi(data.id, null);
      }
    }
  };

  // v1 키오스크 마이그레이션
  const temp_migrateFromLockerV1 = (lockerList) => {
    const sum = lockerList.reduce(
      (previous, current) => previous + current.pos_top + current.pos_left,
      0,
    );
    if (sum !== 0) return lockerList; // 좌표가 이미 지정되어 있으면 마이그레이션을 진행하지 않습니다.
    const lockerListDividedByFloor = {}; // 층별로 마이그레이션을 별도로 지정합니다.

    // 층별로 분리된 사물함 정보를 생성합니다.
    lockerList.forEach((locker) => {
      if (lockerListDividedByFloor[locker.floor])
        return lockerListDividedByFloor[locker.floor].push(locker);
      return (lockerListDividedByFloor[locker.floor] = [locker]);
    });
    let result = [];
    // seat number 기준으로 정렬한 뒤 새로운 좌표 값을 부여합니다.
    Object.values(lockerListDividedByFloor).forEach((list) => {
      const sorted = list.sort((a, b) => a.number - b.number);
      result = [
        ...result,
        ...sorted.map((locker, index) => ({
          ...locker,
          pos_top: Math.floor(index / 20) * 48,
          pos_left: (index % 20) * 48,
        })),
      ];
    });
    return result; // returns migrated locker list
  };

  const getLockerListApi = async (merchantId, floorData) => {
    try {
      setIsLoading(true);
      if (floorData && lists.length !== 0)
        return setFloorList(lists.filter((item) => item.floor === floorData));
      const res = await getLockerList(merchantId);
      setDefaultData(res.data);
      const migrated = temp_migrateFromLockerV1(res.data);
      // if (floorData) {
      //   setFloorList(migrated.filter((item) => item.floor === floorData));
      setLists(migrated);
      // } else {
      //   setLists(migrated);
      // }
    } catch (error) {
      swalReturnWarning(
        '사물함 정보를 불러오는 중 오류가 발생했습니다.\n잠시 후 다시 시도해 주세요.',
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleLockerOnClick = (locker) => {
    tagToggle();
    setSelected(locker);
  };
  const tagToggle = () => {
    setTagModal(!tagModal);
  };
  const handleError = (msg) => {
    Swal.fire({
      icon: 'error',
      timer: 2000,
      title: '현재 사용중인 사물함은 변경이 불가능합니다.',
    });
  };
  const handleAddLocker = () => {
    const fakeId = new Date().getTime();
    const max = [...lists, ...addList].reduce((pre, cur) => Math.max(pre, cur.number), 0);
    const newObj = {
      fakeId,
      number: max + 1,
      name: '',
      status: 'idle',
      type: 'locker',
      level: 1,
      pos_top: 0,
      pos_left: 0,
      id: 0,
      floor: floorNum,
      seatType: 0,
    };
    setAddList([...addList, newObj]);
  };

  const handleOnchange = ({ target }) => {
    const originalFromExisting = defaultData.find((locker) => {
      if (locker.id && locker.id === selected.id) return true;
      if (locker.fakeId && locker.fakeId === selected.fakeId) return true;
      return false;
    });

    if (target.name === 'number') return setSelected({ ...selected, [target.name]: +target.value });
    if (target.name === 'status') {
      if (target.checked) return setSelected({ ...selected, status: 'block' });
      return setSelected({
        ...selected,
        status: 'idle',
      });
    }
    if (target.name === 'floor') return setSelected({ ...selected, floor: +target.value });
    return setSelected({ ...selected, [target.name]: target.value });
  };

  const lockerInfoChange = () => {
    if (selected.fakeId)
      setAddList([
        ...addList.map((addSeat) => (addSeat.fakeId === selected.fakeId ? selected : addSeat)),
      ]);
    else
      setLists([
        ...lists.map((existingSeat) => (existingSeat.id === selected.id ? selected : existingSeat)),
      ]);
    tagToggle();
    setSelected();
  };

  const seatChangeCancel = () => {
    tagToggle();
    setSelected();
  };

  const seatDelete = () => {
    Swal.fire({
      icon: 'warning',
      title: '삭제 시 저장하지 않은 데이터는 사라집니다.',
      text: '그래도 지우시겠습니까?',
      confirmButtonColor: '#F53C14',
      cancelButtonColor: '#04c9db',
      confirmButtonText: '예',
      cancelButtonText: '아니오',
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = await deleteSeat(selected.id, selected.merchantId);
        if (data.status === 200) {
          Swal.fire({
            icon: 'success',
            title: '삭제가 완료되었습니다.',
            timer: 2000,
          }).then(() => {
            window.location.reload();
            tagToggle();
          });
        }
      }
    });
  };

  const handleOnSubmit = async () => {
    const changedList = [];
    lists.forEach((locker) => {
      if (locker.fakeId) return changedList.push(locker);
      const original = defaultData.find((element) => element.id === locker.id);
      if (_.isEqual(original, locker)) return;
      changedList.push(locker);
    });
    const request = [...changedList, ...addList].map((locker) => ({
      ...locker,
      status: locker.status === 'block' ? 1 : 0,
    }));
    try {
      await postCreateSeat({ seat: request, merchantId });

      Swal.fire({
        icon: 'success',
        title: '변경이 완료됐습니다.',
        timer: 3000,
      }).then(() => window.location.reload());
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: '변경에 실패했습니다. 다시 시도해주세요.',
        timer: 2000,
      });
    }
  };
  const handleReset = () => {
    Swal.fire({
      title: '저장하지 않은 데이터는 사라집니다.',
      text: '변경을 취소하시겠습니까?',
      showCancelButton: true,
      confirmButtonColor: '#F53C14',
      cancelButtonColor: '#04c9db',
      confirmButtonText: '예',
      cancelButtonText: '아니오',
    }).then(() => window.location.reload());
  };

  // TODO
  const handleDeleteAll = () => {};

  useEffect(() => {
    if (!merchantId || floorNum === 0) return;
    getLockerListApi(merchantId, floorNum);
  }, [merchantId, floorNum]);

  return (
    <PageContainer title="사물함 배치 변경">
      {tagModal && (
        <Modal centered isOpen={tagModal} toggle={tagToggle}>
          <ModalHeader className="modal-title" toggle={tagToggle}>
            사물함 정보 수정
          </ModalHeader>
          <ModalBody>
            <Label1>
              사물함 번호
              <input
                type="text"
                name="number"
                value={selected.number || 0}
                onChange={handleOnchange}
                placeholder="사물함 번호를 입력해주세요."
              />
            </Label1>
            <Label1>
              사물함 이름
              <input
                type="text"
                name="name"
                value={selected.name || ''}
                onChange={handleOnchange}
                placeholder="사물함 이름을 입력해주세요."
              />
            </Label1>
            {floorNum ? (
              <Label1>
                층 설정
                <Input
                  type="text"
                  name="floor"
                  value={selected?.floor || ''}
                  onChange={handleOnchange}
                />
              </Label1>
            ) : (
              <></>
            )}
            <ToggleContainer>
              사물함 사용불가 설정
              <ToggleBtn>
                <input
                  type="checkbox"
                  name="status"
                  onChange={handleOnchange}
                  checked={selected.status === 'block'}
                />
                <span className="onoff-switch"></span>
              </ToggleBtn>
            </ToggleContainer>
            <div
              style={{
                display: 'flex',
                width: '100%',
                gap: '10px',
              }}>
              <ActionButton buttonType="default" onClick={seatChangeCancel}>
                취소
              </ActionButton>
              <ActionButton color={primary} onClick={lockerInfoChange}>
                확인
              </ActionButton>
              <ActionButton color="#F53C14" onClick={seatDelete}>
                삭제
              </ActionButton>
            </div>
          </ModalBody>
        </Modal>
      )}
      <ButtonArea>
        <SeatLegend>
          <SeatLegendItem
            text="이용 가능한 사물함"
            seatColor={theme.idleSeat}
            borderColor={theme.neutralGray[300]}
          />
          <SeatLegendItem
            text="점유된 사물함"
            seatColor={theme.occupiedSeat}
            borderColor={theme.occupiedSeat}
          />
          <SeatLegendItem
            text="이용 불가 처리한 사물함"
            seatColor={theme.unavailableSeat}
            borderColor={theme.unavailableSeat}
          />
        </SeatLegend>
        <div>
          <TopButton icon={IconAddLocker} buttonType="default" striped onClick={handleAddLocker}>
            사물함 추가
          </TopButton>
          <TopButton icon={IconReset} buttonType="default" striped onClick={handleReset}>
            변경 취소
          </TopButton>
          <TopButton icon={IconDeleteAll} buttonType="default" striped onClick={handleDeleteAll}>
            전체 삭제
          </TopButton>
          <TopButton icon={IconSave} buttonType="default" onClick={handleOnSubmit}>
            저장
          </TopButton>
        </div>
      </ButtonArea>

      {multiNum && (
        <div className="floorBtn">
          {[...Array(multiNum)].map((item, index) => (
            <TabButton
              selected={floorNum === index + 1}
              onClick={() => setFloorNum(index + 1)}
              value={index + 1}>
              {index + 1}층
            </TabButton>
          ))}
        </div>
      )}

      <LockerViewV2>
        <LockersArray>
          {lists
            .filter((locker) => {
              if (floorNum === 0) return true;
              return locker.floor === floorNum;
            })
            .map((seat) => (
              <React.Fragment key={seat.id === 0 ? seat?.fakeId : seat.id}>
                <Locker
                  draggable
                  onDragStart={(e) => dragStartHandler(e, seat)}
                  onDrag={dragHandler}
                  onDragEnd={(e) => dragEndHandler(e, seat)}
                  onDoubleClick={() =>
                    seat.status === 'using' ? handleError() : handleLockerOnClick(seat)
                  }
                  top={seat.pos_top}
                  left={seat.pos_left}
                  isUse={seat.status === 'using'}
                  blocked={seat.status === 'block'}>
                  <div>{seat.number}</div>
                </Locker>
              </React.Fragment>
            ))}
          {addList
            .filter((locker) => {
              if (floorNum === 0) return true;
              return locker.floor === floorNum;
            })
            .map((seat) => (
              <React.Fragment key={seat.fakeId}>
                <Locker
                  draggable
                  onDragStart={(e) => dragStartHandler(e, seat)}
                  onDrag={dragHandler}
                  onDragEnd={(e) => dragEndHandler(e, seat)}
                  onDoubleClick={() =>
                    seat.status === 'using' ? handleError() : handleLockerOnClick(seat)
                  }
                  top={seat.pos_top}
                  left={seat.pos_left}
                  isUse={seat.status === 'using'}
                  blocked={seat.status === 'block'}>
                  <div>{seat.number}</div>
                </Locker>
              </React.Fragment>
            ))}
        </LockersArray>
      </LockerViewV2>
    </PageContainer>
  );
};

const ButtonArea = styled.div`
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    gap: 1rem;
  }

  > div:last-child {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
  }
`;

export default LockerChangeV2;
