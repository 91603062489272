import React, { useMemo } from 'react';
import DatePicker from 'react-datepicker';
import { useState } from 'react';
import { Inputs, Label2 } from './coupon/coupon';
import { Label1 } from './lockerChange';
import { DatePickerStyle } from './seatInfo';
import { ExampleCustomInput, LabelRow } from './ticketCustom';
import { ko } from 'date-fns/locale';
import moment from 'moment';
import { Button, Input } from 'reactstrap';
import { LabelS } from './ticketList';
import { SearchLabel, SearchList, SearchLists } from './user/user';
import Swal from 'sweetalert2';
import { getUserMerchantList } from '../lib/api';
import { exportExcel } from '../components/common';
import { postAdminCoupon } from '../lib/api/v2/admin';
import Format from '../util/formatter';
import styled from 'styled-components';
import { RadioBtn, RadioLabel } from '../components/commonStyle';

const TotalAmount = styled.div`
  font-size: 1.125rem;
  font-weight: 600;
  color: ${({ theme }) => theme.main[600]};
`;

const DiscountSelect = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.5rem;
  max-width: 30rem;
  padding-bottom: 0.5rem;
`;

const AdminCoupon = () => {
  const [selectedDiscountMethod, setSelectedDiscountMethod] = useState('discountRate');
  const [couponIssue, setCouponIssue] = useState({
    couponType: 1,
    seatType: 0,
    discountRate: 0,
    discountAmount: 0,
    time: 1,
    day: 1,
    originalAmount: 1000,
    hour: 1,
    min: 0,
    level: 1,
    //발급 갯수
    couponCount: 1,
  });
  const [expirationDate, setExpirationDate] = useState();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(moment(new Date()).add(7, 'day')._d);
  const [searchMerchant, setSearchMerchant] = useState('');
  const [merchantList, setMerchantList] = useState([]);
  const [selectedMerchant, setSelectedMerchant] = useState(null);

  const {
    couponType,
    seatType,
    discountAmount,
    discountRate,
    time,
    day,
    originalAmount,
    hour,
    min,
    level,
    couponCount,
  } = couponIssue;

  const DiscountAppliedAmount = useMemo(() => {
    if (selectedDiscountMethod === 'discountRate')
      return (couponIssue.originalAmount * (100 - couponIssue.discountRate)) / 100;
    return couponIssue.originalAmount - couponIssue.discountAmount;
  }, [selectedDiscountMethod, couponIssue]);

  //onChange 함수
  const handleChange = (e) => {
    if (e.target.name === 'seatType') {
      setCouponIssue({
        ...couponIssue,
        [e.target.name]: e.target.checked ? 1 : 0,
      });
    } else if (e.target.name === 'couponType') {
      if (+e.target.value === 1 || +e.target.value === 3 || +e.target.value === 4) {
        setCouponIssue({
          ...couponIssue,
          [e.target.name]: +e.target.value,
          day: 0,
        });
      } else {
        setCouponIssue({
          ...couponIssue,
          [e.target.name]: +e.target.value,
          time: 0,
        });
      }
    } else if (e.target.name === 'expirationDate') {
      setExpirationDate(e.target.value);
    } else {
      setCouponIssue({ ...couponIssue, [e.target.name]: +e.target.value });
    }
  };

  //지점검색
  const handleSearchKeyword = ({ target }) => {
    const regexp = /[a-z0-9]|[ \[\]{}()<>?|`~!@#$%^&*-_+=,.;:\"'\\]/g;

    setSearchMerchant(target.value.replace(regexp, ''));
  };
  const keyword = ['스터디카페', '스터디', '카페', '스터'];
  const handleSearchData = async () => {
    if (searchMerchant.length < 2) {
      return Swal.fire({
        icon: 'error',
        title: '2글자 이상 검색해주세요.',
        timer: 3500,
      });
    }
    if (keyword.some((word) => searchMerchant.includes(word))) {
      return;
    }
    const { data } = await getUserMerchantList(null, searchMerchant);
    setSelectedMerchant(null);
    if (data?.length > 0) {
      setMerchantList(data);
    }
  };

  const checkBeforeIssue = () => {
    try {
      const calculatedTotalAmount =
        selectedDiscountMethod === 'discountRate'
          ? (originalAmount * (100 - discountRate)) / 100
          : originalAmount - discountAmount;
      if (calculatedTotalAmount < 0) {
        Swal.fire({ icon: 'error', title: '최종 금액을 음수로 설정할 수 없습니다.' });
        return false;
      }
      if (calculatedTotalAmount % 500 !== 0) {
        Swal.fire({ icon: 'error', title: '최종금액을 500원 단위로 맞춰 주세요.' });
        return false;
      }
      if (couponType == 3 && !expirationDate) {
        Swal.fire({ icon: 'error', title: '상품의 유효기간을 입력해 주세요.' });
        return false;
      }
      return true;
    } catch (error) {
      return false;
    }
  };

  // 쿠폰 발급 Func
  const exportCouponData = async () => {
    //보내는 함수 가공
    try {
      const baseRequestData = {
        couponType,
        seatType,
        time,
        day,
        originalAmount,
        hour,
        min,
        level,
        couponCount,
      };
      if (!checkBeforeIssue()) return;
      if (selectedDiscountMethod === 'discountRate') baseRequestData.discountRate = discountRate;
      if (selectedDiscountMethod === 'discountAmount')
        baseRequestData.discountAmount = discountAmount;
      let data;
      if (couponType === 3) {
        data = {
          ...baseRequestData,
          merchantId: selectedMerchant.id,
          time: couponIssue.hour * 3600 + couponIssue.min * 60,
          expStartDate: moment(startDate).format('YYYY-MM-DD 00:00:00'),
          expEndDate: moment(endDate).format('YYYY-MM-DD 23:59:59'),
          day: expirationDate,
        };
      } else if (couponType === 2 || couponType === 5) {
        data = {
          ...baseRequestData,
          merchantId: selectedMerchant.id,
          time: 0,
          expStartDate: moment(startDate).format('YYYY-MM-DD 00:00:00'),
          expEndDate: moment(endDate).format('YYYY-MM-DD 23:59:59'),
        };
      } else {
        data = {
          ...baseRequestData,
          merchantId: selectedMerchant.id,
          day: 0,
          time: couponIssue.hour * 3600 + couponIssue.min * 60,
          expStartDate: moment(startDate).format('YYYY-MM-DD 00:00:00'),
          expEndDate: moment(endDate).format('YYYY-MM-DD 23:59:59'),
        };
      }
      //api실행
      if (!data) return;
      const result = await postAdminCoupon(data);
      let couponIssuedData = result.data.map((coupon) => {
        let newobj = {};
        newobj['name'] = coupon;
        newobj['type'] = data.couponType;
        newobj['typeName'] =
          +data.couponType === 1
            ? '일회권'
            : +data.couponType === 2
            ? '기간권'
            : +data.couponType === 3
            ? '시간정액권'
            : '사물함';
        newobj['merchantName'] = selectedMerchant.name;
        newobj['term'] =
          data.couponType === 2 || data.couponType === 5
            ? `${data.day}일`
            : `${data.hour > 0 ? data.hour + '시간 ' : ''}${data.min > 0 ? data.min + '분' : ''}`;
        newobj['day'] = data.couponType === 3 ? `${data.day}일` : 0;
        newobj['expDate'] = `${moment(data.expStartDate).format('MM/DD/YYYY')} ~ ${moment(
          data.expEndDate,
        ).format('MM/DD/YYYY')}`;
        return newobj;
      });
      if (couponIssuedData)
        //api실행 후 excel 데이터 저장(넘어오는 값 확인 후 입장권 상태에 따른 header로 변경)
        exportExcel(couponIssuedData, '', '', '지점 쿠폰 발급');
    } catch (e) {
      console.log(e);
      Swal.fire({
        text: '쿠폰 발급 중 오류가 발생했습니다.',
        timer: 2000,
      });
    }
  };
  return (
    <>
      <Label1>
        <h5>쿠폰 타입</h5>
        <select value={couponIssue.couponType} name="couponType" onChange={(e) => handleChange(e)}>
          <option value={1}>일회권</option>
          <option value={2}>기간권</option>
          <option value={3}>시간정액권</option>
          <option value={5}>사물함</option>
        </select>
      </Label1>
      {/* 지점선택 추가 */}
      <SearchLabel>
        <h5>발급 지점</h5>
        <div style={{ width: '80%' }}>
          <input
            type="text"
            value={searchMerchant || ''}
            // name="parentPhoneNum"
            onChange={handleSearchKeyword}
            placeholder=""
          />
          <button className="btn btn-primary" onClick={handleSearchData}>
            검색
          </button>
        </div>
        {selectedMerchant && (
          <p style={{ margin: '15px 0  0 7px', textAlign: 'left' }}>
            <strong>{selectedMerchant?.name}</strong> 으로 선택
          </p>
        )}
      </SearchLabel>

      {merchantList?.length > 0 && !selectedMerchant && (
        <SearchLists>
          {merchantList.map((li) => (
            <SearchList
              selected={selectedMerchant?.name === li.name}
              key={li.id}
              onClick={() => setSelectedMerchant(li)}>
              {li.name}
            </SearchList>
          ))}
        </SearchLists>
      )}

      {couponIssue.couponType === 2 && (
        <Label1>
          <h5>고정석 여부</h5>
          <div>
            <LabelS htmlFor="seatType">
              <Input
                id="seatType"
                name="seatType"
                type="checkbox"
                checked={couponIssue.seatType || ''}
                onChange={(e) => handleChange(e)}
              />
              고정석 사용
            </LabelS>
          </div>
        </Label1>
      )}

      {(couponIssue.couponType === 2 || couponIssue.couponType === 5) && (
        <Label1>
          <h5>상품기간(일)</h5>
          <Input
            type="text"
            name="day"
            placeholder="숫자만 입력해주세요"
            value={couponIssue.day || ''}
            onChange={(e) => handleChange(e)}
          />
        </Label1>
      )}
      {(couponIssue.couponType === 1 ||
        couponIssue.couponType === 3 ||
        couponIssue.couponType === 4) && (
        <Label1>
          <h5>상품시간</h5>
          <Inputs>
            <LabelRow>
              <Input
                type="number"
                min="0"
                name="hour"
                value={couponIssue.hour || ''}
                onChange={(e) => handleChange(e)}
              />
              <p>시간</p>{' '}
            </LabelRow>
            <LabelRow>
              <Input
                type="number"
                min="0"
                name="min"
                value={couponIssue.min || ''}
                onChange={(e) => handleChange(e)}
              />
              <p> 분</p>{' '}
            </LabelRow>
          </Inputs>
        </Label1>
      )}
      {couponIssue.couponType === 3 && (
        <>
          <Label1>
            <h5>상품유효기간(일)</h5>
            <Input
              type="number"
              min="0"
              name="expirationDate"
              value={expirationDate || ''}
              onChange={(e) => handleChange(e)}
            />
          </Label1>
        </>
      )}
      <Label1>
        <h5>등급</h5>
        <Input
          type="text"
          name="level"
          placeholder="숫자만 입력해주세요"
          value={couponIssue.level || ''}
          onChange={(e) => handleChange(e)}
        />
      </Label1>
      <Label1>
        <h5>원가(정상판매가)</h5>
        <Input
          type="text"
          name="originalAmount"
          placeholder="숫자만 입력해주세요"
          value={couponIssue.originalAmount || ''}
          onChange={(e) => handleChange(e)}
        />
      </Label1>
      <DiscountSelect>
        <RadioLabel>
          <RadioBtn
            checked={selectedDiscountMethod === 'discountRate'}
            onClick={() => setSelectedDiscountMethod('discountRate')}
          />
          할인율
        </RadioLabel>

        <Input
          className="form-control m-input digits"
          type="text"
          name="discountRate"
          placeholder="숫자만 입력하세요."
          disabled={selectedDiscountMethod !== 'discountRate'}
          value={couponIssue.discountRate || ''}
          onChange={(e) => handleChange(e)}
        />
        <RadioLabel>
          <RadioBtn
            checked={selectedDiscountMethod === 'discountAmount'}
            onClick={() => setSelectedDiscountMethod('discountAmount')}
          />
          할인 금액 직접 입력
        </RadioLabel>

        <Input
          className="form-control m-input digits"
          type="text"
          name="discountAmount"
          placeholder="숫자만 입력하세요."
          disabled={selectedDiscountMethod !== 'discountAmount'}
          value={couponIssue.discountAmount || ''}
          onChange={(e) => handleChange(e)}
        />
      </DiscountSelect>
      <Label1>
        <h5>최종금액</h5>
        <TotalAmount>{Format.formatNumber(DiscountAppliedAmount)}원</TotalAmount>
      </Label1>

      <Label2>
        <h5 style={{ textAlign: 'left' }}>쿠폰유효기간</h5>
      </Label2>
      <Label1>
        <DatePickerStyle style={{ margin: 0 }}>
          <DatePicker
            selected={startDate}
            onChange={(date) => {
              setStartDate(date);
            }}
            selectsStart
            locale={ko}
            startDate={startDate}
            maxDate={endDate}
            endDate={endDate}
            customInput={<ExampleCustomInput />}
          />
          <h5 style={{ margin: '0 8px' }}> - </h5>
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            selectsEnd
            locale={ko}
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            customInput={<ExampleCustomInput />}
          />
        </DatePickerStyle>
      </Label1>
      {/* 쿠폰 발급 갯수 */}

      <Label1>
        <h5>발급 개수</h5>
        <Input
          type="text"
          name="couponCount"
          placeholder="숫자만 입력해주세요"
          value={couponIssue.couponCount || ''}
          onChange={(e) => handleChange(e)}
        />
      </Label1>
      <button className="btn btn-primary" onClick={exportCouponData}>
        엑셀로 저장
      </button>
    </>
  );
};

export default AdminCoupon;
