import { couponWithMerchantToken } from '.';

/**
 * 쿠폰 등록
 * @param req 쿠폰 등록에 필요한 정보
 * @returns
 */
export const postCoupon = (req: any) => couponWithMerchantToken.post(``, req);

/**
 * 발급된 쿠폰 목록
 * @param isUsed
 * @param page
 * @param startDate
 * @param endDate
 * @returns
 */
export const getCoupon = (isUsed: 0 | 1, page: number, startDate: string, endDate: number) =>
  couponWithMerchantToken.get(`/${isUsed}`, {
    params: {
      page,
      startDate,
      endDate,
    },
  });

/**
 * 쿠폰 삭제
 * @param id 삭제하려는 쿠폰의 고유값
 * @returns
 */
export const deleteCoupon = (id: number) => couponWithMerchantToken.delete(`/${id}`);
