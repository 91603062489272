import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, FormGroup, Input } from 'reactstrap';

import { withRouter } from 'react-router-dom';

import Swal from 'sweetalert2';

import Footer from '../layout/footer';

import LoginImage from '../assets/images/logo/login.svg';
import Tab from '../components/atom/Tab';
import { postMerchantLogin, postUserLogin } from '../lib/api/v2/auth';
import { useSetRecoilState } from 'recoil';
import { userPhoneNumberState } from '../state';
import FooterV2 from '../layout/footer/FooterV2';

const Logins = () => {
  const setUserPhoneNumber = useSetRecoilState(userPhoneNumberState);

  const [LoginData, setLoginData] = useState({ id: '', password: '' });
  const [selectedTab, setSelectedTab] = useState('admin');
  const [togglePassword, setTogglePassword] = useState(false);
  const { id, password } = LoginData;
  const handleOnChange = (e) => {
    setLoginData({ ...LoginData, [e.target.name]: e.target.value });
  };

  const handleLogin = async () => {
    // macaddress.all(async function (err, all) {
    try {
      let res;
      if (selectedTab === 'admin') res = await postMerchantLogin(id, password);
      if (selectedTab === 'user') res = await postUserLogin(id, password);
      // const res = await (id, password);
      if (res.status === 200) {
        const { data } = res;
        if (selectedTab === 'user') setUserPhoneNumber(data?.phoneNum || '');
        // let levelData = levelDecode(res.data.access.token);
        // if (+levelData === 99) {
        //   window.location.href = `${process.env.PUBLIC_URL}/merchant-list`;
        // } else if (+levelData === 50) {
        //   window.location.href = `${process.env.PUBLIC_URL}/admin/dashboard`;
        // } else {
        //   window.location.href = `${process.env.PULIC_URL}/dashboard`;
        // }
      } else if (res.status === 202 && res.data === 102) {
        Swal.fire({
          text: '아이디가 잘못됐습니다. 다시 입력해주세요.',
          confirmButtonColor: '#04c9db',
        });
      } else if (res.status === 202 && res.data === 101) {
        Swal.fire({
          text: '비밀번호가 잘못됐습니다. 다시 입력해주세요.',
          confirmButtonColor: '#04c9db',
        });
      }
    } catch (err) {
      console.log(err);
      if (err.toString().includes('401' || '500')) {
        Swal.fire(`아이디 또는 비밀번호가 틀렸습니다.`);
      } else {
        Swal.fire({
          html: '에러가 발생했습니다.<br/>잠시 후 다시 시도해 주세요.',
          confirmButtonColor: '#04c9db',
        });
      }
    }
    // });
  };

  const onClickjoin = () => {
    window.location.href = `${process.env.PUBLIC_URL}/auth/join`;
  };

  const onClickPassword = () => {
    window.location.href = `${process.env.PUBLIC_URL}/auth/password`;
  };

  const handleKeyup = () => {
    if (window.event.keyCode === 13) {
      handleLogin();
    }
  };
  return (
    <Container style={{ overflow: 'hidden' }} fluid={true} className="p-0">
      {/* {window.innerWidth > 480 && <AuthHeader />} */}
      <Row>
        <Col xs="12">
          <div className="login-card">
            <div className={window.innerWidth < 1080 ? 'card-style' : 'card-style'}>
              <div style={{ marginBottom: '40px' }}>
                <img className="img-fluid for-light" src={LoginImage} alt="" />
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  width: '100%',
                }}
                className="login-main login-tab">
                <Form className="theme-form">
                  <Tab
                    selected={selectedTab}
                    setSelected={setSelectedTab}
                    tabItems={[
                      { key: 'admin', text: '관리자' },
                      { key: 'user', text: '사용자' },
                    ]}
                  />
                  <div style={{ height: '1rem' }} />
                  <FormGroup>
                    <Input
                      className="form-control"
                      placeholder="휴대폰 번호를 적어주세요."
                      type="text"
                      name="id"
                      required
                      value={id}
                      onChange={handleOnChange}
                    />
                  </FormGroup>
                  <FormGroup>
                    <div className="show-hide" onClick={() => setTogglePassword(!togglePassword)}>
                      <span className={togglePassword ? '' : 'show'}></span>
                    </div>
                    <Input
                      className="form-control"
                      placeholder="비밀번호를 적어주세요."
                      type={togglePassword ? 'text' : 'password'}
                      name="password"
                      onChange={handleOnChange}
                      onKeyUp={() => handleKeyup()}
                      required
                    />
                  </FormGroup>
                  <a className="ml-2" href="#javascript" onClick={() => onClickPassword()}>
                    비밀번호 찾기
                  </a>
                </Form>
                <button className="loginBtn" onClick={() => handleLogin()}>
                  로그인
                </button>
                <p className="text">
                  아직 렛미업 회원이 아니신가요?
                  <br />
                </p>
                <button className="loginBtn line" onClick={() => onClickjoin()}>
                  회원가입
                </button>
                {/* <p className="mt-4 mb-0">
                  비밀번호를 모르실 경우
                  <br />
                  <a
                    className="ml-2"
                    href="#javascript"
                    onClick={() => onClickPassword()}
                  >
                    여기
                  </a>
                  를 클릭해주세요
                </p> */}
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default withRouter(Logins);
