import React, { useState } from 'react';
import { Input, Modal, ModalBody, ModalHeader } from 'reactstrap';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import { changeSeat, ticketForceExit, ticketUsing } from '../../lib/api';
import { Button, Label1 } from '../../pages/lockerChange';
import { ButtonS, ModalBackground } from './choiceModal';
import configDB from '../../data/customizer/config';
import { ArrowRight, Info, LogOut, MessageSquare } from 'react-feather';
import MessageSendModal from './messageSendModal';
import DetailsModal from './detailsModal';

const primary = localStorage.getItem('default_color') || configDB.data.color.primary_color;

const Btns = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-top: 10px;
  > button {
    padding: 8px 10px;
    font-size: 14px;
  }
`;

const ChangeLockerModal = ({ isOpen, onClose, selected, lists }) => {
  const [changeNum, setChangeNum] = useState(0);
  const [isChangeLocker, setIsChangeLocker] = useState(false);
  const [isSendMsg, setIsSendMsg] = useState(false);
  const [detailUserModal, setDetailUserModal] = useState(false);

  const toggleIsSendMsg = () => {
    setIsSendMsg(!isSendMsg);
  };
  const showDetailModal = () => {
    setDetailUserModal(!detailUserModal);
  };

  const seatChange = async () => {
    const changeSeatData = lists.filter((item) => item.number === +changeNum);

    if (changeSeatData.length > 0) {
      try {
        const data = await changeSeat({
          merchantId: selected.merchantId,
          userId: selected.userId,
          number1: selected.number,
          number2: +changeNum,
          ticketId: selected.ticketId,
          seatId1: selected.id,
          seatId2: changeSeatData[0].id,
        });

        if (data.status === 200) {
          Swal.fire({
            icon: 'success',
            title: '사물함 좌석변경이 완료됐습니다.',
            timer: 3000,
          }).then(() => {
            window.location.reload();
          });
        }
      } catch (error) {
        console.log(error);
        Swal.fire({
          icon: 'error',
          title: '에러가 발생했습니다. 다시 시도해주세요.',
          timer: 3000,
        });
      }
    } else {
      Swal.fire({
        icon: 'error',
        title: '해당번호는 없는 사물함번호입니다. 다시 입력해주세요.',
        timer: 3000,
      });
    }
  };

  const outTicket = async (e) => {
    Swal.fire({
      title: '퇴장처리를 하시겠습니까?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#F53C14',
      cancelButtonColor: '#04c9db',
      confirmButtonText: '퇴장',
      cancelButtonText: '취소',
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = await ticketUsing(e.userId, e.merchantId);
        if (data.status === 200) {
          let result = await ticketForceExit({
            merchantId: e.merchantId,
            id: e.ticketId,
            userId: e.userId,
            seatId: data.data.seatId,
          });

          if (result.status === 200) {
            Swal.fire('퇴장처리가 완료되었습니다.');
            window.location.reload();
          } else {
            Swal.fire('퇴장처리 중 에러가 발생했습니다. 다시 시도해주세요.');
          }
        }
      }
    });
    // }
  };

  return (
    <>
      {isSendMsg && (
        <MessageSendModal
          isOpen={isSendMsg}
          onClose={toggleIsSendMsg}
          phoneNum={selected.phoneNum}
          mid={selected.merchantId}
        />
      )}
      {detailUserModal && (
        <DetailsModal
          isOpen={detailUserModal}
          close={showDetailModal}
          choiceModalClose={onClose}
          seat={selected}
        />
      )}
      {isChangeLocker && (
        <Modal centered isOpen={isChangeLocker} toggle={() => setIsChangeLocker(!isChangeLocker)}>
          <ModalHeader className="modal-title" toggle={onClose}>
            사물함 이동
          </ModalHeader>
          <ModalBody>
            <Label1>
              <p>이동할 사물함을 입력해주세요</p>

              <Input
                type="text"
                value={changeNum}
                onChange={({ target }) => setChangeNum(target.value)}
              />
            </Label1>
            <div
              style={{
                display: 'flex',
                width: '100%',
                gap: '10px',
              }}
            >
              <Button color="#fff" onClick={() => setIsChangeLocker(!isChangeLocker)}>
                취소
              </Button>
              <Button onClick={seatChange} color={primary}>
                사물함이동
              </Button>
            </div>
          </ModalBody>
        </Modal>
      )}
      <Modal centered isOpen={isOpen} toggle={onClose}>
        <ModalHeader className="modal-title" toggle={onClose}>
          {selected.number}번 사물함 회원 정보
        </ModalHeader>
        <ModalBody>
          <h5>{selected.phoneNum}</h5>
          <Btns>
            <ButtonS
              bg=" #FFC42B"
              color="#0F2A4A"
              onClick={() => setIsChangeLocker(!isChangeLocker)}
            >
              사물함 이동 <ArrowRight />
            </ButtonS>
            <ButtonS bg="#21D7D9" color="#0F2A4A" onClick={toggleIsSendMsg}>
              문자발송 <MessageSquare />
            </ButtonS>
            <ButtonS bg="#722DE2 " onClick={showDetailModal}>
              상세정보 <Info />
            </ButtonS>
            <ButtonS bg="#F53C14" onClick={() => outTicket(selected)}>
              퇴장 <LogOut />
            </ButtonS>
          </Btns>
        </ModalBody>
      </Modal>

      <ModalBackground />
    </>
  );
};

export default ChangeLockerModal;
