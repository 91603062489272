import React from 'react';
import { paymentTypeConversionsKr } from '../util/util';
export const ticketListColumns = [
  {
    name: '번호',
    selector: (row) => row.id,
    sortable: true,
    center: true,
    minWidth: '80px',
  },
  {
    name: '유형',
    selector: (row) => row.type1,
    sortable: true,
    center: true,
    minWidth: '125px',
  },
  {
    name: '등급',
    selector: (row) => row.level,
    sortable: true,
    center: true,
    minWidth: '10px',
    maxWidth: '80px',
  },
  {
    name: '전화번호',
    selector: (row) => row.phoneNumtag,
    sortable: true,
    center: true,
    minWidth: '140px',
  },
  {
    name: '사용시작일',
    selector: (row) => <p style={{ textAlign: 'center', lineHeight: 1.2 }}>{row.startDate}</p>,
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '140px',
  },

  {
    name: '마지막 출입시간',
    selector: (row) => <p style={{ textAlign: 'center', lineHeight: 1.2 }}>{row.last2}</p>,
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '150px',
  },
  {
    name: '충전시간',
    selector: (row) => row.action,
    sortable: true,
    center: true,
    minWidth: '120px',
  },
  {
    name: '유효기간',
    selector: (row) => row.expireDate2,
    sortable: true,
    center: true,
    minWidth: '120px',
  },

  {
    name: '잔여시간',
    selector: (row) => row.remainTime2,
    sortable: true,
    center: true,
    minWidth: '120px',
  },
  {
    name: '사용상태',
    selector: (row) => row.T_block,
    sortable: true,
    center: true,
    minWidth: '135px',
  },
  {
    name: '이용상태',
    selector: (row) => row.T_isUsing,
    sortable: true,
    center: true,
    minWidth: '120px',
  },
  {
    name: '퇴장처리',
    selector: (row) => row.outButton,
    sortable: true,
    center: true,
    minWidth: '120px',
  },
  {
    name: '사용가능',
    selector: (row) => row.ticketBlock,
    sortable: true,
    center: true,
    minWidth: '120px',
  },
  {
    name: '자세히',
    selector: (row) => row.action2,
    sortable: true,
    center: true,
  },
];

export const paylogListColumns = [
  {
    name: 'ID',
    selector: (row) => row.id,
    sortable: true,
    center: true,
    maxWidth: '1px',
  },
  {
    name: '입장권 유형',
    selector: (row) => row.p_type,
    sortable: true,
    center: true,
    minWidth: '130px',
  },
  {
    name: '전화번호',
    selector: (row) => row.phoneNumTag,
    sortable: true,
    center: true,
    minWidth: '140px',
  },
  {
    name: '티켓번호',
    selector: (row) => row.ticketId,
    sortable: true,
    center: true,
    minWidth: '150px',
  },
  {
    name: '결제일자',
    selector: (row) => row.createdAt,
    sortable: true,
    center: true,
    minWidth: '180px',
  },
  {
    name: '결제수단',
    selector: (row) => {
      return paymentTypeConversionsKr(row.paymentType);
    },
    sortable: true,
    center: true,
    minWidth: '140px',
  },
  {
    name: '금액',
    selector: (row) => row.price,
    sortable: true,
    center: true,
  },
  {
    name: '적립 마일리지',
    selector: (row) => row.addMileage,
    sortable: true,
    center: true,
    Cell: ({ value }) => <span>{value}</span>,
    minWidth: '140px',
  },
  {
    name: '사용 마일리지',
    selector: (row) => row.minusMileage,
    sortable: true,
    center: true,
    Cell: ({ value }) => <span>{value}</span>,
    minWidth: '140px',
  },
  {
    name: '승인번호',
    selector: (row) => row.meta2,
    sortable: true,
    center: true,
    minWidth: '140px',
  },
  {
    name: '상태',
    selector: (row) => row.cancel,
    sortable: true,
    center: true,
    minWidth: '140px',
  },
  {
    name: '자세히',
    selector: (row) => row.action,
    sortable: true,
    center: true,
  },
];

export const paylogListColumns1 = [
  {
    name: 'ID',
    selector: (row) => row.id,
    sortable: true,
    center: true,
    maxWidth: '1px',
  },
  {
    name: '유형',
    selector: (row) => row.ticketType,
    sortable: true,
    center: true,
    minWidth: '160px',
  },

  {
    name: '권번',
    selector: (row) => row.ticketId,
    sortable: true,
    center: true,
  },
  {
    name: '결제일자',
    selector: (row) => row.createdAt,
    sortable: true,
    center: true,
    minWidth: '170px',
  },
  {
    name: '금액',
    selector: (row) => row.price,
    sortable: true,
    center: true,
  },
  {
    name: '승인번호',
    selector: (row) => row.meta2,
    sortable: true,
    center: true,
    minWidth: '160px',
    // wrap: true,
  },
  {
    name: '상태',
    selector: (row) => row.cancelType,
    sortable: true,
    center: true,
    minWidth: '141px',
  },
];

export const seatlogListColumn = [
  {
    name: '유형',
    selector: (row) => row.type,
    sortable: true,
    center: true,
  },
  {
    name: '좌석번호',
    selector: (row) => row.numberName,
    sortable: true,
    center: true,
    minWidth: '120px',
  },
  {
    name: '전화번호',
    selector: (row) => row.phoneNumTag,
    sortable: true,
    center: true,
    minWidth: '160px',
  },
  {
    name: '입장일시',
    selector: (row) => row.lastIn,
    sortable: true,
    center: true,
    minWidth: '170px',
  },
  {
    name: '최종 입출입 시간',
    selector: (row) => row.action,
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '170px',
  },
  {
    name: '체류시간',
    selector: (row) => row.expireDate,
    sortable: true,
    center: true,
    minWidth: '210px',
  },

  {
    name: '권번',
    selector: (row) => row.ticketId,
    sortable: true,
    center: true,
    minWidth: '100px',
  },

  {
    name: '퇴장여부',
    selector: (row) => row.isUsing,
    sortable: true,
    center: true,
    minWidth: '160px',
  },
];

export const messagelogListColumn = [
  {
    name: 'no.',
    selector: (row) => row.id,
    sortable: true,
    center: true,
    maxWidth: '10px',
  },
  {
    name: '본문',
    selector: (row) => row.message,
    sortable: true,
    wrap: true,
    minWidth: '500px',
    grow: 3,
  },
  {
    name: '수신번호',
    selector: (row) => row.phone,
    sortable: true,
    center: true,
    minWidth: '150px',
  },
  {
    name: '전송일자',
    selector: (row) => row.createdAt,
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '200px',
  },
];

export const userTicketListColumn = [
  {
    name: 'no.',
    selector: (row) => row.id,
    sortable: true,
    center: true,
    maxWidth: '10px',
  },
  {
    name: '유형',
    selector: (row) => row.type,
    sortable: true,
    center: true,
    minWidth: '120px',
  },
  {
    name: '등급',
    selector: (row) => row.level,
    sortable: true,
    center: true,
  },
  {
    name: '시작날짜',
    selector: (row) => row.start,
    sortable: true,
    center: true,
    minWidth: '170px',
  },
  {
    name: '마감 날짜',
    selector: (row) => row.end,
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '170px',
  },
  {
    name: '사용여부',
    selector: (row) => row.T_isUsing,
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '160px',
  },
];

export const mapViewUserListColumn = [
  {
    name: '유형',
    selector: (row) => row.type,
    sortable: true,
    center: true,
    minWidth: '9rem',
  },

  {
    name: '이용기간',
    selector: (row) => row.usagePeriod,
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '18rem',
  },
  {
    name: '잔여시간',
    selector: (row) => row.remainTime,
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '2rem',
  },
  {
    name: '선택',
    selector: (row) => row.select,
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '2rem',
  },
];

export const userListColumn = [
  {
    name: 'no.',
    selector: (row) => row.id,
    sortable: true,
    center: true,
    maxWidth: '10px',
  },
  {
    name: '이름',
    selector: (row) => row.name,
    sortable: true,
    center: true,
    maxWidth: '40px',
  },
  {
    name: '휴대폰 번호',
    selector: (row) => row.phoneNum2,
    sortable: true,
    center: true,
    minWidth: '160px',
  },
  {
    name: '가입일자',
    selector: (row) => row.create,
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '130px',
  },
  {
    name: '슈퍼입장',
    selector: (row) => row.superEnterTag,
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '120px',
  },
  {
    name: 'QR/PIN 제한해제',
    selector: (row) => row.access,
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '160px',
  },
  {
    name: '지문등록',
    selector: (row) => row.finger,
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '120px',
  },
  {
    name: '키오스크',
    selector: (row) => row.kiosk,
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '120px',
  },
  {
    name: '백오피스',
    selector: (row) => row.backoffice,
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '120px',
  },

  {
    name: '차단',
    selector: (row) => row.block1,
    sortable: true,
    center: true,
    wrap: true,
  },
  {
    name: '자세히',
    selector: (row) => row.info,
    sortable: true,
    center: true,
    wrap: true,
  },
];

export const userLogColumn = [
  {
    name: 'no.',
    selector: (row) => row.idx,
    sortable: true,
    center: true,
    maxWidth: '10px',
  },
  {
    name: '내역',
    selector: (row) => row.message,
    sortable: true,
    center: true,
    minWidth: '250px',
  },
  {
    name: '좌석번호',
    selector: (row) => row.seatNumber1,
    sortable: true,
    center: true,
  },
  {
    name: '권번',
    selector: (row) => row.ticketId,
    sortable: true,
    center: true,
  },
  {
    name: '일시',
    selector: (row) => row.create,
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '210px',
  },
];

export const userInOutColumn = [
  {
    name: 'no.',
    selector: (row) => row.id,
    sortable: true,
    center: true,
    maxWidth: '10px',
  },
  {
    name: '좌석번호',
    selector: (row) => row.seatNumber,
    sortable: true,
    center: true,
  },

  {
    name: '전화번호',
    selector: (row) => row.phoneNum,
    sortable: true,
    center: true,
    minWidth: '160px',
  },

  {
    name: '내역',
    selector: (row) => row.message,
    sortable: true,
    center: true,
  },

  {
    name: '일시',
    selector: (row) => row.create,
    sortable: true,
    center: true,
    wrap: true,
  },
];

export const seminarReserved = [
  {
    name: '전화번호',
    selector: (row) => row.phoneNum1,
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '150px',
  },
  {
    name: '시작시간',
    selector: (row) => row.start,
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '200px',
  },
  {
    name: '종료시간',
    selector: (row) => row.end,
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '200px',
  },
  {
    name: '예약 취소',
    selector: (row) => row.cancel,
    sortable: true,
    center: true,
    wrap: false,
    minWidth: '200px',
  },
];

export const seminarReserved1 = [
  {
    name: '전화번호',
    selector: (row) => row.phoneNum,
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '150px',
  },
  {
    name: '시작시간',
    selector: (row) => row.start,
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '200px',
  },
  {
    name: '종료시간',
    selector: (row) => row.end,
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '200px',
  },
];

export const mileageColumn = [
  {
    name: 'no.',
    selector: (row) => row.id,
    sortable: true,
    center: true,
    wrap: true,
  },
  {
    name: '적립액',
    selector: (row) => row.price,
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '130px',
  },
  {
    name: '사용시간',
    selector: (row) => row.usingTime,
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '200px',
  },
  {
    name: '전화번호',
    selector: (row) => row.phoneNumTag,
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '200px',
  },
];

export const mileageColumn2 = [
  {
    name: 'no.',
    selector: (row) => row.id,
    sortable: true,
    center: true,
    wrap: true,
  },
  {
    name: '적립액',
    selector: (row) => row.price,
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '130px',
  },
  {
    name: '적립시간',
    selector: (row) => row.usingTime,
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '200px',
  },
];

export const couponIssuedColumn = [
  {
    name: '쿠폰번호',
    selector: (row) => row.code,
    sortable: true,
    center: true,
    minWidth: '150px',
  },
  {
    name: '쿠폰타입',
    selector: (row) => row.couponType,
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '110px',
  },
  {
    name: '사용여부',
    selector: (row) => row.used,
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '110px',
  },
  {
    name: '발급 시간',
    selector: (row) => row.create,
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '120px',
  },
  {
    name: '전화번호',
    selector: (row) => row.phoneNum,
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '160px',
  },

  {
    name: '유효기간',
    selector: (row) => row.expdate,
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '200px',
  },

  {
    name: '상품유효기간',
    selector: (row) => row.expirationDate,
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '200px',
  },
  {
    name: '삭제',
    selector: (row) => row.delete,
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '120px',
  },
];

export const couponUsedColumn = [
  {
    name: '쿠폰번호',
    selector: (row) => row.code,
    sortable: true,
    center: true,
    minWidth: '150px',
  },
  {
    name: '쿠폰타입',
    selector: (row) => row.couponType,
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '170px',
  },

  {
    name: '발급 시간',
    selector: (row) => row.create,
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '170px',
  },
  {
    name: '전화번호',
    selector: (row) => row.phoneNum,
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '160px',
  },

  {
    name: '유효기간',
    selector: (row) => row.expdate,
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '200px',
  },

  {
    name: '삭제',
    selector: (row) => row.delete,
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '200px',
  },
];

export const couponUsedColumnUser = [
  {
    name: 'no.',
    selector: (row) => row.id,
    sortable: true,
    center: true,
    wrap: true,
  },
  {
    name: '쿠폰번호',
    selector: (row) => row.code,
    sortable: true,
    center: true,
    minWidth: '150px',
  },
  {
    name: '쿠폰타입',
    selector: (row) => row.couponType,
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '130px',
  },
  {
    name: '사용여부',
    selector: (row) => row.used,
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '130px',
  },
  {
    name: '발급 시간',
    selector: (row) => row.create,
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '120px',
  },

  {
    name: '유효기간',
    selector: (row) => row.expdate,
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '200px',
  },
];

export const UserTicketListColumn = [
  {
    name: 'no.',
    selector: (row) => row.id,
    sortable: true,
    center: true,
    minWidth: '20px',
  },
  {
    name: '유형',
    selector: (row) => row.type1,
    sortable: true,
    center: true,
    minWidth: '120px',
  },
  {
    name: '퇴장',
    selector: (row) => row.exitBtn,
    sortable: true,
    center: true,
    minWidth: '120px',
  },
  {
    name: '등급',
    selector: (row) => row.level,
    sortable: true,
    center: true,
    minWidth: '80px',
  },
  {
    name: '사용시작일',
    selector: (row) => <p style={{ textAlign: 'center', lineHeight: 1.2 }}>{row.startDate}</p>,
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '200px',
  },

  {
    name: '마지막 출입시간',
    selector: (row) => <p style={{ textAlign: 'center', lineHeight: 1.2 }}>{row.last2}</p>,
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '200px',
  },
  {
    name: '충전시간',
    selector: (row) => row.action,
    sortable: true,
    center: true,
    minWidth: '120px',
  },
  {
    name: '유효기간',
    selector: (row) => row.expireDate2,
    sortable: true,
    center: true,
    minWidth: '103px',
  },

  {
    name: '잔여시간',
    selector: (row) => row.remainTime2,
    sortable: true,
    center: true,
    minWidth: '120px',
  },
  {
    name: '사용가능여부',
    selector: (row) => row.T_block,
    sortable: true,
    center: true,
    minWidth: '135px',
  },
  {
    name: '사용상태',
    selector: (row) => row.T_isUsing,
    sortable: true,
    center: true,
    minWidth: '120px',
  },
];

export const UserpaylogListColumns1 = [
  {
    name: '권번',
    selector: (row) => row.ticketId,
    sortable: true,
    center: true,
    maxWidth: '30px',
  },

  {
    name: '입장권 유형',
    selector: (row) => row.paymentType,
    sortable: true,
    center: true,
    minWidth: '200px',
  },
  {
    name: '유형',
    selector: (row) => row.p_type,
    sortable: true,
    center: true,
  },

  {
    name: '결제일자',
    selector: (row) => row.createdAt,
    sortable: true,
    center: true,
  },
  {
    name: '금액',
    selector: (row) => row.price,
    sortable: true,
    center: true,
  },
  {
    name: '승인번호',
    selector: (row) => row.meta2,
    sortable: true,
    center: true,
  },
  {
    name: '상태',
    selector: (row) => row.cancel,
    sortable: true,
    center: true,
    minWidth: '130px',
  },
  {
    name: '자세히',
    selector: (row) => row.action,
    sortable: true,
    center: true,
  },
];

export const UserpaylogListColumns2 = [
  {
    name: '권번',
    selector: (row) => row.ticketId,
    sortable: true,
    center: true,
    maxWidth: '30px',
  },
  {
    name: '유형',
    selector: (row) => row.p_type,
    sortable: true,
    center: true,
    minWidth: '150px',
  },

  {
    name: '결제일자',
    selector: (row) => row.createdAt,
    sortable: true,
    center: true,
    minWidth: '170px',
  },

  {
    name: '금액',
    selector: (row) => row.amount,
    sortable: true,
    center: true,
  },

  {
    name: '입장권 유형',
    selector: (row) => row.paymentType,
    sortable: true,
    center: true,
    minWidth: '170px',
  },
];

export const userExitColumns = [
  {
    name: 'no.',
    selector: (row) => row.idx,
    sortable: true,
    center: true,
    maxWidth: '10px',
  },
  {
    name: '번호',
    selector: (row) => row.phoneNumTag,
    sortable: true,
    center: true,
    minWidth: '170px',
  },
  {
    name: '입장권',
    selector: (row) => row.typeTag,
    sortable: true,
    center: true,
    minWidth: '170px',
  },
  {
    name: '내역',
    selector: (row) => row.message1,
    sortable: true,
    center: true,
    minWidth: '270px',
  },
  {
    name: '좌석번호',
    selector: (row) => row.seatNumber1,
    sortable: true,
    center: true,
    minWidth: '150px',
  },
  {
    name: '티켓번호',
    selector: (row) => row.ticketId,
    sortable: true,
    center: true,
    minWidth: '150px',
  },
  {
    name: '일시',
    selector: (row) => row.create1,
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '200px',
  },
];

export const noticeColumns = [
  {
    name: 'no',
    selector: (row) => row.id,
    sortable: true,
    center: true,
    maxWidth: '10px',
  },
  {
    name: '제목',
    selector: (row) => row.titleData,
    sortable: true,
    center: true,
    minWidth: '170px',
  },
  {
    name: '날짜',
    selector: (row) => row.create,
    sortable: true,
    center: true,
  },
];

export const seatCategoryColumn = [
  {
    name: '카테고리 이름',
    selector: (row) => row.seatCategoryName,
    sortable: true,
    center: true,
    wrap: true,
  },
  {
    name: '',
    selector: (row) => row.edit,
    sortable: false,
    center: true,
    wrap: true,

    maxWidth: '80px',
    minWidth: '80px',
  },
  {
    name: '',
    selector: (row) => row.delete,
    sortable: false,
    center: true,
    wrap: true,
    maxWidth: '80px',
    minWidth: '80px',
  },
];

export const callerIdColumn = [
  { accessor: 'nameString', Header: '이름' },
  { accessor: 'phoneNumberString', Header: '수신 번호' },
];

export const selectCallerIdColumn = [
  { accessor: 'nameString', Header: '이름', width: '5rem' },
  { accessor: 'phoneNumberString', Header: '전화번호', width: '12rem' },
];

export const goingToExitSeatColumn = [
  {
    name: '좌석',
    selector: (row) => row.seatNumber,
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '120px',
  },
  {
    name: '레벨',
    selector: (row) => row.level,
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '120px',
  },
  {
    name: '잔여 시간',
    selector: (row) => row.remainTime,
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '120px',
  },
];
