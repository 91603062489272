import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import { announcementRead } from '../../../lib/api';
import { DashboardCard } from '../../commonStyle';
import NoticeModal from '../../modal/noticeModal';
import PaginationComponent from '../../pagination';

const NoticeContainer = styled(DashboardCard)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  p:first-child {
    margin-bottom: 0;
    /* padding-bottom: 0.62vw; */
    font-weight: 700;
    line-height: 1.5;
    font-size: 1.25rem;
    width: 100%;
    border-bottom: 2px solid #dde4eb;
  }
  .tableDiv {
    min-height: 17.9vw;
    max-height: 17.9vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

const NoticeTable = styled.table`
  color: #686c71;
  font-size: 0.875rem;
  width: 100%;
  table-layout: fixed;
  th {
    text-align: center;
    color: #3e4144;
    font-weight: 600;
    padding: 1rem;
  }
  td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    padding: 0.5rem;
    &:first-child {
      width: 15%;
      /* padding: 0.62vw 1.04vw; */
      border-bottom-left-radius: 100vw;
      border-top-left-radius: 100vw;
    }
    &:nth-child(2) {
      width: 60%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      /* padding: 0.62vw; */
    }
    &:last-child {
      width: 25%;
      /* padding: 0.62vw 1.04vw 0.62vw 0; */
      border-bottom-right-radius: 100vw;
      border-top-right-radius: 100vw;
    }
  }
  tbody {
    tr {
      cursor: pointer;
      &:nth-child(2n-1) {
        background-color: #f1f3f6;
      }
      &:hover {
        background-color: #dde4eb;
      }
    }
  }
`;

const NoticeListTable = ({ isMobile, itemRef }) => {
  const [isShowText, setIsShowText] = useState(false);
  const [noticeList, setNoticeList] = useState([]);
  const [detailData, setDetailData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(5);
  const indexOfLast = currentPage * postsPerPage;
  const indexOfFirst = indexOfLast - postsPerPage;

  const currentPosts = (tmp) => {
    let currentPosts = 0;
    currentPosts = tmp.slice(indexOfFirst, indexOfLast);
    return currentPosts;
  };

  const getNoticeData = async () => {
    let getData = await announcementRead();

    let reverseData = getData.data.reverse();

    setNoticeList([
      ...reverseData.map((notice) => ({
        ...notice,
        create: moment(notice.createdAt).format('YYYY-MM-DD'),
        titleData: <span>{notice.title}</span>,
      })),
    ]);
  };

  const showData = (item) => {
    setIsShowText(true);
    setDetailData(item);
  };

  const closeData = () => {
    setIsShowText(false);
  };

  useEffect(() => {
    getNoticeData();
  }, []);
  return (
    <>
      {isShowText && <NoticeModal show={showData} onClose={closeData} selected={detailData} />}
      <Fragment>
        <NoticeContainer ref={itemRef}>
          <p>공지사항</p>
          {/* <div className="tableDiv"> */}
          <NoticeTable>
            <thead>
              <tr>
                <th>No.</th>
                <th>제목</th>
                {!isMobile && <th>일자</th>}
              </tr>
            </thead>
            <tbody>
              {currentPosts(noticeList).map((item) => (
                <tr onClick={() => showData(item)}>
                  <td>{item.id}</td>
                  <td>{item.titleData}</td>
                  {!isMobile && <td>{item.create}</td>}
                </tr>
              ))}
            </tbody>
          </NoticeTable>

          {noticeList.length > 0 && (
            <PaginationComponent
              setCurrentPage={setCurrentPage}
              totalLength={noticeList.length}
              postsPerPage={postsPerPage}
              currentPage={currentPage}
              padding={0}
            />
          )}
          {/* </div> */}
        </NoticeContainer>
      </Fragment>
    </>
  );
};

export default NoticeListTable;
