import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import CommonWrapper from '../CommonWrapper';
import Tab from '../../atom/Tab';
import styled from 'styled-components';
import TableController, { TableCol, TableRow } from '../../common/TableController';
import { TableHeaderArea } from '../../common/Table';
import { Input } from 'reactstrap';
import { selectCallerIdColumn } from '../../../data/ticketList';
import { userList } from '../../../lib/api';

import Format from '../../../util/formatter';
import NewTableComponent from '../../table/NewTableComponent';
import { PHONE_NUMBER_REGEX } from '../../../util/text';
import { swalReturnWarning } from '../../../util/commonFunctions';

const AddSendMessageTargetModal = ({
  merchantId,
  setSelected,
}: {
  merchantId: number;
  setSelected: (req: any[]) => void;
}) => {
  const [selectedTab, setSelectedTab] = useState('search');

  const [tableData, setTableData] = useState<any[]>([]);
  const [checkedItems, setCheckedItems] = useState<any[]>([]);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [form, setForm] = useState({ name: '', phone: '' });

  const FilteredTableData = useMemo(() => {
    if (searchKeyword === '') return tableData;
    return tableData.filter((user) => user.phoneNum.includes(searchKeyword));
  }, [tableData, searchKeyword]);

  const handleClickAdd = () => {
    if (selectedTab === 'manual') {
      if (!PHONE_NUMBER_REGEX.test(form.phone)) {
        swalReturnWarning('전화번호를 형식에 맞게 입력해 주세요.');
        return 'abort';
      }
      return setSelected([{ name: form.name, phoneNum: form.phone }]);
    }
    if (checkedItems.length === 0) {
      swalReturnWarning('추가할 수신 번호를 하나 이상 선택해 주세요.');
      return 'abort';
    }
    return setSelected([...checkedItems]);
  };

  const fetch = async () => {
    try {
      const { data } = await userList(merchantId, 'all');
      setTableData([
        ...data.map((user: any) => {
          return {
            ...user,
            nameString: user.name || '-',
            phoneNumberString: Format.formatPhone(user.phoneNum),
          };
        }),
      ]);
    } catch (error) {}
  };

  useEffect(() => {
    fetch();
  }, []);

  return (
    <CommonWrapper
      title="대상 추가"
      confirmBtnText="추가"
      style={{ gap: 0 }}
      contentStyle={{ padding: 0 }}
      onConfirm={handleClickAdd}>
      <Tab
        selected={selectedTab}
        setSelected={setSelectedTab}
        tabItems={[
          { key: 'search', text: '검색' },
          { key: 'manual', text: '직접 입력' },
        ]}
      />
      <Content>
        {selectedTab === 'search' && (
          <>
            <TableHeaderArea
              summary={{
                mainSummaryPrefix: '선택 ',
                mainSummaryUnitString: '명',
                mainSummaryValue: checkedItems.length,
                subSummaryPrefix: '총 ',
                subSummaryUnitString: '명',
                subSummaryValue: FilteredTableData.length,
              }}
              buttons={[
                <div style={{ position: 'relative' }}>
                  <Input
                    style={{ margin: 0 }}
                    placeholder="전화번호를 입력해주세요"
                    pattern="^[0-9]*$"
                    defaultValue={searchKeyword}
                    onChange={(e) => setSearchKeyword(e.target.value)}
                  />

                  <i
                    onClick={() => {}}
                    className="fa fa-search"
                    style={{
                      position: 'absolute',
                      right: '0.5rem',
                      top: '50%',
                      transform: 'translatey(-50%)',
                    }}
                  />
                </div>,
              ]}
            />

            <NewTableComponent
              isCheckbox
              columns={selectCallerIdColumn}
              defaultData={FilteredTableData}
              checkboxConfig={{
                id: 'id',
                externalChecklist: checkedItems,
                setExternalChecklist: setCheckedItems,
              }}
              data={FilteredTableData}
              style={{
                tableContainer: {
                  height: '33.2rem',
                  overflowY: 'scroll',
                },
              }}
            />
          </>
        )}
        {selectedTab === 'manual' && (
          <>
            <TableController>
              <TableRow isStart>
                <TableCol title="이름">
                  <Input
                    value={form.name}
                    onChange={(e) => setForm({ ...form, name: e.target.value })}
                    placeholder="이름을 입력해 주세요. (생략 가능)"
                  />
                </TableCol>
              </TableRow>
              <TableRow>
                <TableCol title="전화번호">
                  <Input
                    value={form.phone}
                    onChange={(e) => {
                      if (e.target.value.length > 11) return;
                      setForm({ ...form, phone: e.target.value });
                    }}
                    pattern="^[0-9]*$"
                    placeholder="하이픈('-') 없이 입력"
                  />
                </TableCol>
              </TableRow>
            </TableController>
          </>
        )}
      </Content>
    </CommonWrapper>
  );
};

export default AddSendMessageTargetModal;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 30rem;
  padding: 2rem;
  overflow: auto;
  gap: 1rem;
`;
