import React, { Fragment, useEffect, useState } from 'react';
import { Input, Modal, ModalHeader, ModalBody } from 'reactstrap';
import Swal from 'sweetalert2';
import { merchantId_api, postTicketCreate } from '../../lib/api';
import { Button, Label1 } from '../../pages/lockerChange';
import configDB from '../../data/customizer/config';
import { ToggleBtn } from '../../pages/seatList';
import { LabelRow } from '../../pages/ticketCustom';
import { ModalBackground } from './choiceModal';
import { InputS, InputWithP } from '../../pages/ticketList';

const primary = localStorage.getItem('default_color') || configDB.data.color.primary_color;

let merchantId = '';
const TicketsInsertModal = ({ isOpen, onClose, setData, setReload }) => {
  const [info, setInfo] = useState({
    T_type: 1,
    T_name: '',
    T_description: '',
    T_price: 0,
    T_discount: 0,
    T_expireDate: 1,
    T_level: 0,
    T_time: 0,
    T_hour: 0,
    T_min: 0,
    T_day: 0,
    seatType: 0,
    T_count: -1,
  });
  const [count, setCount] = useState(false);

  const getMerchantId = async () => {
    const { data } = await merchantId_api();
    if (data) {
      merchantId = data.id;
    }
  };

  useEffect(() => {
    getMerchantId();
  }, []);

  const handleOnchange = ({ target }) => {
    if (target.name === 'T_type' || target.name === 'seatType') {
      if (target.value === 3) {
        setInfo({
          ...info,
          [target.name]: +target.value,
          seatType: 0,
        });
        // setInfo({ ...info, seatType: 0 });
      } else if (target.value === 1) {
        setInfo({
          ...info,
          [target.name]: +target.value,
          T_day: 0,
          T_expireDate: 1,
          seatType: 0,
        });
      } else {
        setInfo({
          ...info,
          [target.name]: +target.value,
        });
      }
    } else if (target.name === 'T_level') {
      setInfo({
        ...info,
        [target.name]: +target.value > 4 ? 4 : +target.value,
      });
    } else {
      setInfo({ ...info, [target.name]: target.value });
    }
  };

  const timeChange = (e) => {
    setInfo({ ...info, [e.target.name]: e.target.value });
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();

    try {
      if (
        info.T_name.length > 0 &&
        info.T_price > 0 &&
        info.T_level > 0 &&
        (info.T_hour || info.T_min || info.T_day)
      ) {
        let copyData = {
          ...info,
          T_time: info.T_hour * 3600 + info.T_min * 60,
        };
        const res = await postTicketCreate({
          ...copyData,
          merchantId,
        });
        if (res.status === 200) {
          setReload();
          Swal.fire({
            icon: 'success',
            title: '이용권이 등록됐습니다.',
            timer: 2000,
          }).then(
            setInfo({
              T_type: 1,
              T_name: '',
              T_description: '',
              T_price: 0,
              T_discount: 0,
              T_expireDate: 1,
              T_level: 0,
              T_time: 0,
              T_day: 0,
              seatType: 0,
            }),
            onClose(),
          );
        }
      } else if (info.T_type === 3 && info.T_expireDate <= 0) {
        Swal.fire({
          icon: 'error',
          title: '유효기간은 최소 1일 이상 설정합니다.',
          timer: 2000,
        });
      } else if (info.T_type !== 2 && !+info.T_hour && +info.T_min === 0) {
        Swal.fire({
          icon: 'error',
          title: '시간은 최소 1분 이상 입력해주세요.',
          timer: 2000,
        });
      } else if (info.T_type !== 2 && info.T_min > 59) {
        Swal.fire({
          icon: 'error',
          title: '정확한 시간을 입력해주세요',
          timer: 2000,
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: '필수항목을 전부 기입해주세요.',
          timer: 2000,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Fragment>
      <Modal centered isOpen={isOpen} toggle={onClose}>
        <ModalHeader className="modal-title" toggle={onClose}>
          이용권 추가
        </ModalHeader>
        <ModalBody>
          <Label1>
            <h5>
              이용권 타입 <strong>*</strong>
            </h5>
            <select name="T_type" value={info?.T_type} onChange={handleOnchange}>
              <option value={+1} disabled={info?.seatType === 1}>
                일회권
              </option>
              <option value={+2}>기간권</option>
              <option value={+3} disabled={info?.seatType === 1}>
                시간정액권
              </option>
            </select>
          </Label1>
          <Label1>
            <h5>
              좌석 타입 <strong>*</strong>
            </h5>
            <select name="seatType" value={info?.seatType} onChange={handleOnchange}>
              <option value={+0}>자유석</option>
              <option value={+1} disabled={info?.T_type === 1 || info?.T_type === 3}>
                고정석
              </option>
            </select>
          </Label1>
          <Label1>
            <h5>
              이용권 이름 <strong>*</strong>
            </h5>
            <InputS
              type="text"
              name="T_name"
              onChange={handleOnchange}
              value={info?.T_name || ''}
            />
          </Label1>
          <Label1>
            <h5>이용권 설명</h5>
            <Input
              type="text"
              placeholder=""
              name="T_description"
              onChange={handleOnchange}
              value={info?.T_description || ''}
            />
          </Label1>
          <Label1>
            <h5>
              이용권 가격 <strong>*</strong>
            </h5>
            <InputS
              type="number"
              min="0"
              name="T_price"
              onChange={handleOnchange}
              value={info?.T_price || ''}
            />
          </Label1>
          <Label1>
            <h5>이용권 할인률</h5>
            <InputWithP>
              <Input
                style={{ marginTop: '0' }}
                type="number"
                min="0"
                name="T_discount"
                onChange={handleOnchange}
                value={info?.T_discount || ''}
              />
              <p style={{ marginBottom: '0' }}>%</p>
            </InputWithP>
          </Label1>
          <Label1>
            <h5>
              이용권 등급 <strong>*</strong>{' '}
              <p>(등급이 높을 수록 더 많은 좌석을 사용 할 수 있습니다)</p>
            </h5>

            <InputS
              style={{ width: '40%' }}
              type="number"
              min="0"
              max="4"
              name="T_level"
              onChange={handleOnchange}
              value={info?.T_level || ''}
            />
          </Label1>
          {info?.T_type === 3 ? (
            <Label1>
              <h5>이용권 유효기간</h5>
              <InputWithP>
                <InputS
                  type="number"
                  min="1"
                  name="T_expireDate"
                  onChange={handleOnchange}
                  value={info?.T_expireDate || 1}
                  disabled={!(info?.T_type === 3)}
                />
                <p>일</p>
              </InputWithP>
            </Label1>
          ) : (
            ''
          )}

          <Label1>
            <h5>이용권 사용기간</h5>

            <InputWithP>
              {(info.T_type === 1 || info.T_type === 3 || info.T_type === 5) && (
                <>
                  <Input
                    type="number"
                    min="0"
                    name="T_hour"
                    value={info?.T_hour || ''}
                    onChange={timeChange}
                  />
                  <p>시간</p>
                </>
              )}
              <InputS
                type="number"
                min="0"
                name={info.T_type === 2 ? 'T_day' : 'T_min'}
                onChange={timeChange}
                value={info.T_type === 2 ? info?.T_day : +info?.T_min || ''}
              />
              <p>{info.T_type === 2 ? '일' : '분'}</p>
            </InputWithP>
          </Label1>
          <LabelRow>
            <h5>이용권 수 제한</h5>
            <ToggleBtn>
              <InputS
                id="count"
                type="checkbox"
                onChange={() => setCount(!count)}
                checked={count}
              />{' '}
              <span className="onoff-switch"></span>
            </ToggleBtn>
          </LabelRow>
          {count && (
            <LabelRow>
              <InputWithP>
                <Input
                  style={{ width: '40%', marginTop: '0' }}
                  type="number"
                  min="0"
                  name="T_count"
                  onChange={handleOnchange}
                  value={info?.T_count || ''}
                />
                <p>개</p>
              </InputWithP>
            </LabelRow>
          )}
          <div
            style={{
              display: 'flex',
              width: '100%',
              gap: '10px',
            }}
          >
            <Button color="#fff" onClick={onClose}>
              취소
            </Button>
            <Button onClick={handleOnSubmit} color={primary}>
              확인
            </Button>
          </div>
        </ModalBody>
      </Modal>
      <ModalBackground />
    </Fragment>
  );
};

export default TicketsInsertModal;
