import { useState, useEffect } from 'react';
import debounce from 'lodash/debounce';

export interface WindowArea {
  width?: number;
  height?: number;
}

const POLLING_RATE = 15;

export default function useWindow() {
  const [windowInfo, setWindowInfo] = useState<WindowArea | undefined>(undefined);
  function getWidth() {
    let xWidth = 0;
    if (window.screen != null) xWidth = window.screen.availWidth;
    if (window.innerWidth != null) xWidth = window.innerWidth;
    if (document.body != null) xWidth = document.body.clientWidth;
    return xWidth;
  }
  function getHeight() {
    let xHeight = 0;
    if (window.screen != null) xHeight = window.screen.availHeight;
    if (window.innerHeight != null) xHeight = window.innerHeight;
    if (document.body != null) xHeight = document.body.clientHeight;
    return xHeight;
  }

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      if (typeof window !== 'undefined')
        setWindowInfo({ ...windowInfo, width: getWidth(), height: getHeight() });
    }
    // Add event listener
    window.addEventListener('resize', debounce(handleResize, POLLING_RATE));
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowInfo;
}
